import { Icon } from "../icons/icon";

export function EmptyView(props) {
  return (
    <div className="p-text-center">
      <div className="empty-container">
        <div className="adjust-empty-icon">
          <Icon icon="no-item" />
        </div>
        <div>
          <p className="empty-text-two">{props.emptyText}</p>
        </div>
        {props.isSearch && (
          <div className="p-mt-3">
            <button onClick={() => props.reload()} className="primary-button">
              Reset
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
