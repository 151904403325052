import axios from "axios";
import { CONFIG } from "../config/config";
import { STORAGE } from "../utils/storage";
import * as webStorage from "../utils/webStorage";

export const apiClient = axios.create({
  baseURL: CONFIG.BASE_URL,
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      if (window.location.pathname !== "/login") {
        webStorage.clear();
        window.location.href = "/login?session_expired=true";
      }
    }
    return Promise.reject(error);
  }
);

export const getAuthHeaders = () => {
  const token = STORAGE.GET_TOKEN();
  return {
    Authorization: `Bearer ${token}`,
  };
};
