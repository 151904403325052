import React, { useCallback, useEffect, useState } from "react";
import { API_SERVICE } from "api/service";
import { CustomConfirmationDialog } from "components/elements/confirmation-dialog/confirmation-dialog";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { DetailsBreakDown } from "components/elements/details-break-down/details-break-down";
import { Optional } from "components/elements/optional/optional";
import { HELPER } from "utils/helper";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";

export function DisputeRefundAttempts(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [refundAttempts, setRefundAttempts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [emptyText, setEmptyText] = useState("No refund attempts found...");
  const [search, setSearch] = useState(false);
  const [error, setError] = useState(null);
  // const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [refundAttemptStatus, setRefundAttemptStatus] = useState(null);
  const [disputeRefundStatus, setDisputeRefundStatus] = useState(null);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [
    updateDisputeRefundAttemptStatusError,
    setUpdateDisputeRefundAttemptStatusError,
  ] = useState(null);
  const [
    updateDisputeRefundAttemptStatusResponseMessage,
    setUpdateDisputeRefundAttemptStatusResponseMessage,
  ] = useState(null);

  const tableHeaders = [
    { label: "Date", value: "completedTime" },
    { label: "Destination account", value: "destinationAccount" },
    { label: "Destination account name", value: "destinationAccountName" },
    // { label: "Narration", value: "narration" },
    { label: "Transaction ref", value: "transactionRef" },

    { label: "Status", value: "status" },

    { label: "Actions", value: "actions" },
  ];

  const showTransactionDetails = useCallback((e, isMobile) => {
    let arr = [];
    arr.push({
      label: "Destination Account",
      value: e?.refundTransaction?.destinationAccount,
    });
    arr.push({
      label: "Destination Account Name",
      value: e?.refundTransaction?.destinationAccountName,
    });
    arr.push({
      label: "Amount",
      value: e?.refundTransaction?.amount,
      itemCase: "amount",
    });
    arr.push({
      label: "Status",
      value: e?.status,
      itemCase: "status",
    });
    arr.push({
      label: "Initiated At",
      value: e?.createdTime,
      itemCase: "createdTime",
    });
    arr.push({
      label: "Completed At",
      value: e?.completedTime,
      itemCase: "completedTime",
    });
    arr.push({ label: "Comment", value: e?.comment });

    arr.push({
      label: "Narration",
      value: e?.narration,
    });
    arr.push({
      label: "Transaction Ref",
      value: e?.transactionRef,
    });
    arr.push({
      label: "Transaction Search key",
      value: e?.refundTransaction?.transactionSearchKey,
    });
    setRefundAttemptStatus(e?.status);
    setTransactionDetails(arr);
    setCurrentModalIndex(0);
    setVisible(true);
  }, []);

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          showTransactionDetails(e, isMobile);
          break;
        }
      }
    },
    [showTransactionDetails]
  );

  const getDisputeRefundAttempts = useCallback(() => {
    setCurrentIndex(0);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: 10,
      transactionSearchKey: props?.transactionSearchKey,
    });
    const frontOfficeUrl = FRONT_OFFICE_API.DISPUTE.GET_DISPUTE_REFUND_ATTEMPTS;
    const backOfficeUrl = BACK_OFFICE_API.DISPUTE.GET_DISPUTE_REFUND_ATTEMPTS;
    const url =
      props.requestType === "front-office" ? frontOfficeUrl : backOfficeUrl;

    API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
      .then((data) => {
        const result = data?.result?.content;
        if (!result.length) {
          setEmptyText("No refund attempts found.");
        } else {
          let arr = [];
          setTotalItems(data?.result.totalElements);
          setTotalPages(data?.result.totalPages);
          result.forEach((e) => {
            arr.push({
              ...e,
              destinationAccount: e?.refundTransaction?.destinationAccount,
              destinationAccountName:
                e?.refundTransaction?.destinationAccountName,
              actions: "VIEW",
              detailsFunction: openAction,
            });
          });
          setRefundAttempts(arr);
        }
        setError(null);
        setCurrentIndex(1);
        // setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        // setLoading(false);
        setCurrentIndex(1);
      });
  }, [pageNo, openAction, props?.transactionSearchKey, props?.requestType]);

  useEffect(() => {
    getDisputeRefundAttempts();
  }, [getDisputeRefundAttempts]);

  function updateDisputeRefundAttemptStatus() {
    // setLoading(true);
    let params = HELPER.TO_URL_STRING({
      disputeStatus: disputeRefundStatus,
    });
    const url = BACK_OFFICE_API.REQUERY_MANAGEMENT.UPDATE_DISPUTE_ON_REQUERY;
    API_SERVICE.MAKE_PUT_REQUEST(
      `${url}/${props.transactionSearchKey}?${params}`
    )
      .then((data) => {
        setUpdateDisputeRefundAttemptStatusResponseMessage(
          data?.result?.message
        );
        // setLoading(false);
        // setCurrentModalIndex(2);
      })
      .catch((error) => {
        setUpdateDisputeRefundAttemptStatusError(HELPER.PROCESS_ERROR(error));
        // setLoading(false);
      });
  }

  function markDisputeStatus(status) {
    setDisputeRefundStatus(status);
    setCurrentModalIndex(1);
  }

  function reload() {
    setPageNo(0);
    // setLoading(true);
    setSearch(false);
    getDisputeRefundAttempts();
  }

  function closeModal(reload) {
    setVisible(false);
    if (reload) {
      getDisputeRefundAttempts();
    }
  }

  function onHide() {}

  const currentView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container tab">
            <CustomLoader loadingText="Loading refund attempts..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={false}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={refundAttempts}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={(no) => setPageNo(no)}
          />
        );
    }
  };

  const modalContent = () => {
    switch (currentModalIndex) {
      case 0:
        return (
          <div>
            <DetailsBreakDown
              error={false}
              loading={false}
              title="Transaction"
              breakDown={transactionDetails}
              closeModal={closeModal}
              hideCloseButton={
                refundAttemptStatus === "AWAITING_RE_QUERY" &&
                HELPER.HAS_AUTHORITY(
                  "bo_update_dispute_refunds_on_awaiting_requery"
                )
              }
            />
            <Optional
              show={
                refundAttemptStatus === "AWAITING_RE_QUERY" &&
                HELPER.HAS_AUTHORITY(
                  "bo_update_dispute_refunds_on_awaiting_requery"
                )
              }
            >
              <div className="p-grid">
                <div className="p-col-6 p-pl-0">
                  {/* {cancelButton()} */}
                  <button
                    className="secondary-button"
                    onClick={() => markDisputeStatus("FAILED")}
                  >
                    Mark as failed
                  </button>
                </div>
                <div className="p-col-6 p-pr-0">
                  {/* {submitButton()} */}
                  <button
                    className="primary-button"
                    onClick={() => markDisputeStatus("SUCCESSFUL")}
                  >
                    Mark as successful
                  </button>
                </div>
              </div>
            </Optional>
          </div>
        );
      case 1:
        return (
          <CustomConfirmationDialog
            text={`Confirm that you want to mark this dispute refund attempt as ${disputeRefundStatus.toLowerCase()}.`}
            action={updateDisputeRefundAttemptStatus}
            errorMessage={updateDisputeRefundAttemptStatusError}
            clearErrorMessage={() =>
              setUpdateDisputeRefundAttemptStatusError(null)
            }
            responseMessage={updateDisputeRefundAttemptStatusResponseMessage}
            closeModal={closeModal}
          />
        );

      default:
        break;
    }
  };

  return (
    <div>
      <div>
        <CustomModal
          onHide={onHide}
          closeModal={closeModal}
          visible={visible}
          modalContent={modalContent}
        />
      </div>
      <div className="p-mt-5">{currentView()}</div>
    </div>
  );
}
