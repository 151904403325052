import { useEffect, useRef, useState } from "react";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { FormDropdown } from "components/form/form-dropdown";
import { FormInput } from "components/form/form-input";
import { CUSTOM_VALIDATION } from "utils/validation";
import { API_SERVICE } from "api/service";
import { CustomMessage } from "components/elements/alert/custom-message";
import { CustomToast } from "components/elements/alert/custom-toast";
import { HELPER } from "utils/helper";
import { Toast } from "primereact/toast";
import { Optional } from "components/elements/optional/optional";
import { ToolTip } from "components/elements/tooltip/tooltip";
import { BACKOFFICE_CONFIGS } from "utils/constants";
import { useSingleAppConfigurationValue } from "hooks/use-single-configuration-value";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";
import { COMMON_API } from "../../../api/routes/common/index";

export function CustomSettlementParticipantModel(props) {
  const toast = useRef(null);
  const isNameEnquiryEnabled = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.IS_NAME_ENQUIRY_ENABLED
  );
  const [loading, setLoading] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [messageTitle, setMessageTitle] = useState(null);
  const [chargeTypes, setChargeTypes] = useState([]);
  const [gettingChargeTypes, setGettingChargeTypes] = useState(false);
  const [message, setMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [verifyingAccountNumber, setVerifyingAccountNumber] = useState(false);
  const [accountType, setAccountType] = useState(null);
  const [accountTypeName, setAccountTypeName] = useState("");
  const [feeSharingAccountType, setFeeSharingAccountType] = useState(null);
  const [feeSharingAccountTypeName, setFeeSharingAccountTypeName] =
    useState("");
  const [participant, setParticipant] = useState({
    accountName: null,
    accountNumber: null,
    chargeType: null,
    description: null,
    global: false,
    name: null,
    reportEmail: null,
    sendReport: null,
    feeDebitAccount: "",
    feeDebitAccountName: "",
    feeSharing: true,
    useMerchantChargeType: false,
    remainderParticipant: false,
  });
  const [participantError, setParticipantError] = useState({
    accountName: null,
    accountNumber: null,
    chargeType: null,
    description: null,
    global: null,
    name: null,
    reportEmail: null,
    sendReport: null,
    feeDebitAccount: null,
    feeDebitAccountName: null,
    feeSharing: null,
  });
  const accountTypes = [
    { desc: "GL", code: "gl" },
    { desc: "NUBAN", code: "nuban" },
  ];
  const sendReportChoices = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];
  const [feeSharingTip] = useState(
    "A Debit Account will be required if the option selected is ‘No’.  This is an alternative account to the usual Transit account used for transactions settlement."
  );
  const remainderParticipantTip = `A remainder participant is one whose fee split is allocated last once all other participants have been settled. This scenario comes about when what is available for split via the charge amount varies due to concessions on merchant charges.`;
  const merchantChargeTypeTip = `The derived participant fee amount will be computed via the main charge attached to the merchant. Alternatively, the participant fee amount will be retrieved from the merchant charge amount if the 'No' option is selected on settlement.`;
  const glNameEnquiry = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.GL_NAME_ENQUIRY_OPTION
  );
  const glAccountSize = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.GL_ACCOUNT_NUMBER_SIZE
  );

  useEffect(() => {
    function getChargeType() {
      setGettingChargeTypes(true);
      setChargeTypes([]);
      const url = BACK_OFFICE_API.CHARGE_TYPE.GET_ALL_CHARGE_MODELS;
      API_SERVICE.MAKE_GET_REQUEST(url)
        .then((data) => {
          let arr = [];
          let newChargeType = null;
          data.result.forEach((e) => {
            if (
              props.isUpdate &&
              e.chargeTypeName === props?.editParticipant?.chargeType
            ) {
              newChargeType = {
                desc: e.chargeTypeName,
                code: e.code,
              };
            }
            arr.push({ desc: e.chargeTypeName, code: e.code });
          });
          if (props.isUpdate) {
            const global = getGlobalCharge(props?.editParticipant?.global);
            setParticipant({
              ...props.editParticipant,
              global: global,
              chargeType: newChargeType,
            });
          }
          setChargeTypes(arr);
          setLoading(false);
          setGettingChargeTypes(false);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error getting charge types!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
          setLoading(false);
          setGettingChargeTypes(false);
        });
    }

    getChargeType();
  }, [props.editParticipant, props.isUpdate]);

  useEffect(() => {
    if (accountType) {
      setAccountTypeName(accountType.code);
    }
    if (feeSharingAccountType) {
      setFeeSharingAccountTypeName(feeSharingAccountType?.code);
    }
  }, [accountType, feeSharingAccountType]);

  function submit() {
    setLoading(true);
    let payload = {
      ...participant,
      chargeType: participant["chargeType"]?.code,
    };
    payload = HELPER.TRIM_OBJECT(payload);
    const url = BACK_OFFICE_API.PARTICIPANT.CREATE_SETTLEMENT_PARTICIPANT;
    API_SERVICE.MAKE_POST_REQUEST(url, payload)
      .then((data) => {
        setMessageTitle(null);
        setSuccessMessage("Participant created successfully");
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setMessageTitle("Error");
        setMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  function verifyAccountNumber(accNo, accountType) {
    setVerifyingAccountNumber(true);
    const ACCOUNT_NAME_FIELD =
      accountType === "feeDebitAccount" ? "feeDebitAccountName" : "accountName";
    setAccountName(null);
    setParticipantError({
      ...participantError,
      [accountType]: null,
      [ACCOUNT_NAME_FIELD]: null,
    });
    const url = COMMON_API.ACCOUNT_ENQUIRY.VERIFY_ACCOUNT_NUMBER;
    API_SERVICE.MAKE_GET_REQUEST(`${url}/${accNo}`)
      .then((data) => {
        setParticipant({
          ...participant,
          [accountType]: accNo,
          [ACCOUNT_NAME_FIELD]: data?.result?.accountName,
        });
        setVerifyingAccountNumber(false);
      })
      .catch((error) => {
        setParticipantError({
          ...participantError,
          accountNumber: HELPER.PROCESS_ERROR(error, "NAME_ENQUIRY"),
        });
        setVerifyingAccountNumber(false);
      });
  }

  function getGlobalCharge(global) {
    if (global) {
      return { desc: "GLOBAL", code: "GLOBAL", isGlobal: true };
    }
    return { desc: "NON_GLOBAL", code: "NON_GLOBAL", isGlobal: false };
  }

  useEffect(() => {
    let mounted = true;
    function checkValidForm() {
      let participantForm = {
        ...participant,
      };
      if (accountTypeName === "gl") {
        delete participantForm["accountName"];
      }
      if (feeSharingAccountTypeName === "gl") {
        delete participantForm["feeDebitAccountName"];
      }
      if (participantForm.sendReport === false) {
        delete participantForm.reportEmail;
      }
      if (participantForm.feeSharing) {
        delete participantForm.feeDebitAccount;
        delete participantForm?.feeDebitAccountName;
      }
      // these values can either be true or false and don't need to be evaluated
      delete participantForm.feeSharing;
      delete participantForm.useMerchantChargeType;
      delete participantForm.remainderParticipant;
      const validErrorForm = !CUSTOM_VALIDATION.VALID_OBJ_ANY(participantError);
      let objKeys = Object.keys(participantForm);
      let isValidForm = true;
      objKeys.forEach((key) => {
        if (!participantForm[key] && key !== "sendReport" && key !== "global") {
          isValidForm = false;
        }
      });
      setValidForm(isValidForm && validErrorForm);
    }

    if (mounted) {
      checkValidForm();
    }
    return () => {
      mounted = false;
    };
  }, [
    participant,
    participantError,
    accountTypeName,
    props.isUpdate,
    feeSharingAccountTypeName,
  ]);

  function updateParticipant() {
    setLoading(true);
    const payload = {
      ...participant,
      chargeType: participant["chargeType"]?.code,
      global: participant["global"]?.isGlobal,
      id: props?.editParticipant?.id,
    };
    const url = BACK_OFFICE_API.PARTICIPANT.UPDATE_SETTLEMENT_PARTICIPANT;
    API_SERVICE.MAKE_PUT_REQUEST(`${url}/${payload?.id}`, payload)
      .then((data) => {
        setMessageTitle(null);
        setSuccessMessage("Participant updated successfully");
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setMessageTitle("Error");
        // setMessage("An error occur when updating participant");
        setMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  function validateDropdown(e, name) {
    const value = e.target.value;
    if (value) {
      setParticipantError({ ...participantError, [name]: "" });
      setParticipant({ ...participant, [name]: value });
    } else {
      let errorMessage = "Select participant";
      if (name === "chargeType") {
        errorMessage = "Select charge type";
      }
      setParticipantError({ ...participantError, [name]: errorMessage });
    }
  }

  function validateReportDropdown(e, name) {
    const value = e.target.value;
    setParticipant({ ...participant, [name]: value, reportEmail: null });
    setParticipantError({ ...participantError, reportEmail: null });
  }

  function saveAccountType(e, accountType) {
    if (accountType === "feeSharingAccountType") {
      setFeeSharingAccountType(e?.target?.value);
      setParticipantError({
        ...participantError,
        feeDebitAccountName: null,
        feeDebitAccount: null,
      });
    } else {
      setAccountType(e?.target?.value);
      setParticipantError({
        ...participantError,
        accountName: null,
        accountNumber: null,
      });
    }
    // setAccount(e.target.value);
  }

  function validateGlAccount(e, name, type, refineName, required) {
    const accountNo = e?.target?.value;
    const isValidAccountNo = glAccountSize
      ? accountNo?.length?.toString() === glAccountSize
      : true;
    if (isValidAccountNo) {
      if (isNameEnquiryEnabled === "true" && glNameEnquiry === "true") {
        verifyAccountNumber(accountNo, name);
      } else {
        setParticipant({ ...participant, [name]: accountNo });
        setParticipantError({ ...participantError, [name]: null });
      }
    } else {
      setParticipantError({
        ...participantError,
        [name]: "Account number is invalid",
      });
    }
  }

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    setParticipant({ ...participant, [name]: value });
    if (isValidInput) {
      setParticipantError({ ...participantError, [name]: null });
      if (
        (name === "accountNumber" || name === "feeDebitAccount") &&
        isNameEnquiryEnabled === "true"
      ) {
        verifyAccountNumber(value, name);
      }
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setParticipantError({
        ...participantError,
        [name]: errorMessage,
      });
    }
  }

  function handleRadioInputChange(e) {
    const val = e?.target?.value;
    const propertyName = e?.target?.name;
    setParticipant({
      ...participant,
      [propertyName]: val === "true" ? true : false,
    });
  }

  const titleView = () => {
    if (props.isUpdate) {
      return "Update Participant";
    }
    return "New Participant";
  };

  const subTitleView = () => {
    if (props.isUpdate) {
      return "Update the form below";
    }
    return "";
  };

  const participantView = () => {
    if (currentIndex) {
      return (
        <div>
          <div>
            <CustomMessage messageType="success" />
          </div>
          <div>
            <p className="success-message-text">{successMessage}</p>
          </div>
          <div className="success-message-btn-container p-text-center">
            <p
              onClick={() => props.closeModal(true)}
              className="close-modal add-cursor"
            >
              Close
            </p>
          </div>
        </div>
      );
    } else {
      return <div>{participantFormView()}</div>;
    }
  };

  const submitButton = () => {
    if (!loading) {
      if (props.isUpdate) {
        return (
          <button
            disabled={!validForm}
            onClick={updateParticipant}
            className="primary-button"
          >
            Update
          </button>
        );
      } else {
        return (
          <button
            disabled={!validForm}
            onClick={submit}
            className="primary-button"
          >
            Submit
          </button>
        );
      }
    } else {
      return (
        <div className="pull-up-element-2">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  const viewAlert = () => {
    if (messageTitle) {
      return (
        <div>
          <CustomToast
            title={messageTitle}
            description={message}
            type="error"
            clearToast={() => setMessageTitle(null)}
          />
        </div>
      );
    } else {
      return <div />;
    }
  };

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={props.closeModal} className="secondary-button">
          Close
        </button>
      );
    } else {
      return <div />;
    }
  };

  function shouldDisableFeeAccountNameField() {
    if (!feeSharingAccountTypeName) {
      return true;
    }
    if (isNameEnquiryEnabled === "false") {
      return false;
    } else {
      if (feeSharingAccountTypeName === "gl" && glNameEnquiry === "true") {
        return true;
      } else {
        return true;
      }
    }
  }

  function shouldDisableAccountNameField() {
    if (!accountTypeName) {
      return true;
    }
    if (isNameEnquiryEnabled === "false") {
      return false;
    } else {
      if (accountTypeName === "gl" && glNameEnquiry === "true") {
        return true;
      } else {
        return true;
      }
    }
  }

  const participantFormView = () => {
    return (
      <div>
        <div>
          <div className="custom-modal-title p-text-left">{titleView()}</div>
          <div className="custom-dialog-subtitle-container p-mb-5">
            <p className="custom-dialog-subtitle">{subTitleView()}</p>
            <p className="custom-dialog-subtitle">* Required Fields</p>
          </div>
        </div>
        <div className="p-pb-1">{viewAlert()}</div>
        <div className="p-grid">
          <div className="p-col-12">
            <div className="p-mt-1">
              <FormDropdown
                required={true}
                label="desc"
                field="chargeType"
                error={participantError["chargeType"]}
                disabled={loading}
                value={participant["chargeType"]}
                fn={validateDropdown}
                options={chargeTypes}
                placeholder="Select a charge type *"
                isLoading={gettingChargeTypes}
                filter
              />
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-mt-1">
              <FormInput
                value={participant["name"]}
                required={true}
                field="name"
                type="NAME"
                error={participantError["name"]}
                fn={validateForm}
                loading={loading}
                placeholder="Name"
              />
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-mt-1">
              <FormInput
                value={participant["description"]}
                required={true}
                field="description"
                type=""
                error={participantError["description"]}
                fn={validateForm}
                loading={loading}
                placeholder="Description"
              />
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-mt-1">
              <FormDropdown
                required={true}
                label="desc"
                field="accountType"
                error=""
                disabled={loading}
                value={accountType}
                fn={saveAccountType}
                options={accountTypes}
                placeholder="Select account type *"
              />
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-mt-1">
              <FormInput
                verifyingField="account no"
                verifying={verifyingAccountNumber}
                verified={accountName}
                value={participant["accountNumber"]}
                required={true}
                field="accountNumber"
                type="NUBAN"
                error={participantError["accountNumber"]}
                fn={accountTypeName === "gl" ? validateGlAccount : validateForm}
                disabled={loading || accountTypeName.length === 0}
                placeholder="Account no"
              />
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-mt-1">
              <FormInput
                value={participant["accountName"]}
                required={false}
                field="accountName"
                type="NAME"
                error={participantError["accountName"]}
                fn={validateForm}
                disabled={shouldDisableAccountNameField()}
                placeholder="Account name"
              />
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-mt-1">
              <Optional show={props.isUpdate}>
                <label>
                  Would this participant like to receive report emails?
                </label>
              </Optional>
              <FormDropdown
                required={true}
                label="name"
                field="sendReport"
                error={participantError["sendReport"]}
                disabled={loading}
                value={participant["sendReport"]}
                fn={validateReportDropdown}
                options={sendReportChoices}
                placeholder="Would this participant like to receive report emails? *"
              />
            </div>
          </div>
          {participant.sendReport && (
            <div className="p-col-12">
              <div className="p-mt-1">
                <FormInput
                  value={participant["reportEmail"]}
                  required={true}
                  field="reportEmail"
                  type="EMAIL"
                  error={participantError["reportEmail"]}
                  fn={validateForm}
                  loading={loading}
                  placeholder="Email"
                />
              </div>
            </div>
          )}
          <div className="p-col-12 p-my-2">
            <div className="flex default">
              <div style={{ marginBottom: "-0.15rem" }}>
                <ToolTip tip={feeSharingTip} />
              </div>
              <p className="text-small p-my-0 p-ml-1">
                Fee sharing participant?
              </p>
              <div
                className="flex default p-ml-3"
                style={{ marginTop: "-0.3rem" }}
              >
                <label className="label">
                  <input
                    id="fee-sharing--true"
                    value={true}
                    name="feeSharing"
                    type="radio"
                    checked={participant?.feeSharing === true}
                    onChange={handleRadioInputChange}
                  />
                  <span>Yes</span>
                </label>
                <label className="label p-ml-3">
                  <input
                    id="free-sharing--false"
                    value={false}
                    name="feeSharing"
                    type="radio"
                    checked={participant?.feeSharing === false}
                    onChange={handleRadioInputChange}
                  />
                  <span>No</span>
                </label>
              </div>
            </div>
          </div>
          <Optional show={participant?.feeSharing === true}>
            <div className="p-col-12 p-my-2">
              <div className="flex default">
                <div style={{ marginBottom: "-0.15rem" }}>
                  <ToolTip tip={merchantChargeTypeTip} />
                </div>
                <p className="text-small p-my-0 p-ml-1">
                  Derive fee from merchant charge type?
                </p>
                <div
                  className="flex default p-ml-3"
                  style={{ marginTop: "-0.3rem" }}
                >
                  <label className="label">
                    <input
                      id="use-merchant-charge--true"
                      value={true}
                      name="useMerchantChargeType"
                      type="radio"
                      checked={participant?.useMerchantChargeType === true}
                      onChange={handleRadioInputChange}
                    />
                    <span>Yes</span>
                  </label>
                  <label className="label p-ml-3">
                    <input
                      id="use-merchant-charge--false"
                      value={false}
                      name="useMerchantChargeType"
                      type="radio"
                      checked={participant?.useMerchantChargeType === false}
                      onChange={handleRadioInputChange}
                    />
                    <span>No</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-my-2">
              <div className="flex default">
                <div style={{ marginBottom: "-0.15rem" }}>
                  <ToolTip tip={remainderParticipantTip} />
                </div>
                <p className="text-small p-my-0 p-ml-1">
                  Is a remainder participant?
                </p>
                <div
                  className="flex default p-ml-3"
                  style={{ marginTop: "-0.3rem" }}
                >
                  <label className="label">
                    <input
                      id="remainder-participant--true"
                      value={true}
                      name="remainderParticipant"
                      type="radio"
                      checked={participant?.remainderParticipant === true}
                      onChange={handleRadioInputChange}
                    />
                    <span>Yes</span>
                  </label>
                  <label className="label p-ml-3">
                    <input
                      id="remainder-participant--false"
                      value={false}
                      name="remainderParticipant"
                      type="radio"
                      checked={participant?.remainderParticipant === false}
                      onChange={handleRadioInputChange}
                    />
                    <span>No</span>
                  </label>
                </div>
              </div>
            </div>
          </Optional>
          <Optional show={participant?.feeSharing === false}>
            <div className="p-col-12">
              <div className="p-mt-1">
                <FormDropdown
                  required={true}
                  label="desc"
                  field="feeSharingAccountType"
                  error=""
                  disabled={loading}
                  value={feeSharingAccountType}
                  fn={saveAccountType}
                  options={accountTypes}
                  placeholder="Select debit account type *"
                />
              </div>
            </div>
            <div className="p-col-12">
              <div className="p-mt-1">
                <FormInput
                  verifyingField="account no"
                  verifying={verifyingAccountNumber}
                  value={participant["feeDebitAccount"]}
                  required={true}
                  field="feeDebitAccount"
                  type="NUBAN"
                  error={participantError["feeDebitAccount"]}
                  fn={
                    feeSharingAccountTypeName === "gl"
                      ? validateGlAccount
                      : validateForm
                  }
                  disabled={loading || feeSharingAccountTypeName.length === 0}
                  placeholder="Debit account no"
                />
              </div>
            </div>
            <div className="p-col-12">
              <div className="p-mt-1">
                <FormInput
                  value={participant["feeDebitAccountName"]}
                  required={true}
                  field="feeDebitAccountName"
                  type="NAME"
                  error={participantError["feeDebitAccountName"]}
                  fn={validateForm}
                  disabled={shouldDisableFeeAccountNameField()}
                  placeholder="Debit account name"
                />
              </div>
            </div>
          </Optional>

          <div className="p-col-12">
            <div className="p-mt-5">
              <div className="p-grid">
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {cancelButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div>{participantView()}</div>
    </div>
  );
}
