import React, { useContext, useState } from "react";
import { saveAs } from "file-saver";
import { CustomToast } from "components/elements/alert/custom-toast";
// import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { HELPER } from "utils/helper";
import moment from "moment";
import { API_SERVICE } from "api/service";
import { Optional } from "components/elements/optional/optional";
import { DownloadProgress } from "components/elements/download-progress/download-progress";
import { MainContext } from "App";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function DownloadConfirmation(props) {
  const [loading, setLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(null);
  const mainContext = useContext(MainContext);

  function downloadReport() {
    setLoading(true);
    let date = props?.report?.createdAt;
    if (date && date?.length === 7) {
      date.pop();
    }
    date = moment(date, "YYYY MM DD HH mm ss").format("YYYY-MM-DD");
    const frontofficeUrl =
      FRONT_OFFICE_API.TRANSACTION_REPORT.DOWNLOAD_TRANSACTION_REPORT;
    const backofficeUrl =
      BACK_OFFICE_API.TRANSACTION_REPORT.DOWNLOAD_TRANSACTION_REPORT;
    const url = props.type === "fo" ? frontofficeUrl : backofficeUrl;
    const isBlob = true;
    API_SERVICE.MAKE_GET_REQUEST(
      `${url}/${props?.report?.recordId}`,
      isBlob,
      mainContext
    )
      .then((data) => {
        saveAs(
          data,
          `${props?.report?.owner}-report_generated_at-${date}.xlsx`
        );
        props.closeModal();
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function handleError(error) {
    const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
    setDownloadError(errMessage);
    setLoading(false);
  }

  return (
    <div>
      <h4 className="p-text-left p-mt-0">Confirm Download!</h4>
      <p className="p-text-left">
        Confirm that you want to download this report.
      </p>
      <div className="p-pb-1">
        {downloadError && (
          <CustomToast
            title="Error"
            description={downloadError}
            type="error"
            clearToast={() => setDownloadError(null)}
          />
        )}
      </div>
      <Optional show={loading}>
        <DownloadProgress />
      </Optional>
      <Optional show={!loading}>
        <div className="p-mt-4 p-pb-2">
          <div className="p-grid">
            <div className="p-col-6">
              <button onClick={props.closeModal} className="secondary-button">
                Cancel
              </button>
            </div>
            <div className="p-col-6">
              <button onClick={downloadReport} className="primary-button">
                Download
              </button>
            </div>
          </div>
        </div>
      </Optional>
    </div>
  );
}
