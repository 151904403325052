import { CONFIG } from "config/config";

const STORAGE_TYPE = CONFIG.STORAGE_TYPE;
const inMemoryStorage = {};

export const getItem = (key) => {
  if ("LOCAL_STORAGE" === STORAGE_TYPE) return localStorage.getItem(key);
  else if ("SESSION_STORAGE" === STORAGE_TYPE)
    return sessionStorage.getItem(key);
  else if ("IN_MEMORY_STORAGE" === STORAGE_TYPE) return inMemoryStorage[key];
  else return undefined;
};

export const setItem = (key, value) => {
  if ("LOCAL_STORAGE" === STORAGE_TYPE) localStorage.setItem(key, value);
  else if ("SESSION_STORAGE" === STORAGE_TYPE)
    sessionStorage.setItem(key, value);
  else if ("IN_MEMORY_STORAGE" === STORAGE_TYPE) inMemoryStorage[key] = value;
};

export const clear = () => {
  if ("LOCAL_STORAGE" === STORAGE_TYPE) localStorage.clear();
  else if ("SESSION_STORAGE" === STORAGE_TYPE) sessionStorage.clear();
  else if ("IN_MEMORY_STORAGE" === STORAGE_TYPE)
    for (let key in inMemoryStorage) {
      delete inMemoryStorage[key];
    }
};

export const removeItem = (key) => {
  if ("LOCAL_STORAGE" === STORAGE_TYPE) localStorage.removeItem(key);
  else if ("SESSION_STORAGE" === STORAGE_TYPE) sessionStorage.removeItem(key);
  else if ("IN_MEMORY_STORAGE" === STORAGE_TYPE) delete inMemoryStorage[key];
};
