import moment from "moment";
import forge from "node-forge";
import { STORAGE } from "./storage";
import {
  FRONTOFFICE_AUTHORITIES_ROUTE_MAP,
  BACKOFFICE_AUTHORITIES_ROUTE_MAP,
} from "./models/authorities-route-map";
import { ProgressBar } from "components/elements/progress-bar/progress-bar";
import { ToolTip } from "components/elements/tooltip/tooltip";
import { CONFIG } from "config/config";

export const encryptionService = {
  decode,
  encryptLoginCredentials,
};

function replaceHTMLCodedCharacters(str) {
  const txt = document.createElement("textarea");
  txt.innerHTML = str.replace(/& #/g, "&#");
  return txt.value;
}

function decode(encoded) {
  const encodedLength = encoded.length;

  let textArray = encoded.split("");

  for (let i = 0; i < 5; i++) {
    const firstChar = textArray[0];
    for (let j = 0; j < encodedLength - 1; j++) {
      textArray[j] = textArray[j + 1];
    }
    textArray[encodedLength - 1] = firstChar;
  }

  const text = textArray.join("").replaceAll(":", "=");
  return atob(text);
}

function encryptLoginCredentials(payload) {
  const credentials = JSON.stringify({ ...payload, timestamp: Date.now() });
  const encrypted = CONFIG.PUBLIC_KEY.encrypt(credentials, "RSA-OAEP", {
    md: forge.md.sha256.create(),
    mgf1: {
      md: forge.md.sha1.create(),
    },
  });
  return { credentials: forge.util.encode64(encrypted) };
}

function toCurrencyFormat(data) {
  if (data) {
    data = parseFloat(data) / 100;
    return (
      "₦" +
      parseFloat(data)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    );
  } else {
    return "₦0.00";
  }
}

function toNumberFormat(value) {
  return new Intl.NumberFormat().format(value);
}

function formatDate(date) {
  if (typeof date === "object") {
    if (date) {
      let dateLength = date?.length;
      if (dateLength && dateLength === 7) {
        // removes millisecond
        date.pop();
      }
      return `${moment(date, "YYYY MM DD HH mm ss").format(
        "MMM Do YY"
      )} | ${moment(date, "YYYY MM DD HH mm ss").format("LTS")}`;
    } else {
      return "------";
    }
  } else {
    return `${moment(date).format("MMM Do YY")} | ${moment(date).format(
      "LTS"
    )}`;
  }
}

function formatDateOnly(date) {
  if (!date) {
    return "------";
  }
  if (date && typeof date === "object") {
    let dateLength = date?.length;
    if (dateLength && dateLength === 7) {
      // removes millisecond
      date.pop();
    }
    return `${moment(date, "YYYY MM DD HH mm ss").format("MMM DD, YYYY")}`;
  } else {
    return `${moment(date).format("MMM DD, YYYY")}`;
  }
}

function formatDateInMilliseconds(date) {
  if (date) {
    return `${moment(date).format("MMM Do YY")} | ${moment(date).format(
      "LTS"
    )}`;
  } else {
    return "------";
  }
}

function setCountdown(date) {
  if (!date) {
    return `${0} Day(s) ${0} Hours ${0} minutes ${0} seconds`;
  }
  let countdownDate;
  if (typeof date === "object") {
    // Remove millisicond from end of date array to allow proper handling of date
    if (date.length > 6) {
      date.pop();
    }
    let disputeExpiryDate = [...date];
    // Subtract one from the month at position 1 because date format months start at 0
    --disputeExpiryDate[1];
    countdownDate = new Date(...disputeExpiryDate).getTime();
  } else {
    countdownDate = new Date(date).getTime();
  }
  var now = new Date().getTime();
  var distance = countdownDate - now;
  if (!distance || distance < 0) {
    return false;
  } else {
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return `${days} Day(s) ${hours} Hours ${minutes} minutes ${seconds} seconds`;
  }
}
function getExpiryHour(date) {
  if (!date) {
    return null;
  }
  let expiryDate;
  if (typeof date === "object") {
    let disputeExpiryDate = [...date];
    // Subtract one from the month at position 1 because date format months start at 0
    --disputeExpiryDate[1];
    expiryDate = new Date(...disputeExpiryDate).getTime();
  } else {
    expiryDate = new Date(date).getTime();
  }

  var now = new Date().getTime();
  var distance = expiryDate - now;
  if (distance < 0) {
    return null;
  } else {
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    if (days < 1) {
      return hours;
    } else {
      return null;
    }
  }
}

function responseCodeColor(responseCode) {
  switch (responseCode) {
    case "00":
      return (
        <span
          className="custom-badge custom-badge-code"
          style={{
            backgroundColor: "#37AC00",
            fontWeight: "600",
            color: "#ffffff",
          }}
        >
          {responseCode}
        </span>
      );
    case "91":
    case "92":
    case "96":
    case "22":
    case "06":
      return (
        <span
          className="custom-badge custom-badge-code"
          style={{
            backgroundColor: "#f44435",
            fontWeight: "600",
            color: "#ffffff",
          }}
        >
          {responseCode}
        </span>
      );
    default:
      return (
        <span
          className="custom-badge custom-badge-code"
          style={{
            backgroundColor: "#50B8EE",
            fontWeight: "600",
            color: "#ffffff",
          }}
        >
          {responseCode}
        </span>
      );
  }
}

function titleCase(str) {
  if (str === null || str === "" || !str) return false;
  else str = str.toString();

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function getProcessError(error, specialCase = null) {
  const errorData = error?.data?.errors;
  const err = error?.data?.error
    ? [`${error?.data?.error}`]
    : ["Something went wrong ..."];
  const errors = errorData?.length ? errorData : err;

  let result = "";
  errors.forEach((e) => {
    result += e.message
      ? specialCase === "NAME_ENQUIRY" || specialCase === "TOAST"
        ? e.message
        : `${e.message}`
      : e;
  });
  return result;
  // }
}

async function parseBlobError(error) {
  const parsedError = JSON.parse(await error?.data?.text());
  const errors = parsedError?.errors
    ? parsedError?.errors
    : parsedError?.error
    ? [parsedError?.error]
    : ["Something went wrong..."];
  let errMessage = "";
  errors.forEach((error) => {
    errMessage += error?.message ? error?.message : error;
  });
  return errMessage;
}

function percentageStatus(value) {
  if (value === null) {
    return "------";
  } else {
    return <ProgressBar value={value} />;
  }
}

function settlementWarningStatus(response) {
  return (
    <div className="flex default">
      <span className="custom-badge custom-badge-text custom-badge-warning">
        {titleCase(response.toLowerCase())}
      </span>
      {/* <Icon icon="warning-status" /> */}
      <ToolTip
        tip="Settlements need to be processed manually."
        icon="warning-status"
      />
    </div>
  );
}

function responseStatus(response, property) {
  const successfulStatuses = [
    "successCount",
    "success",
    "available",
    "successful",
    "ok",
    "credit",
    "accepted",
    "resolved",
    "approved",
    "active",
    "completed",
    "created",
    "true",
  ];
  const warningStatuses = ["uploadTotalCount", "pending"];
  const errorStatuses = [
    "declined",
    "debit",
    "failed",
    "blocked",
    "deleted",
    "rejected",
  ];
  if (typeof response === "number") {
    switch (property) {
      case "uploadTotalCount":
        return (
          <span className="custom-badge custom-badge-text custom-badge-pending">
            {response}
          </span>
        );
      case "successCount":
        return (
          <span className="custom-badge custom-badge-text custom-badge-success">
            {response}
          </span>
        );
      case "failedCount":
        return (
          <span className="custom-badge custom-badge-text custom-badge-error">
            {response}
          </span>
        );
      default:
        return;
    }
  }
  const status = response ? response?.toString()?.toLowerCase() : response;
  let statusToDisplay = titleCase(status);
  if (!response) {
    return "------";
  }
  if (successfulStatuses.includes(status)) {
    return (
      <span className="custom-badge custom-badge-text custom-badge-success">
        {statusToDisplay}
      </span>
    );
  } else if (warningStatuses.includes(status)) {
    return (
      <span className="custom-badge custom-badge-text custom-badge-pending">
        {statusToDisplay}
      </span>
    );
  } else if (errorStatuses.includes(status)) {
    return (
      <span className="custom-badge custom-badge-text custom-badge-error">
        {statusToDisplay}
      </span>
    );
  } else if (status === "") {
    return "------";
  } else {
    return (
      <span className="custom-badge custom-badge-text custom-badge-warning">
        {statusToDisplay}
      </span>
    );
  }
}

function toTitleCase(str) {
  if (str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }
  return str;
}

function toUrlString(obj) {
  let urlString = "";
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (obj[prop] != null && obj[prop].constructor === Array) {
        urlString = createUrlString(obj[prop], prop);
      } else {
        urlString += prop + "=" + encodeURIComponent(obj[prop]) + "&";
      }
    }
  }
  return urlString.substr(0, urlString.length - 1);
}

function createUrlString(arr, prop) {
  let urlString = "";
  arr.forEach((i) => {
    urlString += `${prop}=${i}&`;
  });
  return urlString;
}

function getRefineRole() {
  const roles = STORAGE.GET_ROLES();
  switch (roles[0]) {
    case "DCIR_BACKOFFICE_ADMIN":
      return "Backoffice Admin";
    case "DCIR_MERCHANT_SUPER_ADMIN":
      return "Super Agent Admin";
    case "DCIR_BACKOFFICE_OPERATOR":
      return "Backoffice Operator";
    default:
      return roles[0]?.split("_")?.join(" ")?.toLowerCase();
  }
}

function getUserLoginRoute(authorities) {
  const roleType = getUserRoleType(authorities);
  const route = getRouteFromAuthorities(roleType, authorities);
  return route ? `/${roleType}/${route}` : `/account/change-password`;
}

function getUserRoleType(authorities) {
  let userRoleType;
  for (let i = 0; i < authorities.length; i++) {
    let authorityArr = authorities[i].split("_");
    if (authorityArr.includes("bo")) {
      userRoleType = "back-office";
      break;
    } else if (authorityArr.includes("fo")) {
      userRoleType = "front-office";
      break;
    }
  }
  return userRoleType;
}

function getRouteFromAuthorities(roleType, authorities) {
  let route;
  if (roleType === "back-office") {
    let routeKeys = Object.keys(BACKOFFICE_AUTHORITIES_ROUTE_MAP);
    for (let i = 0; i < routeKeys.length; i++) {
      if (authorities.includes(routeKeys[i])) {
        route = BACKOFFICE_AUTHORITIES_ROUTE_MAP[routeKeys[i]];
        break;
      }
    }
  } else {
    let routeKeys = Object.keys(FRONTOFFICE_AUTHORITIES_ROUTE_MAP);
    for (let i = 0; i < routeKeys.length; i++) {
      if (authorities.includes(routeKeys[i])) {
        route = FRONTOFFICE_AUTHORITIES_ROUTE_MAP[routeKeys[i]];
        break;
      }
    }
  }
  return route;
}

function hasAuthority(authority) {
  if (authority === "all") {
    return true;
  }
  const authorities = STORAGE.GET_AUTHORITIES();
  return authorities.includes(authority);
}

function hasRole(rolesArray) {
  let hasRole = false;
  const roles = STORAGE.GET_ROLES();
  rolesArray.forEach((e) => {
    if (roles.includes(e)) {
      hasRole = true;
    }
  });
  return hasRole;
}

function canPerformAction(authorities, action) {
  let authority = "";
  authorities.forEach((e) => {
    if (e.label === action) {
      authority = e.value;
    }
  });

  return hasAuthority(authority);
}

// function getDateDifference(startDate, endDate) {
//   let startDateObj = new Date(startDate);
//   let endDateObj = new Date(endDate);
//   console.log("start", startDate);
//   let dateDifference =
//     Math.abs(startDateObj.getTime() - endDateObj.getTime()) /
//     1000 /
//     60 /
//     60 /
//     24;
//   return dateDifference;
// }

function getDateDifference(startDate, endDate) {
  // Convert both dates to UTC
  let date1 = new Date(startDate);
  let date2 = new Date(endDate);
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);

  const utcDate1 = Date.UTC(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate()
  );
  const utcDate2 = Date.UTC(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate()
  );

  // Calculate the time difference in milliseconds
  const timeDifference = utcDate2 - utcDate1;

  // Convert milliseconds to days
  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return dayDifference + 1;
}

function removeEmptyValues(obj) {
  const propNames = Object.getOwnPropertyNames(obj);
  // @ts-ignore
  propNames.forEach((propName) => {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "undefined" ||
      String(obj[propName]) === ""
    ) {
      delete obj[propName];
    }
  });
  return obj;
}

function getDisputeDetailsArray(dispute, isReversal) {
  let arr = [];
  arr.push({
    label: "Resolution status",
    value: dispute?.resolutionStatus,
    itemCase: "resolutionStatus",
  });
  arr.push({
    label: "Dispute status",
    value: dispute?.status,
    itemCase: "status",
  });
  arr.push({ label: "Log code", value: dispute?.logCode });
  arr.push({ label: "Logged by", value: dispute?.loggedBy });
  arr.push({
    label: "Logged on",
    value: dispute?.createdOn,
    itemCase: "createdOn",
  });
  arr.push({ label: "Reason", value: dispute?.reason });
  arr.push({ label: "Resolved by", value: dispute?.resolvedBy || "___" });
  arr.push({
    label: "Transaction amount",
    value: dispute?.transactionAmount,
    itemCase: "transactionAmount",
  });
  arr.push({
    label: "Transaction response code",
    value: dispute?.transactionResponseCode,
    itemCase: "responseCode",
  });
  arr.push({
    label: "Transaction search key",
    value: dispute?.transactionSearchKey,
    action: "viewTransaction",
    transactionDetails: dispute,
  });
  arr.push({
    label: "Transaction time",
    value: dispute?.transactionTime,
    itemCase: "createdOn",
  });
  arr.push({ label: "Super Agent id", value: dispute?.merchantId });
  arr.push({ label: "Super Agent name", value: dispute?.merchantName });
  arr.push({
    label: "Customer account name",
    value: dispute?.customerAccountName,
  });
  arr.push({
    label: "Customer account no",
    value: dispute?.customerAccountNumber,
  });
  if (dispute?.refundTransaction && isReversal === "true") {
    arr.push({
      label: "Reversal status",
      value: dispute?.refundTransaction?.status,
      itemCase: "status",
    });
    arr.push({
      label: "Reversal amount",
      value: dispute?.refundTransaction?.amount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Reversal destination account",
      value: dispute?.refundTransaction?.destinationAccount,
    });
    arr.push({
      label: "Reversal destination account name",
      value: dispute?.refundTransaction?.destinationAccountName,
    });

    arr.push({
      label: "Reversal request creation time",
      value: dispute?.refundTransaction?.createdOn,
      itemCase: "createdOn",
    });
    arr.push({
      label: "Reversal last update time",
      value: dispute?.refundTransaction?.lastUpdatedTime,
      itemCase: "createdOn",
    });
    arr.push({
      label: "Reversal attempts",
      value: dispute?.refundTransaction?.attempts,
    });
    arr.push({
      label: "Reversal narration",
      value: dispute?.refundTransaction?.narration,
    });
  } else if (dispute?.refundTransaction) {
    arr.push({
      label: "Refund status",
      value: dispute?.refundTransaction?.status,
      itemCase: "status",
    });
    arr.push({
      label: "Refund amount",
      value: dispute?.refundTransaction?.amount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Refund destination account",
      value: dispute?.refundTransaction?.destinationAccount,
    });
    arr.push({
      label: "Refund destination account name",
      value: dispute?.refundTransaction?.destinationAccountName,
    });
    arr.push({
      label: "Refund source account",
      value: dispute?.refundTransaction?.sourceAccount,
    });
    arr.push({
      label: "Refund source account name",
      value: dispute?.refundTransaction?.sourceAccountName,
    });
    arr.push({
      label: "Refund request creation time",
      value: dispute?.refundTransaction?.createdOn,
      itemCase: "createdOn",
    });
    arr.push({
      label: "Refund last update time",
      value: dispute?.refundTransaction?.lastUpdatedTime,
      itemCase: "createdOn",
    });
    arr.push({
      label: "Refund attempts",
      value: dispute?.refundTransaction?.attempts,
    });
    arr.push({
      label: "Refund narration",
      value: dispute?.refundTransaction?.narration,
    });
  }

  return arr;
}

const transformView = (itemCase, value) => {
  let result = value;
  if (itemCase) {
    SpecialLabelCases.forEach((e) => {
      if (e.case === itemCase) {
        result = e.action(value);
      }
    });
  }
  return result;
};

function getSettlementDetails(e) {
  let arr = [];
  // setMerchantDetails([]);
  // setDetails([]);
  const dateRange = `${formatDate(e?.transactionTimeBegin)} - ${formatDate(
    e?.transactionTimeEnd
  )}`;
  // setBulkSettlementKey(e?.bulkSettlementKey);
  arr.push({ label: "Super Agent", value: e?.merchant?.merchantName });
  arr.push({
    label: "Transaction Count",
    value: e?.transactionCount,
  });
  arr.push({
    label: "Total Amount",
    value: e?.transactionTotalAmount,
    itemCase: "transactionAmount",
  });
  arr.push({
    label: "Total Transaction Charge",
    value: e?.transactionChargeAmount,
    itemCase: "transactionAmount",
  });
  arr.push({
    label: "Total VAT Amount",
    value: e?.transactionVatAmount,
    itemCase: "transactionAmount",
  });
  arr.push({
    label: "Super Agent Net Settlement",
    value: e?.transactionMerchantAmount,
    itemCase: "transactionAmount",
  });

  arr.push({ label: "Transaction Date Range", value: dateRange });
  arr.push({
    label: "Settlement Status",
    value: e?.status,
    itemCase: "status",
  });
  arr.push({ label: "Settlement Type", value: e?.settlementType });
  arr.push({
    label: "Report Status",
    value: e?.reportStatus,
    itemCase: "status",
  });
  arr.push({ label: "Bulk Settlement Key", value: e?.bulkSettlementKey });
  arr.push({
    label: "Payment Request Attempt",
    value: e?.paymentRequestAttempt,
  });
  arr.push({
    label: "Report Generation Attempt",
    value: e?.reportGenerationAttempt,
  });
  arr.push({
    label: "Created On",
    value: e?.createdAt,
    itemCase: "transactionTime",
  });
  arr.push({
    label: "Completed On",
    value: e?.completedAt,
    itemCase: "transactionTime",
  });

  // setDetails(arr);
  return arr;
}

function camelToTitleCase(inputString) {
  const words = inputString.split(/(?=[A-Z])/);
  const titleCase = words
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");

  return titleCase;
}

function toggleUserManualView(mainContext, fileName) {
  mainContext.mainDispatch({
    type: "TOGGLE_MARKDOWN_VIEW",
    fileName: fileName,
  });
}

const formatObjectToArray = (data) => {
  let arr = [];
  const objectKeys = Object.keys(data);
  objectKeys.map((key) =>
    arr.push({
      label: HELPER.CAMEL_TO_TITLE_CASE(key),
      value:
        typeof data[key] === "object" ? JSON.stringify(data[key]) : data[key],
      itemCase: key,
    })
  );
  return arr;
};

export const HELPER = {
  FORMAT_OBJECT_TO_ARRAY: formatObjectToArray,
  TO_CURRENCY_FORMAT: toCurrencyFormat,
  TO_NUMBER_FORMAT: toNumberFormat,
  FORMAT_DATE: formatDate,
  FORMAT_DATE_ONLY: formatDateOnly,
  FORMAT_DATE_IN_MILLISECONDS: formatDateInMilliseconds,
  RESPONSE_CODE_COLOR: responseCodeColor,
  RESPONSE_STATUS: responseStatus,
  SETTLEMENT_WARNING_STATUS: settlementWarningStatus,
  PROCESS_ERROR: getProcessError,
  TO_URL_STRING: toUrlString,
  GET_ROLE: getRefineRole,
  getUserRoleType,
  HAS_AUTHORITY: hasAuthority,
  HAS_ROLE: hasRole,
  CAN_PERFORM_ACTION: canPerformAction,
  TITLE_CASE: toTitleCase,
  SET_COUNTDOWN: setCountdown,
  GET_EXPIRY_HOUR: getExpiryHour,
  GET_USER_LOGIN_ROUTE: getUserLoginRoute,
  PARSE_BLOB_ERROR: parseBlobError,
  PERCENTAGE_STATUS: percentageStatus,
  GET_DATE_DIFFERENCE: getDateDifference,
  TRIM_OBJECT: removeEmptyValues,
  GET_DISPUTE_DETAILS_ARRAY: getDisputeDetailsArray,
  TRANSFORM_VIEW: transformView,
  GET_SETTLEMENT_DETAILS: getSettlementDetails,
  CAMEL_TO_TITLE_CASE: camelToTitleCase,
  TOGGLE_USER_MANUAL_VIEW: toggleUserManualView,
  ENCRYPTION_SERVICE: encryptionService,
  REPLACE_HTML_CODED_CHARACTERS: replaceHTMLCodedCharacters,
};

export const SpecialLabelCases = [
  { case: "transactionAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "transactionMerchantAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "transactionChargeAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "transactionTotalAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "amount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "flat", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "minimumCap", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "maximumCap", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "theDate", action: HELPER.FORMAT_DATE },
  { case: "transactionTime", action: HELPER.FORMAT_DATE },
  { case: "settlementAttemptDateTime", action: HELPER.FORMAT_DATE },
  { case: "reportTime", action: HELPER.FORMAT_DATE },
  { case: "transactionDate", action: HELPER.FORMAT_DATE },
  { case: "tatExpiryDate", action: HELPER.FORMAT_DATE },
  { case: "chargeAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "requestTime", action: HELPER.FORMAT_DATE },
  { case: "logTime", action: HELPER.FORMAT_DATE },
  { case: "settlementTime", action: HELPER.FORMAT_DATE },
  { case: "responseCode", action: HELPER.RESPONSE_CODE_COLOR },
  { case: "resolutionStatus", action: HELPER.RESPONSE_STATUS },
  { case: "creationStatus", action: HELPER.RESPONSE_STATUS },
  { case: "disputeCreationStatus", action: HELPER.RESPONSE_STATUS },
  { case: "reportGenerationStatus", action: HELPER.RESPONSE_STATUS },
  { case: "status", action: HELPER.RESPONSE_STATUS },
  { case: "processStatus", action: HELPER.RESPONSE_STATUS },

  { case: "formattedStatus", action: HELPER.RESPONSE_STATUS },
  { case: "createdOn", action: HELPER.FORMAT_DATE },
  { case: "uploadedAt", action: HELPER.FORMAT_DATE },
  { case: "active", action: HELPER.RESPONSE_STATUS },
  { case: "pending", action: HELPER.RESPONSE_STATUS },
  { case: "failed", action: HELPER.RESPONSE_STATUS },
  { case: "createdAt", action: HELPER.FORMAT_DATE },
  { case: "completedAt", action: HELPER.FORMAT_DATE },
  { case: "createdTime", action: HELPER.FORMAT_DATE },
  { case: "completedTime", action: HELPER.FORMAT_DATE },
  { case: "creationTime", action: HELPER.FORMAT_DATE },
  { case: "percentageProcessed", action: HELPER.PERCENTAGE_STATUS },
  { case: "retentionStatus", action: HELPER.RESPONSE_STATUS },
  { case: "passwordStatus", action: HELPER.RESPONSE_STATUS },
  { case: "requestStatus", action: HELPER.RESPONSE_STATUS },
  { case: "attemptTime", action: HELPER.FORMAT_DATE },
  { case: "uploadTotalCount", action: HELPER.RESPONSE_STATUS },
  { case: "successCount", action: HELPER.RESPONSE_STATUS },
  { case: "failedCount", action: HELPER.RESPONSE_STATUS },
  { case: "transactionType", action: HELPER.RESPONSE_STATUS },
  { case: "lastModifiedOn", action: HELPER.FORMAT_DATE },
];
