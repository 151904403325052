import { useEffect } from "react";
export function usePolling(func, hideLoader) {
  useEffect(() => {
    const interval = setInterval(() => {
      func(hideLoader);
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, [func, hideLoader]);
}
