import "./App.css";
import PrimeReact from "primereact/api";
import React, { useReducer, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { monitoringInitialState } from "./redux/state/state";
import { monitoringReducer } from "./redux/reducer/reducer";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";
import axios from "axios";
import { ApplicationMonitoringLogin } from "./features/monitoring/login/login";
import { MonitoringApp } from "./components/layout/monitoring-app/monitoring-app";
import { HELPER } from "./utils/helper";
import { UserManualViewer } from "./components/elements/user-manual-viewer/user-manual-viewer";
import { Optional } from "./components/elements/optional/optional";
import { CustomModal } from "./components/elements/custom-modal/custom-modal";
import { Login } from "./features/login/login";
import { ChangePassword } from "./features/common/change-password/change-password";
import { reducer } from "./redux/reducer/reducer";
import { initialState } from "./redux/state/state";
import { MainApp } from "./components/layout/main-app/main-app";
import { STORAGE } from "./utils/storage";
import { TwoFactor } from "./features/login/two-factor/two-factor";
import { CustomToast } from "./components/elements/alert/custom-toast";
import { AzureLoaderPage } from "features/common/azure-loader-page/azure-loader-page";
import * as webStorage from "utils/webStorage";
import CookieConsent from "components/elements/cookie-consent/cookie-consent";
import PrivacyPolicy from "components/elements/privacy-policy/privacy-policy";
import Footer from "components/elements/footer/footer";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      if (window.location.pathname !== "/login") {
        webStorage.clear();
        const isMonitoringLogin = STORAGE.IS_MONITORING_LOGIN();
        const loginRoute = isMonitoringLogin ? "monitor" : "login";
        window.location.href = `/${loginRoute}?session_expired=true`;
      }
    }
    return Promise.reject(error);
  }
);

export const MainContext = React.createContext();
export const MonitoringContext = React.createContext();
PrimeReact.ripple = true;
PrimeReact.zIndex = {
  modal: 1100, // dialog, sidebar
  overlay: 1000, // dropdown, overlaypanel
  menu: 1000, // overlay menus
  tooltip: 1100, // tooltip
  toast: 1200, // toast
};
PrimeReact.autoZIndex = true;
PrimeReact.appendTo = "self"; // Default value is null(document.body).

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [monitoringState, monitoringDispatch] = useReducer(
    monitoringReducer,
    monitoringInitialState
  );

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);
  const [isMonitoringUserAuthenticated, setIsMonitoringUserAuthenticated] =
    useState(true);

  const isProduction = process.env.NODE_ENV === "production";
  // const [loading, setLoading] = useState(false);

  function checkIfAuthenticated() {
    let isAuthenticated = state.isAuthenticated;
    if (!isAuthenticated) {
      isAuthenticated = STORAGE.IS_LOGGED_IN();
      // if (isAuthenticated) {
      // setUserData();
      setIsUserAuthenticated(isAuthenticated);
      // }
    }
    return isAuthenticated;
  }

  function checkIfMonitoringUserIsAuthenticated() {
    let isAuthenticated = monitoringState.isAuthenticated;
    if (!isAuthenticated) {
      isAuthenticated = STORAGE.IS_MONITORING_LOGIN();
      setIsMonitoringUserAuthenticated(isAuthenticated);
    }
    return isMonitoringUserAuthenticated;
  }

  useEffect(() => {
    function setUserData() {
      const credentials = STORAGE.GET_USER_CREDENTIALS();
      dispatch({ type: "PERSIST_LOGIN_DATA", loginData: credentials });
    }
    if (isUserAuthenticated) {
      setUserData();
    }
  }, [isUserAuthenticated]);

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
    >
      <div className="App">
        <CookieConsent />
        <PrivacyPolicy />
        {state.alert.showAlert && (
          <CustomToast
            title={state.alert.title}
            description={state.alert.description}
            type={state.alert.type}
          />
        )}

        <Optional show={state.markdown.showMarkdown}>
          <div className="markdowns">
            <CustomModal
              onHide={() => {
                HELPER.TOGGLE_USER_MANUAL_VIEW({ mainDispatch: dispatch });
              }}
              visible={state.markdown.showMarkdown}
              // modalContent={<div></div>}
              modalContent={() => (
                <UserManualViewer fileName={state.markdown.name} />
              )}
              closeModal={() =>
                HELPER.TOGGLE_USER_MANUAL_VIEW({ mainDispatch: dispatch })
              }
            />
          </div>
        </Optional>

        <Router>
          <div className="flex-1">
            <MainContext.Provider
              value={{
                mainState: state,
                mainDispatch: dispatch,
              }}
            >
              <MonitoringContext.Provider
                value={{ monitoringState, monitoringDispatch }}
              >
                <Switch>
                  <Route
                    exact
                    path="/change-password"
                    component={ChangePassword}
                  />

                  <Route exact path="/login" component={Login} />
                  <Route
                    exact
                    path="/login/second-factor"
                    component={TwoFactor}
                  />
                  <Route
                    exact
                    path="/login/azure-ad"
                    component={AzureLoaderPage}
                  />

                  <Route
                    exact
                    path="/monitor"
                    component={ApplicationMonitoringLogin}
                  />

                  {/* <Route exact path="/" component={Login} /> */}
                  <Route
                    path="/monitor"
                    render={() =>
                      checkIfMonitoringUserIsAuthenticated() ? (
                        <MonitoringApp />
                      ) : (
                        <Login isMonitoringLogin={true} />
                      )
                    }
                  />
                  <Route
                    path="/"
                    render={() =>
                      checkIfAuthenticated() ? <MainApp /> : <Login />
                    }
                  />
                </Switch>
              </MonitoringContext.Provider>
            </MainContext.Provider>
          </div>
        </Router>
        <Footer />
      </div>
    </CacheBuster>
  );
}

export default App;
