import "./bulk-settlement.css";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { Icon } from "components/elements/icons/icon";
import { SpecialLabelCases } from "utils/models/utilities";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { BulkTransactions } from "./bulk-transactions";
// import { RepaymentRequest } from "./repayment-request";
import { useWindowSize } from "hooks/use-window-size";
import { NewDispute } from "../../back-office/disputes/new-dispute";
import { saveAs } from "file-saver";
import { API_SERVICE } from "api/service";
import { HELPER } from "utils/helper";
import { Toast } from "primereact/toast";
import { CustomToast } from "components/elements/alert/custom-toast";
import { MainContext } from "App";
import { Optional } from "components/elements/optional/optional";
import { DownloadProgress } from "components/elements/download-progress/download-progress";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";

export function BulkSettlementBreakDown(props) {
  const mainContext = useContext(MainContext);
  const toast = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [currentModalIndex, setCurrentModalIndex] = useState(1);
  const [error] = useState(null);
  const [detailsContent, setDetailsContent] = useState([]);
  const [disputeCodes] = useState([]);
  const windowSize = useWindowSize();
  const [downloadError, setDownloadError] = useState(null);
  const [loading, setLoading] = useState(false);

  function isMobile() {
    return windowSize.width <= 930;
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setDetailsContent(props?.detials);
    }
    return () => {
      mounted = false;
    };
  }, [props?.detials]);

  function downloadBulkSettlement() {
    // closeModal();
    setLoading(true);

    const url = FRONT_OFFICE_API.BULK_SETTLEMENT.DOWNLOAD_BULK_SETTLEMENT;
    const isBlob = true;
    API_SERVICE.MAKE_GET_REQUEST(
      `${url}/${props.bulkSettlementKey}`,
      isBlob,
      mainContext
    )
      .then((data) => {
        saveAs(data, `${props.bulkSettlementKey}.xlsx`);
        closeModal();
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function handleError(error) {
    const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
    setDownloadError(errMessage);
    setLoading(false);
    // toast.current.show({
    //   severity: "error",
    //   summary: "Error downloading settlement report!",
    //   detail: errMessage,
    //   life: 10000,
    // });
  }

  const transformView = (itemCase, value) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          result = e.action(value);
        }
      });
    }
    return result;
  };

  const detailsView = () => {
    return detailsContent.map((details, index) => (
      <div key={index.toString()} className="p-grid dcir-row">
        <div className="p-col-6">
          <p className="details-label">{details.label}:</p>
        </div>
        <div className="p-col-6">
          <p className="details-value">
            {transformView(details.itemCase, details.value)}
          </p>
        </div>
      </div>
    ));
  };

  const errorView = () => {
    return (
      <div>
        <div className="empty-container">
          <Icon icon="error-message-icon" />
          <div>
            <p className="empty-text">{error}</p>
          </div>
          <div className="p-mt-3">
            <div style={{ maxWidth: "200px" }}>
              <button className="primary-button">Reload</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showResolvedButton = () => {
    if (!disputeCodes?.includes(detailsContent[3]?.value)) {
      return <div />;
    } else {
      return (
        <button onClick={() => openModal(1)} className="primary-button">
          Log Dispute
        </button>
      );
    }
  };

  function isReportCompleted() {
    let reportStatus = detailsContent.filter((detail) => {
      return detail.label === "Report Status";
    })[0];
    if (reportStatus?.value === "COMPLETED") {
      return true;
    }
    return false;
  }

  const [indexView, setIndexView] = useState(0);
  function setCurrentIndexView(index) {
    setIndexView(index);
  }

  const settlementTabs = () => {
    return (
      <div
        style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
        className="p-shadow-1 p-pb-5"
      >
        <div className="p-text-left p-ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          <TabPanel header={isMobile() ? "BS" : "Bulk Settlement Summary"}>
            <div className="p-text-left">
              <div className="p-grid p-mt-2">
                <div className="p-col-8" />
                <div className="p-col-4">
                  <div style={{ width: "135px", float: "right" }}>
                    {showResolvedButton()}
                  </div>
                  {isReportCompleted() && (
                    <div style={{ width: "150px", float: "right" }}>
                      <button
                        onClick={() => openModal(2)}
                        className="download-button"
                        style={{ fontSize: "0.75rem" }}
                      >
                        <i className="pi pi-download"></i> Download Report
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="centralize">
                <p
                  className={
                    isMobile() ? "custom-title p-ml-4 dcir-show" : "dcir-hide"
                  }
                >
                  Settlement Details
                </p>

                {detailsView()}
              </div>
            </div>
          </TabPanel>
          {HELPER.HAS_AUTHORITY("fo_dcir_get_transactions_per_bulk") ? (
            <TabPanel header={isMobile() ? "TS" : "Transaction Summary"}>
              <div
                style={{ position: "relative", top: "3.5em" }}
                className="p-text-left"
              >
                <p
                  className={
                    isMobile() && indexView === 0
                      ? "custom-title dcir-show"
                      : "dcir-hide"
                  }
                >
                  Transactions
                </p>
              </div>
              <BulkTransactions
                isMobile={isMobile()}
                currentIndexView={setCurrentIndexView}
                bulkSettlementKey={props.bulkSettlementKey}
              />
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
        </TabView>
      </div>
    );
  };

  const settlementBreakdown = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return settlementTabs();
      case 1:
        return (
          <div className="p-mt-5">
            <CustomLoader loadingText="loading transaction details ..." />
          </div>
        );
      case 2:
        return errorView();
      case 3:
        return (
          <div className="custom-mobile-table-card">
            <NewDispute
              transactionSearchKey={props?.transactionSearchKey}
              closeDisputeModal={closeModal}
            />
          </div>
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <NewDispute
            transactionSearchKey={props?.transactionSearchKey}
            closeDisputeModal={closeModal}
          />
        );
      case 2:
        return (
          <div>
            <h4 className="p-text-left p-mt-0">Confirm Download!</h4>
            <p className="p-text-left">
              Confirm that you want to download this report.
            </p>
            <div className="p-pb-1">
              {downloadError && (
                <CustomToast
                  title="Error"
                  description={downloadError}
                  type="error"
                  clearToast={() => setDownloadError(null)}
                />
              )}
            </div>
            <Optional show={loading}>
              <DownloadProgress />
            </Optional>
            {/* {loading ? (
              <CustomLoader loadingText="loading..." />
            ) : ( */}
            <Optional show={!loading}>
              <div className="p-mt-4 p-pb-2">
                <div className="p-grid">
                  <div className="p-col-6">
                    <button onClick={closeModal} className="secondary-button">
                      Cancel
                    </button>
                  </div>
                  <div className="p-col-6">
                    <button
                      onClick={downloadBulkSettlement}
                      className="primary-button"
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </Optional>
            {/* )} */}
          </div>
        );
    }
  };

  function onHide() {}

  function closeModal(reload) {
    if (props.mobile) {
      setCurrentIndex(0);
    } else {
      setVisible(false);
    }
    if (reload) {
      setCurrentIndex(0);
    }
  }

  function openModal(index) {
    if (props.mobile) {
      setCurrentIndex(index);
    } else {
      setCurrentModalIndex(index);
      setVisible(true);
    }
  }

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div>
        <CustomModal
          onHide={onHide}
          visible={visible}
          modalContent={modalContent}
          closeModal={closeModal}
        />
      </div>
      {settlementBreakdown()}
    </div>
  );
}
