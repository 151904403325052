import "./merchant.css";
import { useEffect, useState } from "react";
import { CustomToast } from "components/elements/alert/custom-toast";
import { ConfigureSettlement } from "./configure-settlement";
import { FormInput } from "components/form/form-input";
import { CUSTOM_VALIDATION } from "utils/validation";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { API_SERVICE } from "api/service";
import { CustomMessage } from "components/elements/alert/custom-message";
import { HELPER } from "utils/helper";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";
import { InputSwitch } from "primereact/inputswitch";

export function SuperAgentForm(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [messageTitle, setMessageTitle] = useState(null);
  const [alertType, setAlertType] = useState("success");
  const [message, setMessage] = useState(null);
  const [validForm, setValidForm] = useState(false);
  const [hideTitle, setHideTitle] = useState(false);
  const [optionalFormFields] = useState([
    "alternatePhoneNumber",
    "supportEmail",
    "settlementEmail",
    "disputeEmail",
  ]);
  const [isSettlementConfigured, setIsSettlementConfigured] = useState(true);
  const [merchantForm, setMerchantForm] = useState({
    merchantName: null,
    adminEmail: null,
    mainEmail: null,
    phoneNumber: null,
    cardAcceptorId: null,
    alternatePhoneNumber: null,
    supportEmail: null,
    settlementEmail: null,
    disputeEmail: null,
    adminLastname: null,
    adminFirstname: null,
  });

  const [merchantUpdateForm, setMerchantUpdateForm] = useState({
    merchantName: null,
    mainEmail: null,
    phoneNumber: null,
    merchantId: null,
    supportEmail: null,
    settlementEmail: null,
    disputeEmail: null,
  });

  const [merchantUpdateErrorForm, setMerchantUpdateErrorForm] = useState({
    merchantName: null,
    mainEmail: null,
    phoneNumber: null,
    supportEmail: null,
    settlementEmail: null,
    disputeEmail: null,
  });

  const [merchantErrorForm, setMerchantErrorForm] = useState({
    merchantName: null,
    adminEmail: null,
    mainEmail: null,
    phoneNumber: null,
    cardAcceptorId: null,
    alternatePhoneNumber: null,
    supportEmail: null,
    settlementEmail: null,
    disputeEmail: null,
    adminLastname: null,
    adminFirstname: null,
  });

  const formatPayload = (isUpdate = false) => {
    if (isUpdate) {
      return {
        ...merchantUpdateForm,
        enableSettlement: isSettlementConfigured,
      };
    } else {
      return {
        basicInfo: merchantForm,
        enableSettlement: isSettlementConfigured,
      };
    }
  };

  function updateMerchant() {
    setLoading(true);
    const payload = formatPayload(props.isUpdate);
    const url = BACK_OFFICE_API.SUPER_AGENT.UPDATE_SUPER_AGENT;
    API_SERVICE.MAKE_PUT_REQUEST(
      `${url}/${merchantUpdateForm?.merchantId}`,
      payload
    )
      .then(() => {
        handleSuccess("updated");
      })
      .catch((error) => {
        handleError(error);
      });
  }

  const createMerchant = () => {
    setLoading(true);
    const payload = formatPayload();
    const url = BACK_OFFICE_API.SUPER_AGENT.CREATE_SUPER_AGENT;
    API_SERVICE.MAKE_POST_REQUEST(url, payload)
      .then(() => {
        handleSuccess("created");
      })
      .catch((error) => {
        handleError(error);
      });
  };

  function handleSuccess(action) {
    setMessage(`Super agent ${action} successfully`);
    offTitle();
    if (isSettlementConfigured) {
      setCurrentIndex(1);
    } else {
      setCurrentIndex(2);
    }
    setLoading(false);
  }

  const handleError = (error) => {
    callAlert("Error", HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };
  function callAlert(title, description) {
    setAlertType("error");
    setMessage(description);
    setMessageTitle(title);
  }

  useEffect(() => {
    if (props.isUpdate) {
      preFillForm(props.editMerchant);
      setIsSettlementConfigured(props.editMerchant?.settlementConfigured);
    }
  }, [props.isUpdate, props.editMerchant]);

  function preFillForm(e) {
    setMerchantUpdateForm(e);
  }

  useEffect(() => {
    function checkValidBasicForm() {
      if (props.isUpdate) {
        const noError = !CUSTOM_VALIDATION.VALID_OBJ_ANY(
          merchantUpdateErrorForm
        );
        return (
          CUSTOM_VALIDATION.IS_VALID_FORM(
            merchantUpdateForm,
            optionalFormFields
          ) && noError
        );
      } else {
        const noError = !CUSTOM_VALIDATION.VALID_OBJ_ANY(merchantErrorForm);
        return (
          CUSTOM_VALIDATION.IS_VALID_FORM(merchantForm, optionalFormFields) &&
          noError
        );
      }
    }

    function validateForm() {
      setValidForm(checkValidBasicForm());
    }
    validateForm();
  }, [
    merchantForm,
    merchantUpdateForm,
    merchantErrorForm,
    merchantUpdateErrorForm,
    optionalFormFields,
    props.isUpdate,
  ]);

  function fillForm(e, name, type, refinedName, required) {
    let value = e.target.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);

    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      handleValidInput(name, value);
    } else {
      handleInvalidInput(isEmpty, refinedName, name);
    }
  }

  function handleValidInput(name, value) {
    if (props.isUpdate) {
      setMerchantUpdateForm({ ...merchantUpdateForm, [name]: value });
      setMerchantUpdateErrorForm({
        ...merchantUpdateErrorForm,
        [name]: null,
      });
    } else {
      setMerchantForm({ ...merchantForm, [name]: value });
      setMerchantErrorForm({ ...merchantErrorForm, [name]: null });
    }
  }

  function handleInvalidInput(isEmpty, refinedName, name) {
    if (isEmpty && optionalFormFields.includes(name)) {
      setMerchantUpdateErrorForm({ ...merchantUpdateErrorForm, [name]: null });
      setMerchantErrorForm({ ...merchantErrorForm, [name]: null });
    } else {
      let errorMessage = `${refinedName} is invalid`;
      if (props.isUpdate) {
        setMerchantUpdateErrorForm({
          ...merchantUpdateErrorForm,
          [name]: errorMessage,
        });
      } else {
        setMerchantErrorForm({ ...merchantErrorForm, [name]: errorMessage });
      }
    }
  }

  function navigate(index) {
    // if (checkValidBasicForm()) {
    setCurrentIndex(index);
    // }
  }

  function close(e) {
    props.closeModal(e);
    // mainContext.mainDispatch( {type:'SHOW_DIALOG',showDialog:false});
  }

  const currentWizardName = () => {
    if (props.isSearch) {
      return "";
    } else {
      switch (currentIndex) {
        case 0:
          return "Basic information";
        case 1:
          return "Settlement information";
        case 2:
          return "Settlement participant";
        default:
          return "";
      }
    }
  };

  const buttonView = () => {
    if (props.isUpdate) {
      return (
        <button
          disabled={!validForm}
          onClick={updateMerchant}
          className="primary-button"
        >
          {isSettlementConfigured ? "Next" : "Update"}
        </button>
      );
    } else {
      return (
        <button
          disabled={!validForm}
          onClick={isSettlementConfigured ? () => navigate(1) : createMerchant}
          className="primary-button"
        >
          {isSettlementConfigured ? "Next" : "Submit"}
        </button>
      );
    }
  };

  const basicInfo = () => {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <div style={{ display: props.isUpdate ? "none" : "block" }}>
            <FormInput
              value={merchantForm["merchantName"]}
              required={true}
              field="merchantName"
              type="NAME"
              error={merchantErrorForm["merchantName"]}
              fn={fillForm}
              loading={loading}
              placeholder="Name"
            />
          </div>
          <div style={{ display: props.isUpdate ? "block" : "none" }}>
            <FormInput
              value={merchantUpdateForm["merchantName"]}
              required={true}
              field="merchantName"
              type="NAME"
              error={merchantUpdateErrorForm["merchantName"]}
              fn={fillForm}
              loading={loading}
              placeholder="Name"
            />
          </div>
        </div>
        <div className="p-col-6">
          <div style={{ display: props.isUpdate ? "none" : "block" }}>
            <FormInput
              value={merchantForm["mainEmail"]}
              required={true}
              field="mainEmail"
              type="EMAIL"
              error={merchantErrorForm["mainEmail"]}
              fn={fillForm}
              loading={loading}
              placeholder="Main email"
            />
          </div>
          <div style={{ display: props.isUpdate ? "block" : "none" }}>
            <FormInput
              value={merchantUpdateForm["mainEmail"]}
              required={true}
              field="mainEmail"
              type="EMAIL"
              error={merchantUpdateErrorForm["mainEmail"]}
              fn={fillForm}
              loading={loading}
              placeholder="Main email"
            />
          </div>
        </div>
        <div className="p-col-6">
          <div style={{ display: props.isUpdate ? "none" : "block" }}>
            <FormInput
              value={merchantForm["supportEmail"]}
              required={false}
              field="supportEmail"
              type="EMAIL"
              error={merchantErrorForm["supportEmail"]}
              fn={fillForm}
              loading={loading}
              placeholder="Support email"
            />
          </div>
          <div style={{ display: props.isUpdate ? "block" : "none" }}>
            <FormInput
              value={merchantUpdateForm["supportEmail"]}
              required={false}
              field="supportEmail"
              type="EMAIL"
              error={merchantUpdateErrorForm["supportEmail"]}
              fn={fillForm}
              loading={loading}
              placeholder="Support email"
            />
          </div>
        </div>
        <div className="p-col-6">
          <div style={{ display: props.isUpdate ? "none" : "block" }}>
            <FormInput
              value={merchantForm["settlementEmail"]}
              required={false}
              field="settlementEmail"
              type="EMAIL"
              error={merchantErrorForm["settlementEmail"]}
              fn={fillForm}
              loading={loading}
              placeholder="Settlement email"
            />
          </div>
          <div style={{ display: props.isUpdate ? "block" : "none" }}>
            <FormInput
              value={merchantUpdateForm["settlementEmail"]}
              required={false}
              field="settlementEmail"
              type="EMAIL"
              error={merchantUpdateErrorForm["settlementEmail"]}
              fn={fillForm}
              loading={loading}
              placeholder="Settlement email"
            />
          </div>
        </div>
        <div className="p-col-6">
          <div style={{ display: props.isUpdate ? "none" : "block" }}>
            <FormInput
              value={merchantForm["disputeEmail"]}
              required={false}
              field="disputeEmail"
              type="EMAIL"
              error={merchantErrorForm["disputeEmail"]}
              fn={fillForm}
              loading={loading}
              placeholder="Dispute email"
            />
          </div>
          <div style={{ display: props.isUpdate ? "block" : "none" }}>
            <FormInput
              value={merchantUpdateForm["disputeEmail"]}
              required={false}
              field="disputeEmail"
              type="EMAIL"
              error={merchantUpdateErrorForm["disputeEmail"]}
              fn={fillForm}
              loading={loading}
              placeholder="Dispute email"
            />
          </div>
        </div>
        <div
          style={{ display: props.isUpdate ? "none" : "block" }}
          className="p-col-6"
        >
          <FormInput
            value={merchantForm["adminEmail"]}
            required={true}
            field="adminEmail"
            type="EMAIL"
            error={merchantErrorForm["adminEmail"]}
            fn={fillForm}
            loading={loading}
            placeholder="Admin email"
          />
        </div>
        <div
          style={{ display: props.isUpdate ? "none" : "block" }}
          className="p-col-6"
        >
          <FormInput
            value={merchantForm["cardAcceptorId"]}
            required={true}
            field="cardAcceptorId"
            type="CARD_ACCEPTANCE_ID"
            error={merchantErrorForm["cardAcceptorId"]}
            fn={fillForm}
            loading={loading}
            placeholder="Card acceptor id"
          />
        </div>
        <div className={props.isUpdate ? "p-col-12" : "p-col-6"}>
          <div style={{ display: props.isUpdate ? "none" : "block" }}>
            <FormInput
              value={merchantForm["phoneNumber"]}
              required={true}
              field="phoneNumber"
              type="MOBILE_NUMBER"
              error={merchantErrorForm["phoneNumber"]}
              fn={fillForm}
              loading={loading}
              placeholder="Phone number"
            />
          </div>
          <div style={{ display: props.isUpdate ? "block" : "none" }}>
            <FormInput
              value={merchantUpdateForm["phoneNumber"]}
              required={true}
              field="phoneNumber"
              type="MOBILE_NUMBER"
              error={merchantUpdateErrorForm["phoneNumber"]}
              fn={fillForm}
              loading={loading}
              placeholder="Phone number"
            />
          </div>
        </div>
        <div
          style={{ display: props.isUpdate ? "none" : "block" }}
          className="p-col-6"
        >
          <FormInput
            value={merchantForm["alternatePhoneNumber"]}
            required={false}
            field="alternatePhoneNumber"
            type="MOBILE_NUMBER"
            error={merchantErrorForm["alternatePhoneNumber"]}
            fn={fillForm}
            loading={loading}
            placeholder="Alternate phone number"
          />
        </div>
        <div
          style={{ display: props.isUpdate ? "none" : "block" }}
          className="p-col-12 p-mt-3 p-pb-0"
        >
          <label className="custom-dialog-subtitle">Admin Information</label>
        </div>
        <div className="p-col-6">
          <div style={{ display: props.isUpdate ? "none" : "block" }}>
            <FormInput
              value={merchantForm["adminFirstname"]}
              required={true}
              field="adminFirstname"
              type="NAME"
              error={merchantErrorForm["adminFirstname"]}
              fn={fillForm}
              loading={loading}
              placeholder="Admin first name"
            />
          </div>
          <div style={{ display: props.isUpdate ? "none" : "none" }}>
            <label>Admin First Name</label>
            <FormInput
              value={merchantUpdateForm["adminFirstname"]}
              required={true}
              field="adminFirstname"
              type="NAME"
              error={merchantUpdateErrorForm["adminFirstname"]}
              fn={fillForm}
              loading={loading}
              placeholder="Admin first name"
            />
          </div>
        </div>
        <div className="p-col-6">
          <div style={{ display: props.isUpdate ? "none" : "block" }}>
            <FormInput
              value={merchantForm["adminLastname"]}
              required={true}
              field="adminLastname"
              type="NAME"
              error={merchantErrorForm["adminLastname"]}
              fn={fillForm}
              loading={loading}
              placeholder="Admin last name"
            />
          </div>
          <div style={{ display: props.isUpdate ? "none" : "none" }}>
            <label>Admin Last Name</label>
            <FormInput
              value={merchantUpdateForm["adminLastname"]}
              required={true}
              field="adminLastname"
              type="NAME"
              error={merchantUpdateErrorForm["adminLastname"]}
              fn={fillForm}
              loading={loading}
              placeholder="Admin last name"
            />
          </div>
        </div>
        <div className="p-col-12 p-mt-1 flex default">
          <p className="text-small p-my-0 p-ml-1">Configure settlement?</p>
          <span className="p-px-4">
            <InputSwitch
              checked={isSettlementConfigured}
              onChange={() =>
                setIsSettlementConfigured(!isSettlementConfigured)
              }
            />
          </span>
        </div>
        <div className="p-col-6">
          <div className="p-mt-5">
            {!loading ? (
              <div>
                <button onClick={props.closeModal} className="secondary-button">
                  Close
                </button>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>

        <div className={loading ? "p-col-12" : "p-col-6"}>
          <div className="p-mt-5">
            {!loading ? (
              <div>{buttonView()}</div>
            ) : (
              <div>
                <CustomLoader loadingText="submitting..." />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const merchantWizard = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return basicInfo();
      case 1:
        return (
          <ConfigureSettlement
            offTitle={offTitle}
            merchantId={props?.editMerchant?.id}
            isUpdate={props.isUpdate}
            callAlert={callAlert}
            closeModal={close}
            basicInfo={merchantForm}
            fn={navigate}
            merchantSettlementData={props.merchantSettlementData}
          />
        );
      case 2:
        return (
          <div>
            <CustomMessage
              close={true}
              closeModal={props.closeModal}
              messageType="success"
              message={message}
            />
          </div>
        );
    }
  };

  function offTitle() {
    setHideTitle(true);
  }

  return (
    <div className="custom-form-container super-agents">
      <div>
        <div
          style={{ display: hideTitle ? "none" : "block" }}
          className="custom-modal-title p-text-left"
        >
          {props.isUpdate
            ? "Update Super Agent"
            : props.isSearch
            ? "Filter"
            : "Create Super Agent"}
        </div>
        <div
          style={{ display: hideTitle ? "none" : "block" }}
          className="custom-dialog-subtitle-container"
        >
          <p className="custom-dialog-subtitle">{currentWizardName()}</p>
          <p className="custom-dialog-subtitle">* Required Fields</p>
        </div>
        <div className="login-alert-container">
          {messageTitle ? (
            <div className="merchant-toast">
              <CustomToast
                title={messageTitle}
                description={message}
                type={alertType}
                clearToast={() => setMessageTitle(null)}
              />
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className="p-mt-2">{merchantWizard()}</div>
      </div>
    </div>
  );
}
