import { BACK_OFFICE_API } from "api/routes/back-office/index";
import { API_SERVICE } from "api/service";
import { FormDropdown } from "components/form/form-dropdown";
import { FormInput } from "components/form/form-input";
import { useSingleAppConfigurationValue } from "hooks/use-single-configuration-value";
import { useEffect, useRef, useState } from "react";
import { BACKOFFICE_CONFIGS } from "utils/constants";
import { HELPER } from "utils/helper";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { COMMON_API } from "api/routes/common/index";
import { CUSTOM_VALIDATION } from "utils/validation";
import { CustomToast } from "components/elements/alert/custom-toast";

export function AccountSetup(props) {
  const toast = useRef(null);
  const accountTypes = ["GL", "NUBAN"];
  const GL = "GL";
  const NUBAN = "NUBAN";
  const [error, setError] = useState({
    message: "",
    title: "",
    type: "error",
  });
  // const isNameEnquiryEnabled = useSingleAppConfigurationValue(
  //   BACKOFFICE_CONFIGS.IS_NAME_ENQUIRY_ENABLED
  // );
  const shouldPerformGlNameEnquiry = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.GL_NAME_ENQUIRY_OPTION
  );
  const glAccountSize = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.GL_ACCOUNT_NUMBER_SIZE
  );
  const [settlementTransitAccounts, setSettlementTransitAccounts] = useState(
    []
  );
  const [accountSetupInfo, setAccountSetupInfo] = useState({
    settlementAccountName: "",
    settlementAccountNumber: "",
    transitAccountName: "",
    transitAccountNumber: "",
    settlementAccountType: "",
    transitAccount: "",
  });
  const [accountSetupInfoError, setAccountSetupInfoError] = useState({
    settlementAccountName: null,
    settlementAccountNumber: null,
    transitAccountName: null,
    transitAccountNumber: null,
    settlementAccountType: null,
  });
  const [verifyingAccountNumber, setVerifyingAccountNumber] = useState(false);
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    function prefillForm() {
      if (props?.isUpdate) {
        const { transitAccountName, transitAccountNumber, ...rest } =
          props.selectedRule;
        setAccountSetupInfo({
          ...rest,
          transitAccount: {
            accountName: transitAccountName,
            accountNumber: transitAccountNumber,
          },
        });
      }
    }

    function getSettlementTransitAccounts() {
      const url = BACK_OFFICE_API.TRANSIT_ACCOUNT.SETTLEMENT_TRANSIT_ACCOUNTS;
      API_SERVICE.MAKE_GET_REQUEST(url)
        .then((data) => {
          const formattedData = data?.result.map((item) => {
            const { createdAt, accountReference, active, ...rest } = item;
            return rest;
          });
          setSettlementTransitAccounts(formattedData);
          prefillForm();
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }

    getSettlementTransitAccounts();
  }, []);

  function validateDropdown(e, name) {
    const value = e.target.value;
    if (value) {
      setAccountSetupInfoError({ ...accountSetupInfoError, [name]: null });
      setAccountSetupInfo({ ...accountSetupInfo, [name]: value });
      if (props.isUpdate) {
        if (
          value === NUBAN ||
          (shouldPerformGlNameEnquiry === "true" && value === GL)
        ) {
          setError({
            message: "",
            title: "",
            type: "error",
          });
          verifyAccountNumber(accountSetupInfo.settlementAccountNumber, value);
        }
      }
    }
  }

  function verifyAccountNumber(accNo, accountType) {
    setVerifyingAccountNumber(true);
    setAccountSetupInfoError({
      ...accountSetupInfoError,
      settlementAccountNumber: null,
      settlementAccountName: null,
    });
    const url = COMMON_API.ACCOUNT_ENQUIRY.VERIFY_ACCOUNT_NUMBER;
    API_SERVICE.MAKE_GET_REQUEST(`${url}/${accNo}`)
      .then((data) => {
        const accountName = data?.result?.accountName;
        setAccountSetupInfo({
          ...accountSetupInfo,
          settlementAccountNumber: accNo,
          settlementAccountName: accountName,
          settlementAccountType: accountType,
        });
        setVerifyingAccountNumber(false);
      })
      .catch((error) => {
        setAccountSetupInfoError({
          ...accountSetupInfoError,
          settlementAccountName: HELPER.PROCESS_ERROR(error, "NAME_ENQUIRY"),
        });
        setVerifyingAccountNumber(false);
      });
  }

  function fillForm(e, name, type, field, required) {
    const value = e.target.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type, glAccountSize)
      : false;
    if (isValidInput) {
      if (props.isUpdate) {
        if (name === "settlementAccountNumber") {
          if (
            !(
              value !== props.payload.settlementAccountNumber &&
              accountSetupInfo.settlementAccountType === ""
            )
          ) {
            setError({
              message: "Select a settlement account type",
              title: "Validation Error",
              type: "error",
            });
            setAccountSetupInfo((prev) => ({
              ...prev,
              [name]: value,
              settlementAccountName: "",
            }));
          }
        } else {
          setAccountSetupInfo({ ...accountSetupInfo, [name]: value });
          setAccountSetupInfoError({
            ...accountSetupInfoError,
            [name]: null,
          });
        }
      } else {
        if (
          name === "settlementAccountNumber" &&
          (accountSetupInfo.settlementAccountType === NUBAN ||
            (shouldPerformGlNameEnquiry === "true" &&
              accountSetupInfo.settlementAccountType === GL))
        ) {
          verifyAccountNumber(value, accountSetupInfo.settlementAccountType);
        } else {
          setAccountSetupInfo({ ...accountSetupInfo, [name]: value });
          setAccountSetupInfoError({
            ...accountSetupInfoError,
            [name]: null,
          });
        }
      }
    } else {
      if (props.isUpdate) {
        setAccountSetupInfo((prev) => ({ ...prev, [name]: value }));
      }

      let errorMessage = required && isEmpty ? `${field} is required` : null;
      if (!isValidInput) {
        errorMessage = `${field} is invalid`;
      }
      setAccountSetupInfoError({
        ...accountSetupInfoError,
        [name]: errorMessage,
      });
    }
  }

  function fillTransitAccount(e) {
    setAccountSetupInfo({
      ...accountSetupInfo,
      transitAccountName: e.value?.accountName ? e.value?.accountName : "",
      transitAccountNumber: e.value?.accountNumber
        ? e.value?.accountNumber
        : "",
      transitAccount: e.value ? e.value : "",
    });
  }

  function validateForm() {
    const validForm = Object.values(accountSetupInfo)
      .map((item) => item === "")
      .includes(true);
    return validForm;
  }
  function validateErrors() {
    return Object.values(accountSetupInfoError)
      .map((item) => item === null)
      .includes(true);
  }

  useEffect(() => {
    setValidForm(validateForm());
  }, [accountSetupInfoError, accountSetupInfo]);

  function handleNextAction() {
    if (
      !validateForm() &&
      validateErrors() &&
      !CUSTOM_VALIDATION.VALID_OBJ_ANY(accountSetupInfoError)
    ) {
      const { transitAccount, ...payload } = accountSetupInfo;
      const settlementSetupIndex = 2;
      props.action(payload, settlementSetupIndex);
    }
  }

  function handleBackAction() {
    if (props?.isUpdate) {
      props?.close();
    } else {
      const ruleSetupIndex = 0;
      props.action("", ruleSetupIndex);
    }
  }

  return (
    <div className="p-grid">
      <div>
        <Toast ref={toast} />
      </div>
      <div className="p-col-12">
        <div>
          {props.isUpdate && <label>Transit Account *: </label>}
          <Dropdown
            value={accountSetupInfo["transitAccount"]}
            onChange={fillTransitAccount}
            options={settlementTransitAccounts}
            optionLabel="accountName"
            showClear
            placeholder="Select transit account *"
            className="w-full md:w-14rem"
          />
        </div>
        <div className="p-mt-3">
          {props.isUpdate && <label>Settlement Account Type *:</label>}
          <FormDropdown
            required={true}
            field="settlementAccountType"
            error={accountSetupInfoError["settlementAccountType"]}
            value={accountSetupInfo["settlementAccountType"]}
            fn={validateDropdown}
            options={accountTypes}
            placeholder={"Settlement Account Type *"}
          />
        </div>
        <div className="p-mt-3">
          <FormInput
            verifyingField="settlement account no"
            verifying={verifyingAccountNumber}
            verified={""}
            required={true}
            field="settlementAccountNumber"
            type={NUBAN}
            fn={fillForm}
            value={accountSetupInfo["settlementAccountNumber"]}
            error={accountSetupInfoError["settlementAccountNumber"]}
            placeholder="Settlment Account Number"
          />
        </div>
        <div className="p-mt-3">
          <FormInput
            value={accountSetupInfo["settlementAccountName"]}
            required={true}
            disabled={
              accountSetupInfo.settlementAccountType === NUBAN ||
              (shouldPerformGlNameEnquiry === "true" &&
                !accountSetupInfoError["settlementAccountName"])
            }
            field="settlementAccountName"
            type="NAME"
            error={accountSetupInfoError["settlementAccountName"]}
            fn={() => {}}
            loading={verifyingAccountNumber}
            placeholder={
              verifyingAccountNumber ? "Verifying" : "Settlment Account Name"
            }
          />
        </div>
      </div>
      <div className="login-alert-container p-col-12">
        {error.title ? (
          <div className="merchant-toast">
            <CustomToast
              title={error.title}
              description={error.message}
              type={error.type}
              clearToast={() =>
                setError({ title: null, message: null, type: null })
              }
            />
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className="p-col-12 p-mt-2 d-flex p-jc-between ">
        <button
          onClick={handleBackAction}
          className={` ${
            props?.isUpdate ? "secondary-button" : "primary-button"
          }`}
        >
          {props?.isUpdate ? "Close" : "Back"}
        </button>
        <button
          disabled={validForm}
          onClick={handleNextAction}
          className="primary-button p-ml-2"
        >
          Next
        </button>
      </div>
    </div>
  );
}
