import { useContext, useRef, useState } from "react";
import { FormInput } from "components/form/form-input";
import { CUSTOM_VALIDATION } from "utils/validation";
import moment from "moment";
import { Toast } from "primereact/toast";
import { FormDropdown } from "components/form/form-dropdown";
import { API_SERVICE } from "api/service";
import { HELPER } from "utils/helper";
import { saveAs } from "file-saver";
import { CustomToast } from "components/elements/alert/custom-toast";
import { Optional } from "components/elements/optional/optional";
import { DownloadProgress } from "components/elements/download-progress/download-progress";
import { MainContext } from "App";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function BulkTransactionsFilter(props) {
  const mainContext = useContext(MainContext);
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [currentIndex] = useState(0);
  const [downloadError, setDownloadError] = useState(null);
  const [transaction, setTransaction] = useState({
    endDate: "",
    maskedPan: null,
    rrn: null,
    startDate: "",
    disputeCreationStatus: "",
    logCode: "",
  });
  const [transactionError, setTransactionError] = useState({
    endDate: null,
    maskedPan: null,
    rrn: null,
    startDate: null,
    disputeCreationStatus: null,
    logCode: null,
  });

  const disputeCreationStatuses = [
    { name: "ALL", value: "ALL" },
    { name: "SUCCESSFUL", value: "SUCCESSFUL" },
    { name: "PENDING", value: "PENDING" },
    { name: "FAILED", value: "FAILED" },
  ];

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setTransaction({ ...transaction, [name]: value });
      setTransactionError({ ...transactionError, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setTransactionError({ ...transaction, [name]: errorMessage });
    }
  }

  function downloadTransactions() {
    const payload = getFilters();
    const currentDate = Date.now();
    setLoading(true);
    const isBlob = true;
    const url = BACK_OFFICE_API.BULK_DISPUTE.DOWNLOAD_BULK_DISPUTE_TRANSACTIONS;
    API_SERVICE.MAKE_PUT_REQUEST(
      `${url}/${props.batchId}`,
      payload,
      isBlob,
      mainContext
    )
      .then((data) => {
        saveAs(
          data,
          `${props.batchId}-${moment(currentDate).format("YYYY-MM-DD")}.xlsx`
        );
        props.closeModal();
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function handleError(error) {
    const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
    setDownloadError(errMessage);
    setLoading(false);
  }

  function getFilters() {
    let payload = {};
    for (const property in transaction) {
      if (transaction[property]) {
        payload[property] = transaction[property];
      }
    }
    return payload;
  }

  function cancelModal() {
    props.closeModal();
  }

  function validateDropdown(e, name) {
    const value = e.target.value;
    if (value) {
      setTransactionError({ ...transactionError, [name]: "" });
      setTransaction({ ...transaction, [name]: value });
    } else {
      let errorMessage = "Select type";
      setTransactionError({ ...transactionError, [name]: errorMessage });
    }
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={cancelModal} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  const downloadButton = () => {
    if (!loading) {
      return (
        <button
          onClick={downloadTransactions}
          className="primary-button download-button"
        >
          Download
        </button>
      );
    } else {
      return <div />;
    }
  };

  const submitButton = () => {
    if (!loading) {
      return (
        <button
          onClick={() => props?.searchFunction(getFilters())}
          className="primary-button"
        >
          Filter
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2">
          <DownloadProgress />
        </div>
      );
    }
  };

  const searchTransactionsView = () => {
    if (currentIndex) {
      return (
        <div>
          <div className="success-message-btn-container">
            <button
              onClick={() => {
                props.closeModal(true);
              }}
              className="primary-button success-message-btn"
            >
              Close
            </button>
          </div>
        </div>
      );
    } else {
      return <div>{searchTransactionsFormView()}</div>;
    }
  };

  const searchTransactionsFormView = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">Filter</div>
        <div className="custom-dialog-subtitle-container p-mb-5"></div>
        <div className="p-pb-1">
          <Optional show={downloadError}>
            <CustomToast
              title="Error"
              description={downloadError}
              type="error"
              clearToast={() => setDownloadError(null)}
            />
          </Optional>
        </div>
        <div className="p-grid">
          <div className="p-col-12">
            <FormDropdown
              required={true}
              label="name"
              field="disputeCreationStatus"
              error={transactionError["disputeCreationStatus"]}
              disabled={loading}
              value={transaction["disputeCreationStatus"]}
              fn={validateDropdown}
              options={disputeCreationStatuses}
              placeholder="Select dispute creation status"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={transaction["logCode"]}
              required={false}
              field="logCode"
              type="INPUT"
              error={transactionError["logCode"]}
              fn={validateForm}
              loading={loading}
              placeholder="Log code"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={transaction["maskedPan"]}
              required={false}
              field="maskedPan"
              type="INPUT"
              error={transactionError["maskedPan"]}
              fn={validateForm}
              loading={loading}
              placeholder="Masked Pan"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={transaction["rrn"]}
              required={false}
              field="rrn"
              type="INPUT"
              error={transactionError["rrn"]}
              fn={validateForm}
              loading={loading}
              placeholder="Rrn"
            />
          </div>
          <div className="p-col-12 p-pb-0">
            <label>Transaction Date</label>
          </div>
          <div className="p-col-6">
            <FormInput
              inputType="date"
              value={transaction["startDate"]}
              required={false}
              field="startDate"
              type="INPUT"
              error={transactionError["startDate"]}
              fn={validateForm}
              loading={loading}
              placeholder="Start date"
            />
          </div>
          <div className="p-col-6">
            <FormInput
              inputType="date"
              value={transaction["endDate"]}
              required={false}
              field="endDate"
              type="INPUT"
              error={transactionError["endDate"]}
              fn={validateForm}
              loading={loading}
              placeholder="End date"
            />
          </div>
          <div className="p-col-12">
            <div className="p-mt-5">
              <div className="p-grid">
                <div className={loading ? "p-col-12" : "p-col-4"}>
                  {cancelButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-4"}>
                  {downloadButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-4"}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="p-pb-2">{searchTransactionsView()}</div>
    </div>
  );
}
