import "./dispute.css";
import { useContext, useEffect, useRef, useState } from "react";
import { CUSTOM_VALIDATION } from "utils/validation";
import { FormDropdown } from "components/form/form-dropdown";
import { FormInput } from "components/form/form-input";
import { saveAs } from "file-saver";
import moment from "moment";
import { API_SERVICE } from "api/service";
import { CustomToast } from "components/elements/alert/custom-toast";
import { HELPER } from "utils/helper";
import { Toast } from "primereact/toast";
import { MainContext } from "App";
import { FormMultiselect } from "components/form/form-multiselect";
import { Optional } from "components/elements/optional/optional";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { DisputeTypes } from "utils/constants";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";

export function DisputesFilter(props) {
  const mainContext = useContext(MainContext);
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [errorTitle] = useState("Error");
  const [errorMessage, setErrorMessage] = useState(null);
  const [dispute, setDispute] = useState({
    resolutionStatus: null,
    status: null,
    logCode: "",
    terminalId: "",
    rrn: "",
    responseCode: "",
    maskedPan: "",
    merchantId: "",
    transactionDateEnd: null,
    transactionDateStart: null,
    createdDateTo: moment().format("YYYY-MM-DD"),
    createdDateFrom: moment().format("YYYY-MM-DD"),
    tatExpiryStartDate: null,
    tatExpiryEndDate: null,
  });
  const [formErrors, setFormErrors] = useState({
    resolutionStatus: null,
    status: null,
    logCode: null,
    terminalId: null,
    rrn: null,
    merchantId: null,
    responseCode: null,
    maskedPan: null,
    transactionDateEnd: null,
    transactionDateStart: null,
    createdDateFrom: null,
    createdDateTo: null,
    tatExpiryStartDate: null,
    tatExpiryEndDate: null,
  });
  const resolutionStatuses = ["PENDING", "ACCEPTED", "DECLINED"];
  const [disputeStatuses, setDisputeStatuses] = useState([
    "PENDING",
    "RESOLVED",
    "SYSTEM_ACCEPTED",
  ]);
  const [disputeDateFilterType, setDisputeDateFilterType] =
    useState("disputeLogDate");
  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    function handleError(error) {
      toast.current.show({
        severity: "error",
        summary: "Error!",
        detail: HELPER.PROCESS_ERROR(error, "TOAST"),
        life: 10000,
      });
    }

    function getAllSuperAgents() {
      const url = BACK_OFFICE_API.SUPER_AGENT.GET_ALL_SUPER_AGENTS;
      API_SERVICE.MAKE_GET_REQUEST(url)
        .then((data) => {
          const result = data?.result;
          setMerchants(result);
        })
        .catch((error) => {
          handleError(error);
        });
    }
    function getSuperAgentsTiedToUser() {
      const url = BACK_OFFICE_API.SUPER_AGENT.GET_SUPER_AGENTS_TIED_TO_USER;
      API_SERVICE.MAKE_GET_REQUEST(url)
        .then((data) => {
          const result = data?.result;
          if (result?.length > 0 && result?.length === 1) {
            setDispute((dispute) => {
              return { ...dispute, merchantId: result[0] };
            });
          }
          setMerchants(result);
        })
        .catch((error) => {
          handleError(error);
        });
    }
    if (
      props.disputeType === DisputeTypes.AgentDisputes ||
      props.disputeType === DisputeTypes.BankDisputes
    ) {
      getSuperAgentsTiedToUser();
    } else {
      getAllSuperAgents();
    }
  }, [props.disputeType]);

  useEffect(() => {
    if (props.disputeType === DisputeTypes.BackofficeFailedResponseDisputes) {
      const statuses = ["PENDING", "SYSTEM_ACCEPTED"];
      setDisputeStatuses(statuses);
    }
  }, [props?.disputeType]);

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setDispute({ ...dispute, [name]: value });
      setFormErrors({ ...formErrors, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setDispute({ ...dispute, [name]: null });
      setFormErrors({ ...formErrors, [name]: errorMessage });
    }
  }

  function validateAndDownload() {
    setErrorMessage(null);
    setLoading(true);
    let payload = getFilters();
    let url;

    let frontOfficeDownloadSuperAgentDisputesUrl =
      FRONT_OFFICE_API.DISPUTE.DOWNLOAD_DISPUTES_BY_SUPER_AGENT;
    let frontofficeBackofficeDisputesUrl =
      FRONT_OFFICE_API.DISPUTE.DOWNLOAD_DISPUTES_BACK_OFFICE;
    let backofficeSuperAgentDisputesUrl =
      BACK_OFFICE_API.DISPUTE.DOWNLOAD_SUPER_AGENT_DISPUTES;
    let backofficeBackofficeDisputesUrl =
      BACK_OFFICE_API.DISPUTE.DOWNLOAD_BACK_OFFICE_DISPUTES;

    if (props.disputeType === DisputeTypes.AgentDisputes) {
      url = frontOfficeDownloadSuperAgentDisputesUrl;
    } else if (props.disputeType === DisputeTypes.BankDisputes) {
      url = frontofficeBackofficeDisputesUrl;
    } else if (props.disputeType === DisputeTypes.BackofficeSuperAgent) {
      url = backofficeSuperAgentDisputesUrl;
    } else {
      url = backofficeBackofficeDisputesUrl;
    }
    const isBlob = true;
    API_SERVICE.MAKE_PUT_REQUEST(url, payload, isBlob, mainContext)
      .then((data) => {
        props.closeModal();
        saveAs(
          data,
          `${dispute["createdDateFrom"]}To${dispute["createdDateTo"]}-Disputes.xlsx`
        );
        setLoading(false);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function handleError(error) {
    const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
    setErrorMessage(errMessage);
    setLoading(false);
  }

  function getFilters() {
    let payload = {};
    for (const property in dispute) {
      let value = dispute[property];
      if (dispute[property]) {
        if (property === "merchantId") {
          payload[property] = value.merchantId;
        } else if (
          property === "status" &&
          props.disputeType === DisputeTypes.Backoffice
        ) {
          payload[property] = dispute[property].length
            ? dispute[property].join(",")
            : "";
        } else {
          if (
            props.disputeType !== DisputeTypes.AgentDisputes ||
            props.disputeType !== DisputeTypes.BankDisputes
          ) {
            if (mainContext.mainState.merchantId !== "null")
              payload["merchantId"] = mainContext.mainState.merchantId;
          }
          payload[property] = dispute[property];
        }
      }
    }
    // For failed response disputes, set default filter values
    if (props.disputeType === DisputeTypes.BackofficeFailedResponseDisputes) {
      payload["responseCode"] = props.failedResponseDisputeCodes;
      payload["status"] = "PENDING,SYSTEM_ACCEPTED";
      payload["resolutionStatus"] = "PENDING";
    }

    return payload;
  }

  function cancelModal() {
    props.closeModal();
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={cancelModal} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  function validateDropdown(e, name) {
    const value = e.target.value;
    if (value) {
      setFormErrors({ ...formErrors, [name]: "" });
      setDispute({ ...dispute, [name]: value });
    }
  }

  function validateMultipleSelect(e, name) {
    let value = e.target.value;
    if (value.length) {
      setDispute({ ...dispute, [name]: value });
    }
  }

  function handleDisputeDateFilterTypeChange(e) {
    const type = e?.target?.value;
    setDisputeDateFilterType(e?.target?.value);
    if (type === "transactionDate") {
      setDispute({
        ...dispute,
        createdDateFrom: null,
        createdDateTo: null,
        dueDate: null,
        transactionDateEnd: moment().format("YYYY-MM-DD"),
        transactionDateStart: moment().format("YYYY-MM-DD"),
        tatExpiryStartDate: null,
        tatExpiryEndDate: null,
      });
    } else if (type === "dueDate") {
      setDispute({
        ...dispute,
        createdDateFrom: null,
        createdDateTo: null,
        transactionDateEnd: null,
        transactionDateStart: null,
        tatExpiryStartDate: moment().format("YYYY-MM-DD"),
        tatExpiryEndDate: moment().format("YYYY-MM-DD"),
      });
    } else {
      setDispute({
        ...dispute,
        transactionDateEnd: null,
        transactionDateStart: null,
        dueDate: null,
        createdDateFrom: moment().format("YYYY-MM-DD"),
        createdDateTo: moment().format("YYYY-MM-DD"),
        tatExpiryStartDate: null,
        tatExpiryEndDate: null,
      });
    }
  }

  const downloadButton = () => {
    if (!loading) {
      return (
        <button
          onClick={validateAndDownload}
          className="primary-button download-button"
        >
          Download
        </button>
      );
    }
  };

  const submitButton = () => {
    if (!loading) {
      return (
        <button
          onClick={() => props?.searchFunction(getFilters())}
          className="primary-button"
        >
          Filter
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2">
          {/* <DownloadProgress /> */}
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    }
  };

  const viewAlert = () => {
    if (errorMessage) {
      return (
        <div>
          <CustomToast
            title={errorTitle}
            description={errorMessage}
            type="error"
            clearToast={() => setErrorMessage(null)}
          />
        </div>
      );
    } else {
      return <div />;
    }
  };

  const filterDateTypeForm = () => {
    switch (disputeDateFilterType) {
      case "disputeLogDate":
        return (
          <>
            <div className="p-col-12 p-pb-0">
              <p
                style={{ textAlign: "left", fontSize: "0.8rem" }}
                className="p-my-0"
              >
                Dispute Log Date
              </p>
            </div>
            <div className="p-col-6">
              <FormInput
                inputType="date"
                value={dispute["createdDateFrom"]}
                required={false}
                field="createdDateFrom"
                type="INPUT"
                error={formErrors["createdDateFrom"]}
                fn={validateForm}
                loading={loading}
                placeholder="Start date"
              />
            </div>
            <div className="p-col-6">
              <FormInput
                inputType="date"
                value={dispute["createdDateTo"]}
                required={false}
                field="createdDateTo"
                type="INPUT"
                error={formErrors["createdDateTo"]}
                fn={validateForm}
                loading={loading}
                placeholder="End date"
              />
            </div>
          </>
        );
      case "transactionDate":
        return (
          <>
            <div className="p-col-12 p-pb-0">
              <p
                style={{ textAlign: "left", fontSize: "0.8rem" }}
                className="p-my-0"
              >
                Transaction Date
              </p>
            </div>
            <div className="p-col-6">
              <FormInput
                inputType="date"
                value={dispute["transactionDateStart"]}
                required={false}
                field="transactionDateStart"
                type="INPUT"
                error={formErrors["transactionDateStart"]}
                fn={validateForm}
                loading={loading}
                placeholder="Start date"
              />
            </div>
            <div className="p-col-6">
              <FormInput
                inputType="date"
                value={dispute["transactionDateEnd"]}
                required={false}
                field="transactionDateEnd"
                type="INPUT"
                error={formErrors["transactionDateEnd"]}
                fn={validateForm}
                loading={loading}
                placeholder="End date"
              />
            </div>
          </>
        );
      case "dueDate":
        return (
          <>
            <div className="p-col-12 p-pb-0">
              <p
                style={{ textAlign: "left", fontSize: "0.8rem" }}
                className="p-my-0"
              >
                Due Date
              </p>
            </div>
            <div className="p-col-6">
              <FormInput
                inputType="date"
                value={dispute["tatExpiryStartDate"]}
                required={false}
                field="tatExpiryStartDate"
                type="INPUT"
                error={formErrors["tatExpiryStartDate"]}
                fn={validateForm}
                loading={loading}
                placeholder="Start date"
              />
            </div>
            <div className="p-col-6">
              <FormInput
                inputType="date"
                value={dispute["tatExpiryEndDate"]}
                required={false}
                field="tatExpiryEndDate"
                type="INPUT"
                error={formErrors["tatExpiryEndDate"]}
                fn={validateForm}
                loading={loading}
                placeholder="End date"
              />
            </div>
          </>
        );
      default:
        return;
    }
  };

  function shouldShowMerchantDropdown() {
    let showDropdown = false;
    if (
      props.disputeType === DisputeTypes.AgentDisputes ||
      props.disputeType === DisputeTypes.BankDisputes
    ) {
      if (merchants?.length > 1) {
        showDropdown = true;
      }
    } else {
      showDropdown = true;
    }
    return showDropdown;
  }

  const renderForm = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">Filter</div>
        <div className="custom-dialog-subtitle-container p-mb-5"></div>
        <div className="p-grid">
          {shouldShowMerchantDropdown() && (
            <div className="p-col-12">
              <FormDropdown
                required={true}
                label="merchantName"
                field="merchantId"
                error={formErrors["merchantId"]}
                disabled={loading}
                value={dispute["merchantId"]}
                fn={validateDropdown}
                options={merchants}
                placeholder="Select agent"
                filter
                filterBy="merchantName"
              />
            </div>
          )}
          <Optional show={props.disputeType === DisputeTypes.Backoffice}>
            <div className="p-col-12">
              <FormMultiselect
                required={false}
                error={formErrors["status"]}
                field="status"
                value={dispute["status"]}
                options={disputeStatuses}
                fn={validateMultipleSelect}
                placeholder="Select dispute status"
              />
            </div>
          </Optional>
          <Optional
            show={
              props.disputeType !==
                DisputeTypes.BackofficeFailedResponseDisputes &&
              props.disputeType !== DisputeTypes.Backoffice
            }
          >
            <div className="p-col-12">
              <FormDropdown
                required={false}
                // label="code"
                field="status"
                error={formErrors["status"]}
                disabled={loading}
                value={dispute["status"]}
                fn={validateDropdown}
                options={disputeStatuses}
                placeholder="Select dispute status"
              />
            </div>
          </Optional>

          <Optional
            show={
              props.disputeType !==
              DisputeTypes.BackofficeFailedResponseDisputes
            }
          >
            <div className="p-col-12">
              <FormDropdown
                required={false}
                // label="code"
                field="resolutionStatus"
                error={formErrors["resolutionStatus"]}
                disabled={loading}
                value={dispute["resolutionStatus"]}
                fn={validateDropdown}
                options={resolutionStatuses}
                placeholder="Select resolution status"
              />
            </div>
          </Optional>

          <div className="p-col-12">
            <FormInput
              value={dispute["logCode"]}
              required={false}
              field="logCode"
              type="INPUT"
              error={formErrors["logCode"]}
              fn={validateForm}
              loading={loading}
              placeholder="Log code"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={dispute["terminalId"]}
              required={false}
              field="terminalId"
              type="INPUT"
              error={formErrors["terminalId"]}
              fn={validateForm}
              loading={loading}
              placeholder="Terminal id"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={dispute["rrn"]}
              required={false}
              field="rrn"
              type="INPUT"
              error={formErrors["rrn"]}
              fn={validateForm}
              loading={loading}
              placeholder="RRN"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={dispute["maskedPan"]}
              required={false}
              field="maskedPan"
              type="INPUT"
              error={formErrors["maskedPan"]}
              fn={validateForm}
              loading={loading}
              placeholder="Masked pan"
            />
          </div>
          <Optional
            show={
              props.disputeType !==
              DisputeTypes.BackofficeFailedResponseDisputes
            }
          >
            <div className="p-col-12">
              <FormInput
                value={dispute["responseCode"]}
                required={false}
                field="responseCode"
                type="INPUT"
                error={formErrors["responseCode"]}
                fn={validateForm}
                loading={loading}
                placeholder="Response code"
              />
            </div>
          </Optional>
          <div className="p-col-4">
            <label className="label">
              <input
                id="dispute-log-date"
                value="disputeLogDate"
                name="dateFilterType"
                type="radio"
                checked={disputeDateFilterType === "disputeLogDate"}
                onChange={handleDisputeDateFilterTypeChange}
              />
              <span>Dispute Log Date</span>
            </label>
          </div>
          <div className="p-col-4">
            <label className="label">
              <input
                id="transaction-date"
                value="transactionDate"
                name="dateFilterType"
                type="radio"
                checked={disputeDateFilterType === "transactionDate"}
                onChange={handleDisputeDateFilterTypeChange}
              />
              <span>Transaction Date</span>
            </label>
          </div>
          <div className="p-col-4">
            <label className="label">
              <input
                id="due-date"
                value="dueDate"
                name="dateFilterType"
                type="radio"
                checked={disputeDateFilterType === "dueDate"}
                onChange={handleDisputeDateFilterTypeChange}
              />
              <span>Due Date</span>
            </label>
          </div>

          {filterDateTypeForm()}
          <div className="p-col-12">
            <div className="p-pb-1">{viewAlert()}</div>
          </div>

          <div className="p-col-12">
            <div className="p-mt-2">
              <div className="p-grid">
                <div className={loading ? "p-col-12" : "p-col-4"}>
                  {cancelButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-4"}>
                  {downloadButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-4"}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="p-pb-2">{renderForm()}</div>
    </div>
  );
}
