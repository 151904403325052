import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { API_SERVICE } from "api/service";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { Toast } from "primereact/toast";
import { HELPER } from "utils/helper";
import { AccessDenied } from "components/elements/access-denied/access-denied";
import ErrorBoundary from "components/elements/error-boundary/error-boundary";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";
import { SettlementRuleConfigBreakdown } from "./settlement-rule-config-break-down";
import { SettlementRuleConfigForm } from "./settlement-rule-config-form";
import { CustomConfirmDialog } from "components/elements/custom-confirm-dialog/custom-confirm-dialog";

export function SettlementRuleConfigs() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isMobileTransaction, setIsMobileTransaction] = useState(false);
  const [range] = useState(5);
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [details, setDetails] = useState([]);
  const [configs, setConfigs] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [participants, setParticipants] = useState([]);
  const [rules, setRules] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedRule, setSelectedRuleKey] = useState({});
  const [itemIdForDeactivation, setItemIdForDeactivation] = useState("");
  const [ruleAction, setRuleAction] = useState("DEACTIVATE");
  const [confirmModalSuccess, setConfirmModalSuccess] = useState(null);
  const [confirmModalError, setConfirmModalError] = useState(null);
  const [confirmText, setConfirmText] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [modalLoading, setModalLoading] = useState(false);

  const tableHeaders = [
    { label: "Rule Key", value: "ruleKey" },
    { label: "Settlement Account Name", value: "settlementAccountName" },
    { label: "Settlement Account Number", value: "settlementAccountNumber" },
    { label: "Priority ", value: "priority" },
    { label: "Frequency", value: "frequency" },
    { label: "Status", value: "status" },
    { label: "Actions", value: "actions" },
  ];
  const authorities = [
    { label: "DEACTIVATE", value: "iss_update_settlement_rule" },
    { label: "OPEN", value: "all" },
    { label: "UPDATE", value: "iss_update_settlement_rule" },
  ];

  const openRuleDeactivationModal = useCallback(
    (e, isMobile) => {
      setConfirmModalError(null);
      setConfirmModalSuccess(null);
      setModalLoading(true);
      openModal(2, isMobile);
      setItemIdForDeactivation(e?.ruleKey);
      setConfirmText(`${e.ruleKey?.toString()?.toLowerCase()}`);
      setHeaderText(`config with key: ${e.ruleKey?.toString()?.toLowerCase()}`);
      setModalLoading(false);
    },
    [openModal]
  );

  function deactivateRule(e) {
    setModalLoading(true);
    setConfirmModalError(null);
    setConfirmModalSuccess(null);
    const payload = {
      isDisable: ruleAction === "DEACTIVATE" ? true : false,
    };
    const url = `${BACK_OFFICE_API.SETTLEMENT_RULE_CONFIGS.CONFIGS_ROUTE}/${e}`;
    API_SERVICE.MAKE_PATCH_REQUEST(url, payload)
      .then((data) => {
        setConfirmModalSuccess(
          `Configuration ${ruleAction.toLowerCase()}d successfully.`
        );
        setModalLoading(false);
      })
      .catch((error) => {
        setConfirmModalError(HELPER.PROCESS_ERROR(error));
        setModalLoading(false);
      });
  }

  const getFrormattedConfig = useCallback((e, isMobile) => {
    setDetails([]);
    const { participants, rules, disabled, detailsFunction, actions, ...rest } =
      e;
    setParticipants(participants);
    setRules(HELPER.FORMAT_OBJECT_TO_ARRAY(rules));
    setDetails(HELPER.FORMAT_OBJECT_TO_ARRAY(rest));
    setIsMobileTransaction(isMobile);
    setCurrentIndex(2);
  }, []);

  const openAction = useCallback(
    (e, action, isMobile) => {
      setRuleAction(e.actions);
      switch (action) {
        case "OPEN": {
          getFrormattedConfig(e, isMobile);
          break;
        }
        case "UPDATE": {
          const modalIndex = 1;
          setIsUpdate(true);
          setSelectedRuleKey(e);
          openModal(modalIndex, isMobile);
          break;
        }
        case "DEACTIVATE":
          openRuleDeactivationModal(e, isMobile);
          break;
        case "ACTIVATE":
          openRuleDeactivationModal(e, isMobile);
          break;
        default:
          break;
      }
    },
    [getFrormattedConfig]
  );

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  const getSettlementRuleConfigs = useCallback(
    (isReload) => {
      setCurrentIndex(0);
      setConfigs([]);
      if (isReload) {
        setPageNo(0);
      }
      let params = HELPER.TO_URL_STRING({
        page: isReload ? 0 : pageNo,
        size: 10,
        includeDeleted: false,
      });

      const url = BACK_OFFICE_API.SETTLEMENT_RULE_CONFIGS.CONFIGS_ROUTE;
      API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
        .then((data) => {
          const result = data?.result?.content;
          if (!result.length) {
            setEmptyText("No configurations found ...");
          } else {
            let arr = [];
            setTotalItems(data?.result.totalElements);
            setTotalPages(data?.result.totalPages);
            result.forEach((e) => {
              arr.push({
                ...e,
                status: e.disabled ? "Inactive" : "Active",
                detailsFunction: openAction,
                actions: e?.disabled ? "ACTIVATE" : "DEACTIVATE",
              });
            });
            setConfigs(arr);
          }
          setError(null);
          setCurrentIndex(1);
          setLoading(false);
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
          setCurrentIndex(1);
          setLoading(false);
        });
    },
    [pageNo, openAction]
  );

  useEffect(() => {
    getSettlementRuleConfigs();
  }, [getSettlementRuleConfigs]);

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(1);
    } else {
      setVisible(true);
    }
  }

  function reload(isReload) {
    getSettlementRuleConfigs(isReload);
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }
    if (isReload === true) {
      reload(isReload);
    }
  }

  function goBack() {
    setCurrentIndex(1);
  }

  const modalContent = () => {
    switch (currentModalIndex) {
      case 1:
        return (
          <SettlementRuleConfigForm
            closeModal={closeModal}
            isUpdate={isUpdate}
            selectedRule={selectedRule}
          />
        );
      case 2:
        return (
          <CustomConfirmDialog
            headerText={headerText}
            deactivate={ruleAction}
            success={confirmModalSuccess}
            toastError={confirmModalError}
            clearToastError={() => setConfirmModalError(null)}
            itemId={itemIdForDeactivation}
            confirmText={confirmText}
            loading={modalLoading}
            loadingText={
              ruleAction === "DEACTIVATE"
                ? "Deactivating configuration..."
                : "Activating configuration..."
            }
            fn={deactivateRule}
            closeModal={closeModal}
          />
        );
      default:
        break;
    }
  };

  const settlementFeeRuleConfigurationsView = () => {
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading configurations..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            authorities={authorities}
            isReload={false}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={configs}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="p-mt-2">
            <SettlementRuleConfigBreakdown
              participants={participants}
              rules={rules}
              goBack={goBack}
              details={details}
              mobile={isMobileTransaction}
            />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={() => {}}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <div className="page-header p-text-left">
          <div className="page-title">Settlement Rule Configurations</div>
          {/* <UserManualViewerButton fileName="manage_bulk_settlements" /> */}
        </div>

        <div className="p-mt-2">
          <CustomBreadcrumb page="Manage Settlement Rule Configurations" />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6"></div>
                <div className="p-col-6">
                  <div
                    className={
                      HELPER.HAS_AUTHORITY("iss_create_settlement_rule") &&
                      currentIndex === 1
                        ? "dcir-show"
                        : "dcir-hide"
                    }
                  >
                    <button
                      disabled={loading}
                      onClick={() => {
                        openModal(1, false);
                        setIsUpdate(false);
                      }}
                      className="primary-button"
                    >
                      <i className="pi pi-plus" />
                      <span className="hide-btn-text"> Add Configuration</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            HELPER.HAS_AUTHORITY("iss_view_settlement_rules")
              ? "dcir-show"
              : "dcir-hide"
          }
        >
          {settlementFeeRuleConfigurationsView()}
        </div>
        <div
          className={
            HELPER.HAS_AUTHORITY("iss_view_settlement_rules")
              ? "dcir-hide"
              : "dcir-show"
          }
        >
          <AccessDenied />
        </div>
      </div>
    </ErrorBoundary>
  );
}
