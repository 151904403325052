import React, { useContext, useState } from "react";
import { saveAs } from "file-saver";
import { CustomToast } from "components/elements/alert/custom-toast";
import { HELPER } from "utils/helper";
import { Optional } from "components/elements/optional/optional";
import { DownloadProgress } from "components/elements/download-progress/download-progress";
import { MainContext } from "../../../../App";
import { CustomMessage } from "components/elements/alert/custom-message";
import { BACKOFFICE_SERVICE } from "../../../../api/services/backoffice-service";

export function ReconFileDownload(props) {
  const [loading, setLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(null);
  const [reportGenerationMessage, setReportGenerationMessage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const mainContext = useContext(MainContext);

  function submit() {
    if (props.reportDownloadStatus === "COMPLETED") {
      downloadReport();
    } else {
      generateReport();
    }
  }

  function generateReport() {
    setLoading(true);
    const payload = {
      shouldTriggerReportGeneration: true,
    };
    if (props.isTransactionsByDays) {
      payload["transactionDate"] = props.transactionDate;
    } else {
      payload["fileKey"] = props.fileKey;
    }
    BACKOFFICE_SERVICE.CHECK_OR_GENERATE_RECON_REPORT(payload)
      .then((data) => {
        setReportGenerationMessage(data?.result?.message);
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setDownloadError(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  function downloadReport() {
    setLoading(true);
    // const payload = HELPER.TO_URL_STRING({
    //   fileKey: props.downloadKey,
    // });
    BACKOFFICE_SERVICE.DOWNLOAD_RECON_STATEMENT(props.downloadKey, mainContext)
      .then((data) => {
        saveAs(data, `${props.fileKey}.xlsx`);
        props.closeModal(true);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function handleError(error) {
    const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
    setDownloadError(errMessage);
    setLoading(false);
  }

  function responseView() {
    return (
      <div>
        <div>
          <CustomMessage messageType="success" />
        </div>
        <div>
          <p className="success-message-text">{reportGenerationMessage}</p>
        </div>
        <div className="success-message-btn-container p-text-center">
          <p
            onClick={() => props.closeModal(true)}
            className="close-modal add-cursor"
          >
            Close
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      {currentIndex === 0 ? (
        <div>
          <h4 className="p-text-left">
            Confirm{" "}
            {props.reportDownloadStatus === "COMPLETED"
              ? "Download"
              : "Report Generation"}
          </h4>
          <p className="p-text-left">
            Please confirm that you want to{" "}
            {props.reportDownloadStatus === "COMPLETED"
              ? `download this file`
              : "generate this file"}
            .{" "}
            {props.reportDownloadStatus !== "COMPLETED"
              ? "Report will be generated and will be available for download once completed!"
              : ""}
          </p>
          {/* <Optional show={props.reportDownloadStatus === "PENDING"}>
            <p className="p-text-left">
              Report will be generated and will be available for download once
              completed!
            </p>
          </Optional> */}
          <div className="p-pb-1">
            {downloadError && (
              <CustomToast
                title="Error"
                description={downloadError}
                type="error"
                clearToast={() => setDownloadError(null)}
              />
            )}
          </div>
          <Optional show={loading}>
            <DownloadProgress />
          </Optional>
          <Optional show={!loading}>
            <div className="p-mt-4 p-pb-2">
              <div className="p-grid">
                <div className="p-col-6">
                  <button
                    onClick={props.closeModal}
                    className="secondary-button"
                  >
                    Cancel
                  </button>
                </div>
                <div className="p-col-6">
                  <button onClick={submit} className="primary-button">
                    {props.reportDownloadStatus === "COMPLETED"
                      ? "Download"
                      : "Generate"}
                  </button>
                </div>
              </div>
            </div>
          </Optional>
        </div>
      ) : (
        responseView()
      )}
    </div>
  );
}
