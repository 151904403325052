import { useEffect, useState } from "react";
import { CUSTOM_VALIDATION } from "utils/validation";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomMessage } from "components/elements/alert/custom-message";
import { CustomToast } from "components/elements/alert/custom-toast";
import { FormDropdown } from "components/form/form-dropdown";
import { FormTextArea } from "components/form/form-text-area";
import { HELPER } from "utils/helper";
import { BACKOFFICE_SERVICE } from "../../../../api/services/backoffice-service";
import { RECON_API } from "../../../../api/routes/back-office/reconciliation";

export function ResolveException(props) {
  const [loading, setLoading] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [messageTitle, setMessageTitle] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [message, setMessage] = useState(null);
  // const [disputeAcceptanceConfirmation] =
  //   useState(false);
  const status = ["Mark as false positive"];
  const [resolution, setResolution] = useState({
    comment: "",
    reconResolutionReason: "",
    reconResolutionStatus: "RESOLVED",
  });

  const [formErrors, setFormErrors] = useState({
    comment: null,
    reconResolutionReason: null,
    reconResolutionStatus: null,
  });

  useEffect(() => {
    let mounted = true;
    function checkValidForm() {
      const validForm = CUSTOM_VALIDATION.VALID_OBJ(resolution, 3);
      const validErrorForm = !CUSTOM_VALIDATION.VALID_OBJ_ANY(formErrors);
      setValidForm(validForm && validErrorForm);
    }

    if (mounted) {
      checkValidForm();
    }
    return () => {
      mounted = false;
    };
  }, [resolution, formErrors]);

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setResolution({ ...resolution, [name]: value });
      setFormErrors({ ...formErrors, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setFormErrors({ ...formErrors, [name]: errorMessage });
    }
  }

  const viewAlert = () => {
    if (messageTitle) {
      return (
        <div>
          <CustomToast
            title={messageTitle}
            description={message}
            type="error"
            clearToast={() => setMessageTitle(null)}
          />
        </div>
      );
    } else {
      return <div />;
    }
  };

  function submit() {
    setLoading(true);
    BACKOFFICE_SERVICE.MAKE_PUT_REQUEST(
      RECON_API.RESOLVE_RECON_ACCOUNT_ENTRY,
      resolution,
      null,
      props.referenceId
    )
      .then((data) => {
        setMessageTitle(null);
        setSuccessMessage(
          data?.result?.message || "Exception resolved successfully"
        );
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setMessageTitle("Error");
        setMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  const customCancelButton = () => {
    if (!loading) {
      return (
        <button onClick={() => props.closeModal()} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  const customSubmitButton = () => {
    if (!loading) {
      return (
        <button
          onClick={submit}
          disabled={!validForm}
          className="primary-button"
        >
          Resolve
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  function validateDropdown(e, name) {
    const value = e.target.value;
    if (value) {
      setResolution({ ...resolution, [name]: value });
      setFormErrors({ ...formErrors, [name]: null });
    } else {
      let errorMessage = "Select Action";
      setFormErrors({ ...formErrors, [name]: errorMessage });
    }
  }

  // function handleDisputeAcceptanceConfirmation(e) {
  //   setDisputeAcceptanceConfirmation(e?.target?.checked);
  // }

  const disputeForm = () => {
    return (
      <div>
        <div>
          <p className="custom-modal-title p-text-left p-mt-0">
            Exception Resolution
          </p>
        </div>
        <div className="custom-dialog-subtitle-container-lg">
          <p className="custom-dialog-subtitle p-mb-5">
            Fill the form below to resolve this exception
          </p>
        </div>
        <div className="p-pb-1">{viewAlert()}</div>
        <div className="p-grid">
          <div className="p-col-12">
            <FormDropdown
              required={false}
              field="reconResolutionReason"
              error={formErrors["reconResolutionReason"]}
              disabled={loading}
              value={resolution["reconResolutionReason"]}
              fn={validateDropdown}
              options={status}
              placeholder="Select Action"
            />
          </div>
          <div className="p-col-12">
            <FormTextArea
              required={true}
              field="comment"
              type="DESCRIPTION"
              error={formErrors["comment"]}
              fn={validateForm}
              loading={loading}
              placeholder="Comment"
            />
          </div>
          <div className="p-col-12">
            <div className="p-mt-4">
              <div className="p-grid">
                <div className="p-col-6">{customCancelButton()}</div>
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {customSubmitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const transformView = () => {
    if (currentIndex) {
      return (
        <div className="p-text-center p-mt-2">
          <div>
            <CustomMessage messageType="success" />
          </div>
          <div>
            <p className="success-message-text">{successMessage}</p>
          </div>
          <div className="success-message-btn-container p-text-center">
            <p
              className="close-modal add-cursor p-mt-3"
              onClick={() => props.onSuccess()}
            >
              Close
            </p>
          </div>
        </div>
      );
    } else {
      return <div>{disputeForm()}</div>;
    }
  };

  return <div className="p-pb-5">{transformView()}</div>;
}
