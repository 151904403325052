import { useEffect, useState } from "react";
import { HELPER } from "utils/helper";
import { CustomToast } from "components/elements/alert/custom-toast";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomMessage } from "components/elements/alert/custom-message";
import { saveAs } from "file-saver";
import { CustomUpload } from "components/elements/custom-upload/custom-upload";
import { Optional } from "components/elements/optional/optional";
import { FormRadioInput } from "components/form/form-radio-input";
import { BACKOFFICE_CONFIGS } from "utils/constants";
import { API_SERVICE } from "api/service";
import { STORAGE } from "utils/storage";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function CreateBulkUsers(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [bulkUsers, setBulkUsers] = useState(null);
  const [userType, setUserType] = useState("LOCAL");
  const [canCreateAdUsers, setCanCreateAdUsers] = useState(false);
  const [gettingUserCreationConfiguration, setGettingUserConfiguration] =
    useState(false);

  useEffect(() => {
    function getUserCreationConfiguration() {
      setGettingUserConfiguration(true);
      const url = BACK_OFFICE_API.CONFIGURATION.CONFIGURATIONS;
      API_SERVICE.MAKE_GET_REQUEST(
        `${url}/${BACKOFFICE_CONFIGS.ACTIVE_DIRECTORY_STATUS}`
      )
        .then((data) => {
          const result = data?.result;
          let hasAdDirectory = result.configValue === "true" ? true : false;
          setCanCreateAdUsers(hasAdDirectory);
          setGettingUserConfiguration(false);
        })
        .catch((error) => {
          setErrorMessage(HELPER.PROCESS_ERROR(error));
          setGettingUserConfiguration(false);
        });
    }
    getUserCreationConfiguration();
  }, []);

  function handleUserTypeSelection(e) {
    const value = e?.target?.value;
    setUserType(value);
  }

  function downloadExcelTemplate() {
    if (userType === "LOCAL") {
      saveAs(
        `${process.env.PUBLIC_URL}/bulk_local_user_creation_sample.xlsx`,
        "bulk_local_user_creation_sample.xlsx"
      );
    } else {
      saveAs(
        `${process.env.PUBLIC_URL}/bulk_ad_user_creation_sample.xlsx`,
        "bulk_ad_user_creation_sample.xlsx"
      );
    }
  }

  function downloadCsvTemplate() {
    if (userType === "LOCAL") {
      saveAs(
        `${process.env.PUBLIC_URL}/bulk_local_user_creation_sample.csv`,
        "bulk_local_user_creation_sample.csv"
      );
    } else {
      saveAs(
        `${process.env.PUBLIC_URL}/bulk_ad_user_creation_sample.csv`,
        "bulk_ad_user_creation_sample.csv"
      );
    }
  }

  function submit() {
    setLoading(true);
    let formData = new FormData();
    formData.append("file", bulkUsers);
    const params = HELPER.TO_URL_STRING({
      backofficeUserType: userType,
      email: STORAGE.GET_USER_EMAIL(),
    });
    const { UPLOAD_BULK_USERS } = BACK_OFFICE_API.USER_MANAGEMENT;
    API_SERVICE.MAKE_POST_REQUEST(`${UPLOAD_BULK_USERS}?${params}`, formData)
      .then((res) => {
        setSuccessMessage(res.result);
        setLoading(false);
        setCurrentIndex(1);
      })
      .catch((error) => {
        setErrorMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={() => props.closeModal()} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  const submitButton = () => {
    if (!loading) {
      return (
        <button
          disabled={!bulkUsers}
          onClick={submit}
          className="primary-button"
        >
          Submit
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2 p-mt-5">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  const currentView = () => {
    if (currentIndex) {
      return (
        <div className="p-text-center p-mt-2 success-wrapper">
          <div>
            <CustomMessage messageType="success" />
          </div>
          <div className="w-100">
            <p className="success-message-text">
              {/* Bulk dispute uploaded successfully! */}
              {successMessage}
            </p>
          </div>
          <div className="success-message-btn-container p-text-center">
            <p
              className="close-modal add-cursor p-mt-3"
              onClick={() => props.closeModal(true)}
            >
              Close
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Optional show={gettingUserCreationConfiguration}>
            <CustomLoader loadingText="Loading. Please wait..." />
          </Optional>
          <Optional show={!gettingUserCreationConfiguration}>
            <div className="bulk-upload">
              <div>
                <p className="custom-modal-title p-text-left">
                  Bulk Back Office User Creation
                </p>
              </div>
              <div className="custom-dialog-subtitle-container-lg">
                <p className="custom-dialog-subtitle p-mb-5">
                  Click below to upload file
                </p>
              </div>

              <Optional show={canCreateAdUsers}>
                <div className="flex default p-mb-4">
                  <FormRadioInput
                    id="LOCAL"
                    value="LOCAL"
                    name="user-type"
                    checked={userType === "LOCAL"}
                    handleChange={handleUserTypeSelection}
                    label="Local Users"
                  />
                  <div className="p-ml-3">
                    <FormRadioInput
                      id="AD"
                      value="AD"
                      name="user-type"
                      checked={userType === "AD"}
                      handleChange={handleUserTypeSelection}
                      label="Active Directory Users"
                    />
                  </div>
                </div>
              </Optional>
              <div>
                <CustomUpload
                  accept=".csv,.xlsx"
                  title={`Click to upload a .xlsx or.csv file * (${
                    userType === "LOCAL"
                      ? "Local Users"
                      : "Active Directory Users"
                  })`}
                  fileType="spreadsheet"
                  getUploadedFile={(file) => setBulkUsers(file)}
                  clearUploadedFile={() => setBulkUsers(null)}
                  noMaxSizeTip={true}
                />
              </div>

              <div className="p-text-left p-mt-4 p-d-flex p-justify-between">
                <button
                  className="bare-button"
                  onClick={downloadExcelTemplate}
                  style={{ fontSize: "0.9rem" }}
                >
                  Download Excel Template
                </button>
                <button
                  className="bare-button"
                  onClick={downloadCsvTemplate}
                  style={{ fontSize: "0.9rem" }}
                >
                  Download CSV Template
                </button>
              </div>
              <Optional
                show={!gettingUserCreationConfiguration && errorMessage}
              >
                <CustomToast
                  title="Error"
                  description={errorMessage}
                  type="error"
                  clearToast={() => setErrorMessage(null)}
                />
              </Optional>
              <div className="p-col-12 p-mb-5">
                <div className="p-mt-4">
                  <div className="p-grid">
                    <div className="p-col-6 p-pl-0">{cancelButton()}</div>
                    <div
                      className={`${loading ? "p-col-12" : "p-col-6"} p-pr-0`}
                    >
                      {submitButton()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Optional>
          {/* <Optional show={!gettingUserCreationConfiguration && errorMessage}>
            <CustomToast
              title="Error"
              description={errorMessage}
              type="error"
            />
          </Optional> */}
        </div>
      );
    }
  };

  return <div>{currentView()}</div>;
}
