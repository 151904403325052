import { useEffect, useState } from "react";
import { CustomToast } from "components/elements/alert/custom-toast";
import { API_SERVICE } from "api/service";
import { CustomMessage } from "components/elements/alert/custom-message";
import { HELPER } from "utils/helper";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";
import "./settlement-rule-configs.css";
import { RuleSetup } from "./config-form-components.js/rule-setup";
import { AccountSetup } from "./config-form-components.js/account-setup";
import { SettlementSetup } from "./config-form-components.js/settlement-setup";

export function SettlementRuleConfigForm(props) {
  const [currentIndex, setCurrentIndex] = useState(props.isUpdate ? 1 : 0);
  const [loadingSubmission, setLoadingSubmission] = useState(false);
  const [messageTitle, setMessageTitle] = useState(null);
  const [alertType, setAlertType] = useState("success");
  const [message, setMessage] = useState(null);
  const [hideTitle, setHideTitle] = useState(false);
  const [payload, setPayload] = useState({});

  useEffect(() => {
    setPayload(props.selectedRule);
  }, []);

  const updateRule = (data) => {
    setLoadingSubmission(true);
    const {
      rules,
      disabled,
      ruleKey,
      status,
      actions,
      detailsFunction,
      ...formattedPayload
    } = {
      ...payload,
      ...data,
    };
    const url = `${BACK_OFFICE_API.SETTLEMENT_RULE_CONFIGS.CONFIGS_ROUTE}/${props?.selectedRule.ruleKey}`;
    API_SERVICE.MAKE_PUT_REQUEST(url, formattedPayload)
      .then(() => {
        handleSuccess("updated");
      })
      .catch((error) => {
        handleError(error);
      });
  };
  const createRule = (data) => {
    setLoadingSubmission(true);
    const { status, actions, detailsFunction, ...formattedPayload } = {
      ...payload,
      ...data,
    };
    const url = BACK_OFFICE_API.SETTLEMENT_RULE_CONFIGS.CONFIGS_ROUTE;
    API_SERVICE.MAKE_POST_REQUEST(url, formattedPayload)
      .then(() => {
        handleSuccess("created");
      })
      .catch((error) => {
        handleError(error);
      });
  };

  function handleSuccess(action) {
    setAlertType("");
    setMessage("");
    setMessageTitle("");
    setMessage(`Configuration ${action} successfully`);
    offTitle();
    setCurrentIndex(3);
    setLoadingSubmission(false);
  }

  const handleError = (error) => {
    callAlert("Error", HELPER.PROCESS_ERROR(error));
    setLoadingSubmission(false);
  };

  function callAlert(title, description) {
    setAlertType("error");
    setMessage(description);
    setMessageTitle(title);
  }

  function navigate(index) {
    setCurrentIndex(index);
  }

  function close(e) {
    props.closeModal(e);
  }

  const currentWizardName = () => {
    switch (currentIndex) {
      case 0:
        return "Rule Setup";
      case 1:
        return "Account Setup ";
      case 2:
        return "Settlement Setup";
      default:
        return "";
    }
  };

  const buttonAction = (data, index, isSubmit, isUpdate) => {
    if (isSubmit) {
      if (isUpdate) {
        updateRule(data);
      } else {
        createRule(data);
      }
    } else {
      setPayload((prev) => ({ ...prev, ...data }));
      navigate(index);
    }
  };

  const stepWizard = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return <RuleSetup close={close} next={buttonAction} {...props} />;
      case 1:
        return (
          <AccountSetup
            action={buttonAction}
            {...props}
            close={close}
            payload={payload}
          />
        );
      case 2:
        return (
          <SettlementSetup
            action={buttonAction}
            payload={payload}
            loading={loadingSubmission}
            {...props}
          />
        );
      case 3:
        return (
          <div>
            <CustomMessage
              close={true}
              closeModal={props.closeModal}
              messageType="success"
              message={message}
            />
          </div>
        );
    }
  };

  function offTitle() {
    setHideTitle(true);
  }

  return (
    <div className="custom-form-container super-agents">
      <div>
        <div
          style={{ display: hideTitle ? "none" : "block" }}
          className="custom-modal-title p-text-left"
        >
          {props.isUpdate
            ? "Update Settlement Rule Configuration"
            : "Create Settlement Rule Configuration"}
        </div>
        <div
          style={{ display: hideTitle ? "none" : "block" }}
          className="custom-dialog-subtitle-container"
        >
          <p className="custom-dialog-subtitle p-text-bold">
            {currentWizardName()}
          </p>
        </div>
        <div className="login-alert-container">
          {messageTitle ? (
            <div className="merchant-toast">
              <CustomToast
                title={messageTitle}
                description={message}
                type={alertType}
                clearToast={() => setMessageTitle(null)}
              />
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className="p-mt-2">{stepWizard()}</div>
      </div>
    </div>
  );
}
