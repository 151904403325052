import { useCallback, useEffect, useState } from "react";
import { MONITORING_SERVICE } from "api/services/monitoring-service";
// import { CustomCard } from "../../../shared/components/custom-card/custom-card";
import { CustomCard } from "components/elements/custom-card/custom-card";
import { ServerMemoryMetrics } from "./server-memory-metrics";
import { ServerStatus } from "./server-status";
import { usePolling } from "hooks/use-polling";

export function Servers({ service, status }) {
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(false);

  const getServers = useCallback((hideLoader) => {
    if (!hideLoader) setLoading(true);
    MONITORING_SERVICE.MAKE_GET_REQUEST("/server/health")
      .then((response) => {
        setServers(response?.response);
        setLoading(false);
      })
      .catch((error) => {
        console.log("err", error);
      });
  }, []);

  useEffect(() => {
    getServers();
  }, [getServers]);

  usePolling(getServers, true);

  const renderServers = () => {
    return servers.map((server) => (
      // <div className="p-grid">
      <div key={server.name} className="p-col-6 p-mt-5">
        <CustomCard>
          <div className="p-grid">
            <div className="p-col-12">
              <ServerMemoryMetrics server={server} />
            </div>
            <div className="p-col-12">
              <ServerStatus server={server} />
            </div>
          </div>
        </CustomCard>
      </div>
      // </div>
    ));
  };
  if (loading) {
    return <CustomCard loading={loading}></CustomCard>;
  }
  return <div className="p-mt-5 p-grid">{renderServers()}</div>;
}
