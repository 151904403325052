import { HELPER } from "utils/helper";
import { CONFIG } from "../config/config";
import { apiClient, getAuthHeaders } from "./client";
import { COMMON_API } from "./routes/common/index";

function handleFileDownloadProgress(progressEvent, mainContext) {
  var percentDownloaded = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total
  );
  if (progressEvent.total === 0) {
    percentDownloaded = 0;
  }
  mainContext?.mainDispatch({
    type: "SAVE_PERCENT_DOWNLOADED",
    payload: percentDownloaded,
  });
}

function getBlobObject(mainContext) {
  return {
    responseType: "blob",
    onUploadProgress: function (progressEvent) {
      handleFileDownloadProgress(progressEvent, mainContext);
    },
  };
}

function MAKE_GET_REQUEST(url, isBlob = false, mainContext) {
  const headers = getAuthHeaders();
  const blobConfig = getBlobObject(mainContext);
  return new Promise((resolve, reject) => {
    apiClient
      .get(url, { headers, ...(isBlob && blobConfig) })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function MAKE_PUT_REQUEST(url, payload, isBlob = false, mainContext) {
  const headers = getAuthHeaders();
  const blobConfig = getBlobObject(mainContext);
  return new Promise((resolve, reject) => {
    apiClient
      .put(url, payload, { headers, ...(isBlob && blobConfig) })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function MAKE_PATCH_REQUEST(url, payload, isBlob = false, mainContext) {
  const headers = getAuthHeaders();
  const blobConfig = getBlobObject(mainContext);
  return new Promise((resolve, reject) => {
    apiClient
      .patch(url, payload, { headers, ...(isBlob && blobConfig) })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function MAKE_POST_REQUEST(url, payload) {
  const headers = getAuthHeaders();
  return new Promise((resolve, reject) => {
    apiClient
      .post(url, payload, { headers })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function MAKE_DELETE_REQUEST(url) {
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    apiClient
      .delete(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function CHANGE_PASSWORD(token, payload) {
  const url = COMMON_API.AUTH.CHANGE_PASSWORD;
  const headers = token
    ? {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    : getAuthHeaders();
  return new Promise((resolve, reject) => {
    apiClient
      .put(url, payload, { headers: headers })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function LOGIN(payload) {
  const url = CONFIG.IS_ENCRYPTED_LOGIN
    ? COMMON_API.AUTH.ENCRYPTED_LOGIN
    : COMMON_API.AUTH.LOGIN;
  const formattedPayload = CONFIG.IS_ENCRYPTED_LOGIN
    ? HELPER.ENCRYPTION_SERVICE.encryptLoginCredentials(payload)
    : payload;
  // eslint-disable-next-line no-useless-concat
  const basicAuth =
    "Basic " + btoa(`${CONFIG.AUTH_USER_NAME}:${CONFIG.AUTH_PASSWORD}`);
  const headers = {
    "Content-Type": "application/json",
    Authorization: basicAuth,
  };
  return new Promise((resolve, reject) => {
    apiClient
      .post(url, formattedPayload, {
        ...(!CONFIG.IS_ENCRYPTED_LOGIN && { headers: headers }),
      })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function authenticateTwoFactor(payload) {
  const url = COMMON_API.AUTH.AUTHENTICATE_2FA_CODE;
  return new Promise((resolve, reject) => {
    apiClient
      .post(url, payload)
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

export const AUTH_SERVICE = {
  CHANGE_PASSWORD,
  LOGIN,
  authenticateTwoFactor,
};

export const API_SERVICE = {
  MAKE_GET_REQUEST,
  MAKE_POST_REQUEST,
  MAKE_PUT_REQUEST,
  MAKE_DELETE_REQUEST,
  MAKE_PATCH_REQUEST,
};
