import { TabPanel, TabView } from "primereact/tabview";
import React, { useRef, useState } from "react";
import { Icon } from "components/elements/icons/icon";
import { SpecialLabelCases } from "utils/models/utilities";
import { useWindowSize } from "hooks/use-window-size";
import { Toast } from "primereact/toast";
import { EmptyView } from "components/elements/empty-view/empty-view";
import { ConfigParticipants } from "./config-participants";

export function SettlementRuleConfigBreakdown(props) {
  const toast = useRef(null);
  const windowSize = useWindowSize();

  function isTabMobile() {
    return windowSize.width <= 948;
  }

  const transformView = (itemCase, value) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          result = e.action(value);
        }
      });
    }
    return result;
  };

  const detailsView = (dataArr) => {
    if (dataArr.length) {
      return dataArr.map((details, index) => (
        <div key={index.toString()} className="p-grid dcir-row">
          <div className="p-col-6">
            <p className="details-label">{details.label}:</p>
          </div>
          <div className="p-col-6">
            <p className="details-value">
              {transformView(details.itemCase, details.value)}
            </p>
          </div>
        </div>
      ));
    } else {
      return <EmptyView emptyText="No records found..." />;
    }
  };

  const configurationView = () => {
    return (
      <div
        style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
        className="p-shadow-1 p-pb-5"
      >
        <div className="p-text-left p-ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          <TabPanel header={isTabMobile() ? "CS" : "Configuration Summary"}>
            <div className="p-text-left">
              <div className="p-grid">
                <div className="p-col-12">
                  <div>
                    <div className="p-text-left">
                      <p
                        className={
                          isTabMobile()
                            ? "dcir-show custom-title p-ml-4"
                            : "dcir-hide"
                        }
                      >
                        Configuration Details
                      </p>
                    </div>
                    <div className="p-text-left">
                      {detailsView(props.details)}
                    </div>
                  </div>
                </div>

                <div className="p-col-12">
                  <div style={{ marginTop: "-2.2em" }}>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header={isTabMobile() ? "Participants" : "Participants"}>
            <div className="p-text-left">
              <div className="p-grid">
                <div className="p-col-12">
                  <div>
                    <div className="p-text-left">
                      <p
                        className={
                          isTabMobile()
                            ? "dcir-show custom-title p-ml-4"
                            : "dcir-hide"
                        }
                      >
                        Participants
                      </p>
                    </div>
                    <div className="p-text-left p-mt-5">
                      <ConfigParticipants participants={props.participants} />
                    </div>
                  </div>
                </div>

                <div className="p-col-12">
                  <div style={{ marginTop: "-2.2em" }}>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header={isTabMobile() ? "Rules" : "Rules"}>
            <div className="p-text-left">
              <div className="p-grid">
                <div className="p-col-12">
                  <div>
                    <div className="p-text-left">
                      <p
                        className={
                          isTabMobile()
                            ? "dcir-show custom-title p-ml-4"
                            : "dcir-hide"
                        }
                      >
                        Rules
                      </p>
                    </div>
                    <div className="p-text-left p-mt-3">
                      {detailsView(props.rules)}
                    </div>
                  </div>
                </div>

                <div className="p-col-12">
                  <div style={{ marginTop: "-2.2em" }}>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      {configurationView()}
    </div>
  );
}
