import "./public-holidays.css";
// import moment from "moment";
import { useEffect, useState } from "react";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { FormInput } from "components/form/form-input";
import { API_SERVICE } from "api/service";
import { CustomToast } from "components/elements/alert/custom-toast";
import { CUSTOM_VALIDATION } from "utils/validation";
import { FormDropdown } from "components/form/form-dropdown";
import { CustomMessage } from "components/elements/alert/custom-message";
// import { Calendar } from "primereact/calendar";
import { HELPER } from "utils/helper";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function PublicHolidayForm(props) {
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [messageTitle, setMessageTitle] = useState(null);
  const [message, setMessage] = useState(null);
  const [validForm, setValidForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [publicHolidayModel, setPublicHolidayModel] = useState({
    date: "",
    description: "",
    name: "",
    recurring: "",
  });
  const [publicHolidayFormError, setPublicHolidayFormError] = useState({
    date: null,
    description: null,
    name: null,
    recurring: null,
  });
  const recurringChoices = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];

  const viewAlert = () => {
    if (messageTitle) {
      return (
        <div>
          <CustomToast
            title={messageTitle}
            description={message}
            type="error"
            clearToast={() => setMessageTitle(null)}
          />
        </div>
      );
    } else {
      return <div />;
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && props.isUpdate) {
      // let formattedDate = new Date(props.editedPublicHoliday.dates);
      // console.log(formattedDate);
      setPublicHolidayModel({
        ...props.editedPublicHoliday,
        // dates: [formattedDate],
      });
    }
    return () => {
      mounted = false;
    };
  }, [props.isUpdate, props.editedPublicHoliday]);

  useEffect(() => {
    let mounted = true;

    function checkValidForm() {
      let isFormError = false;
      for (const property in publicHolidayFormError) {
        if (publicHolidayFormError[property]) {
          isFormError = true;
        }
      }
      if (
        publicHolidayModel.date.length &&
        publicHolidayModel.description.length &&
        publicHolidayModel.name.length &&
        !isFormError &&
        (publicHolidayModel.recurring === true ||
          publicHolidayModel.recurring === false)
      ) {
        setValidForm(true);
      } else {
        setValidForm(false);
      }
    }
    if (mounted) {
      checkValidForm();
    }
    return () => {
      mounted = false;
    };
  }, [publicHolidayModel, publicHolidayFormError]);

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    if (props.isSearch) {
      setPublicHolidayModel({ ...publicHolidayModel, [name]: value });
    } else {
      const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
      const isValidInput = !isEmpty
        ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
        : false;
      if (isValidInput) {
        setPublicHolidayModel({ ...publicHolidayModel, [name]: value });
        setPublicHolidayFormError({ ...publicHolidayFormError, [name]: null });
      } else {
        let errorMessage =
          required && isEmpty ? `${refineName} is required` : null;
        if (!isValidInput) {
          errorMessage = `${refineName} is invalid`;
        }
        setPublicHolidayFormError({
          ...publicHolidayFormError,
          [name]: errorMessage,
        });
      }
    }
  }

  function submit() {
    setLoading(true);
    let payload = {
      ...publicHolidayModel,
    };
    if (props.isUpdate) {
      payload = {
        ...publicHolidayModel,
        active: true,
      };
    }
    const { UPDATE_PUBLIC_HOLIDAY, CREATE_PUBLIC_HOLIDAY } =
      BACK_OFFICE_API.PUBLIC_HOLIDAY;

    const SERVICE = props.isUpdate
      ? API_SERVICE.MAKE_PUT_REQUEST
      : API_SERVICE.MAKE_POST_REQUEST;

    const url = props.isUpdate
      ? `${UPDATE_PUBLIC_HOLIDAY}/${props.editedPublicHoliday.code}`
      : CREATE_PUBLIC_HOLIDAY;

    SERVICE(`${url}`, payload)
      .then((data) => {
        setMessageTitle(null);
        setSuccessMessage(
          `Public Holiday ${
            props.isUpdate ? "updated" : "created"
          } successfully`
        );
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setMessageTitle("Error");
        // setMessage("An error occur when creating public holiday");
        setMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  function cancelModal() {
    props.closeModal();
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={cancelModal} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  function validateDropdown(e, name) {
    const value = e.target.value;
    setPublicHolidayFormError({ ...publicHolidayFormError, [name]: "" });
    setPublicHolidayModel({ ...publicHolidayModel, [name]: value });
  }

  const submitButton = () => {
    if (!loading) {
      if (props.isUpdate) {
        return (
          <button
            disabled={!validForm}
            onClick={submit}
            className="primary-button"
          >
            Update
          </button>
        );
      } else if (props.isSearch) {
        return (
          <button
            onClick={() => props?.searchFunction()}
            className="primary-button"
          >
            Filter
          </button>
        );
      } else {
        return (
          <button
            disabled={!validForm}
            onClick={submit}
            className="primary-button"
          >
            Submit
          </button>
        );
      }
    } else {
      return (
        <div className="pull-up-element-2">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  // function setPublicHolidayDates(e) {
  //   setPublicHolidayModel({ ...publicHolidayModel, dates: e.value });
  // }

  const publicHolidayFormView = () => {
    if (currentIndex) {
      return (
        <div>
          <div>
            <CustomMessage messageType="success" />
          </div>
          <div>
            <p className="success-message-text">{successMessage}</p>
          </div>
          <div className="success-message-btn-container p-text-center">
            <p
              onClick={() => {
                props.closeModal(true);
              }}
              className="close-modal add-cursor"
            >
              Close
            </p>
          </div>
        </div>
      );
    } else {
      return <div>{publicHolidayForm()}</div>;
    }
  };

  const publicHolidayForm = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">
          {props.isUpdate
            ? "Update public holiday details"
            : props.isSearch
            ? "Filter"
            : "New public holiday model"}
        </div>
        <div className="custom-dialog-subtitle-container p-mb-5">
          <p className="custom-dialog-subtitle">
            {props.isUpdate
              ? "Update the form below"
              : props.isSearch
              ? ""
              : "Fill the form below to create model"}
          </p>
          <p className="custom-dialog-subtitle">* Required Fields</p>
        </div>
        <div className="p-pb-1">{viewAlert()}</div>
        <div className="p-grid">
          <div className={props.isSearch ? "p-col-12" : "p-col-12"}>
            <FormInput
              value={publicHolidayModel["name"]}
              required={true}
              field="name"
              type="NAME"
              error={publicHolidayFormError["name"]}
              fn={validateForm}
              loading={loading}
              placeholder="Name"
            />
          </div>
          <div className="p-col-12 p-mt-2">
            {props.isUpdate && <label> Is holiday recurring?</label>}
            <FormDropdown
              required={true}
              label="name"
              field="recurring"
              error={publicHolidayFormError["recurring"]}
              disabled={loading}
              value={publicHolidayModel["recurring"]}
              fn={validateDropdown}
              options={recurringChoices}
              placeholder="Is this holiday recurring? *"
            />
          </div>
          <div className="p-col-12 p-mt-2">
            <FormInput
              value={publicHolidayModel["description"]}
              required={true}
              field="description"
              type="DESCRIPTION"
              error={publicHolidayFormError["description"]}
              fn={validateForm}
              loading={loading}
              placeholder="Description"
            />
          </div>
          <div className="p-col-12 p-mt-2">
            {!props.isUpdate && (
              <FormInput
                inputType="date"
                value={publicHolidayModel["date"]}
                required={false}
                field="date"
                type="INPUT"
                error={publicHolidayFormError["date"]}
                fn={validateForm}
                loading={loading}
                placeholder="Date"
                noMax={true}
              />
            )}
            {/* {props.isUpdate ? (
              <FormInput
                inputType="date"
                value={publicHolidayModel["date"]}
                required={false}
                field="date"
                type="INPUT"
                error={publicHolidayFormError["date"]}
                fn={validateForm}
                loading={loading}
                placeholder="Date"
                noMax={true}
              />
            ) : (
              <Calendar
                selectionMode="multiple"
                placeholder="Select dates"
                dateFormat="yy-mm-dd"
                baseZIndex="2101"
                value={publicHolidayModel["dates"]}
                onChange={(e) => setPublicHolidayDates(e)}
              ></Calendar>
            )} */}
          </div>
          <div className="p-col-12">
            <div className="p-mt-5">
              <div className="p-grid">
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {cancelButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div className="p-pb-2">{publicHolidayFormView()}</div>;
}
