import { BACK_OFFICE_API } from "api/routes/back-office/index";
import { API_SERVICE } from "api/service";
import { CustomToast } from "components/elements/alert/custom-toast";
import { FormDropdown } from "components/form/form-dropdown";
import { FormInput } from "components/form/form-input";
import { useEffect, useState } from "react";
import { HELPER } from "utils/helper";

export function RuleSetup(props) {
  const [ruleSetupFormFields, setRuleSetupFormFields] = useState([
    {
      key: "",
      value: "",
      option: "",
    },
  ]);
  const [ruleOptions, setRuleOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ruleSetupError, setRuleSetupError] = useState({});
  const [error, setError] = useState({
    message: "",
    title: "",
    type: "error",
  });

  useEffect(() => {
    setError({
      message: "",
      title: "",
      type: "error",
    });
    const url = BACK_OFFICE_API.SETTLEMENT_RULE_CONFIGS.CONFIGS_ROUTE;
    API_SERVICE.MAKE_GET_REQUEST(`${url}/valid-fields`)
      .then((data) => {
        const result = data?.result;
        const formattedData = result.map((item) => ({
          name: HELPER.CAMEL_TO_TITLE_CASE(item),
          key: item,
        }));
        setRuleOptions(formattedData);
        setLoading(false);
      })
      .catch((error) => {
        setError({
          ...error,
          message: HELPER.PROCESS_ERROR(error),
          title: "Error fetching keys.",
        });
        setLoading(false);
      });
  }, []);

  const addRuleSetup = () => {
    if (ruleOptions.length === ruleSetupFormFields.length - 1) return;
    const newRuleSetup = { key: "", value: "", option: "" };
    setRuleSetupFormFields([...ruleSetupFormFields, newRuleSetup]);
  };

  const removeRuleSetupField = (index) => {
    if (ruleSetupFormFields.length === 1) {
      setRuleSetupFormFields([
        {
          key: "",
          value: "",
          option: "",
        },
      ]);
    } else {
      const updatedRuleSetupFormFields = ruleSetupFormFields.filter(
        (rule, i) => i !== index
      );
      setRuleSetupFormFields(updatedRuleSetupFormFields);
    }
  };

  const handleRuleSetupInput = (e, name, i) => {
    let data = [...ruleSetupFormFields];
    const value = e.target.value;
    if (typeof value === "object") {
      data[i][name] = value?.key;
      data[i]["option"] = value;
      if (validateRuleDropdown(value.key)) {
        const errorMessage = `${value.name} cannot be duplicated`;
        setRuleSetupError({ [value.key]: errorMessage });
      } else {
        setRuleSetupError({});
      }
    } else {
      data[i][name] = value;
    }
    setRuleSetupFormFields(data);
  };

  function validateRuleDropdown(key) {
    return ruleSetupFormFields.filter((rule) => rule.key === key).length > 1;
  }

  function validateFormValues() {
    return ruleSetupFormFields
      .map((rules) => rules.value === "")
      .includes(true);
  }
  function validateFormKeys() {
    return ruleSetupFormFields.map((rules) => rules.key === "").includes(true);
  }

  const handleNextAction = () => {
    const data = {};
    if (!validateFormKeys() && !validateFormValues())
      ruleSetupFormFields.map((rule) => (data[rule.key] = rule.value));
    const accountSetupIndex = 1;
    props.next({ rules: { ...data } }, accountSetupIndex);
  };

  return (
    <div className="p-grid">
      <div className="p-grid p-col-12">
        {ruleSetupFormFields.map((pair, index) => {
          return (
            <div className="p-col-12  d-flex" key={index}>
              <div className="p-col-5 p-mt-1">
                <FormDropdown
                  required={true}
                  label="name"
                  field="key"
                  error={ruleSetupError[pair.key]}
                  disabled={loading}
                  value={pair.option}
                  fn={(e, name) => handleRuleSetupInput(e, name, index)}
                  options={ruleOptions}
                  placeholder={loading ? "Loading keys..." : "Select a key"}
                />
              </div>
              <div className="p-col-5">
                <FormInput
                  noFloatingLabel
                  value={pair.value}
                  required={true}
                  field="value"
                  type="INPUT"
                  label="value"
                  fn={(e, name) => handleRuleSetupInput(e, name, index)}
                  placeholder="Input value"
                />
              </div>
              <div className="p-col-2">
                <button
                  onClick={() => removeRuleSetupField(index)}
                  className="delete-button "
                >
                  <span className="add-cursor">
                    <i className="pi pi-trash" />
                  </span>
                </button>
              </div>
            </div>
          );
        })}
        <div className="p-col-3 p-ml-2" style={{ height: "100%" }}>
          <button
            disabled={ruleOptions.length === ruleSetupFormFields.length}
            onClick={addRuleSetup}
            className="primary-button"
          >
            <span className="add-cursor">
              <i className="pi pi-plus" />
              <span className="p-ml-2">Add</span>
            </span>
          </button>
        </div>
      </div>
      <div className="login-alert-container p-col-12">
        {error.title ? (
          <div className="merchant-toast">
            <CustomToast
              title={error.title}
              description={error.message}
              type={error.type}
              clearToast={() =>
                setError({ title: null, message: null, type: null })
              }
            />
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className="p-col-12 p-mt-2 d-flex p-jc-between ">
        <button onClick={props.close} className="secondary-button ">
          Close
        </button>
        <button onClick={handleNextAction} className="primary-button p-ml-2">
          Next
        </button>
      </div>
    </div>
  );
}
