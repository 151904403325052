import { useState } from "react";
import { HELPER } from "utils/helper";
import { CustomToast } from "components/elements/alert/custom-toast";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomMessage } from "components/elements/alert/custom-message";
import { saveAs } from "file-saver";
import { CustomUpload } from "components/elements/custom-upload/custom-upload";
import { API_SERVICE } from "api/service";
import { ToolTip } from "components/elements/tooltip/tooltip";
import { MAXIMUM_BULK_DISPUTE_ZIP_SIZE_IN_MB } from "utils/constants";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";

export function BulkDisputeResponseUpload(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [bulkResponse, setBulkResponse] = useState(null);
  const [bulkResponseImages, setBulkResponseImages] = useState(null);

  function downloadExcelTemplate() {
    saveAs(
      `${process.env.PUBLIC_URL}/bulk_dispute_response_sample.xlsx`,
      "bulk_dispute_response_sample.xlsx"
    );
  }

  function downloadCsvTemplate() {
    saveAs(
      `${process.env.PUBLIC_URL}/bulk_dispute_response_sample.csv`,
      "bulk_dispute_response_sample.csv"
    );
  }

  function submit() {
    setLoading(true);
    let formData = new FormData();
    formData.append("files", bulkResponse);
    formData.append("files", bulkResponseImages);

    const url =
      FRONT_OFFICE_API.BULK_DISPUTE_RESPONSE.UPLOAD_BULK_DISPUTE_RESPONSE;
    API_SERVICE.MAKE_POST_REQUEST(url, formData)
      .then((res) => {
        setSuccessMessage(res.result);
        setLoading(false);
        setCurrentIndex(1);
      })
      .catch((error) => {
        setErrorMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={() => props.closeModal()} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  const submitButton = () => {
    if (!loading) {
      return (
        <button onClick={submit} className="primary-button">
          Submit
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2 p-mt-5">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  const currentView = () => {
    if (currentIndex) {
      return (
        <div className="p-text-center p-mt-2 success-wrapper">
          <div>
            <CustomMessage messageType="success" />
          </div>
          <div className="w-100">
            <p className="success-message-text">
              {/* Bulk dispute uploaded successfully! */}
              {successMessage}
            </p>
          </div>
          <div className="success-message-btn-container p-text-center">
            <p
              className="close-modal add-cursor p-mt-3"
              onClick={() => props.closeModal(true)}
            >
              Close
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="bulk-upload">
          <div>
            <p className="custom-modal-title p-text-left">
              Bulk Dispute Response Upload
            </p>
          </div>
          <div className="custom-dialog-subtitle-container-lg">
            <p className="custom-dialog-subtitle p-mb-5">
              Click below to upload files
            </p>
          </div>
          {errorMessage && (
            <div>
              <CustomToast
                title="Error"
                description={errorMessage}
                type="error"
                clearToast={() => setErrorMessage(null)}
              />
            </div>
          )}
          <div>
            <CustomUpload
              accept=".csv,.xlsx"
              title="Click to upload .xlsx or.csv response entries"
              fileType="spreadsheet"
              getUploadedFile={(file) => setBulkResponse(file)}
              clearUploadedFile={() => setBulkResponse(null)}
              noMaxSizeTip={true}
            />
          </div>

          {/* <div>
            <p>AND</p>
          </div> */}
          <div className="p-mt-4 flex default">
            <CustomUpload
              accept=".zip"
              title="Click to upload .zip file containing receipts for rejected disputes (Optional)"
              fileType="zip"
              getUploadedFile={(file) => setBulkResponseImages(file)}
              clearUploadedFile={() => setBulkResponseImages(null)}
              maxSize="800"
              noMaxSizeTip={true}
            />
            <ToolTip
              tipPosition="left"
              tip="
              1. Upload Zip File Containing Rejected Disputes Receipt Images, if rejected disputes exist within the excel/csv upload.
              2. The File Name for each image should contain the respective dispute log code ex: BANKCODE202213131.jpg 
              "
              isHTML={false}
            />
          </div>
          <div>
            <p
              className="p-text-left text-small"
              style={{
                color: "#5D7F8D",
              }}
            >
              Note: Zip file size cannot exceed{" "}
              {MAXIMUM_BULK_DISPUTE_ZIP_SIZE_IN_MB}
              MB
            </p>
          </div>
          <div className="p-text-left p-mt-4 p-d-flex p-justify-between">
            <button
              className="bare-button"
              onClick={downloadExcelTemplate}
              style={{ fontSize: "0.9rem" }}
            >
              Download Excel Template
            </button>
            <button
              className="bare-button"
              onClick={downloadCsvTemplate}
              style={{ fontSize: "0.9rem" }}
            >
              Download CSV Template
            </button>
          </div>
          <div className="p-col-12 p-mb-5">
            <div className="p-mt-4">
              <div className="p-grid">
                <div className="p-col-6 p-pl-0">{cancelButton()}</div>
                <div className={`${loading ? "p-col-12" : "p-col-6"} p-pr-0`}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return <div>{currentView()}</div>;
}
