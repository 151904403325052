import "./participant-users.css";
import { useEffect, useRef, useState } from "react";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { FormInput } from "components/form/form-input";
import { API_SERVICE } from "api/service";
import { CustomToast } from "components/elements/alert/custom-toast";
import { CUSTOM_VALIDATION } from "utils/validation";
import { CustomMessage } from "components/elements/alert/custom-message";
import { HELPER } from "utils/helper";
import { Toast } from "primereact/toast";
import { MultiSelect } from "primereact/multiselect";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function ParticipantUserForm(props) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(2);
  const [messageTitle, setMessageTitle] = useState(null);
  const [message, setMessage] = useState(null);
  const [validForm, setValidForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  // const [loadingParticipants, setLoadingParticipants] = useState(false);
  const [participantUser, setParticipantUser] = useState({
    username: null,
    participantIds: [],
  });
  const [participantUserError, setParticipantUserError] = useState({
    username: null,
    participantIds: null,
  });
  const [participants, setParticipants] = useState([]);
  const [isValidatingUsername, setIsValidatingUsername] = useState(false);
  const [shouldAddBottomPadding, setShouldAddBottomPadding] = useState(false);

  const viewAlert = () => {
    if (messageTitle) {
      return (
        <div>
          <CustomToast
            title={messageTitle}
            description={message}
            type="error"
            clearToast={() => setMessageTitle(null)}
          />
        </div>
      );
    } else {
      return <div />;
    }
  };

  useEffect(() => {
    function getParticipants() {
      // setLoadingParticipants(true);
      const url = BACK_OFFICE_API.PARTICIPANT_USER.GET_USER_PARTICIPANTS;
      API_SERVICE.MAKE_GET_REQUEST(url)
        .then((data) => {
          // setLoadingParticipants(false);
          const result = data.result;
          let arr = [];
          result.forEach((e) => {
            arr.push({ label: e?.name, value: e.participantId });
          });
          setParticipants(arr);
          setParticipantUser((participantUser) => {
            return {
              ...participantUser,
              ...props.editedParticipantUser,
            };
          });
          setLoading(false);
        })
        .catch((error) => {
          // setLoadingParticipants(false);
          toast.current.show({
            severity: "error",
            summary: "Error getting participants!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }
    getParticipants();
  }, [props.isUpdate, props.editedParticipantUser]);

  useEffect(() => {
    let mounted = true;

    function checkValidForm() {
      const validForm =
        CUSTOM_VALIDATION.VALID_OBJ(participantUser, 2) &&
        participantUser["participantIds"].length;
      const validErrorForm =
        !CUSTOM_VALIDATION.VALID_OBJ_ANY(participantUserError);
      setValidForm(validForm && validErrorForm);
    }
    if (mounted) {
      checkValidForm();
    }
    return () => {
      mounted = false;
    };
  }, [participantUser, participantUserError]);

  function validateUsername(username) {
    setParticipantUserError({
      ...participantUserError,
      username: null,
    });
    setIsValidatingUsername(true);
    const params = HELPER.TO_URL_STRING({
      username: username,
    });
    const url = BACK_OFFICE_API.PARTICIPANT_USER.VALIDATE_PARTICIPANT_USERNAME;
    API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
      .then((response) => {
        setIsValidatingUsername(false);
        setParticipantUserError({
          ...participantUserError,
          username: null,
        });
        setParticipantUser({ ...participantUser, username: username });
      })
      .catch((error) => {
        setIsValidatingUsername(false);
        setParticipantUserError({
          ...participantUserError,
          username: HELPER.PROCESS_ERROR(error, "NAME_ENQUIRY"),
        });
      });
  }

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    if (value.length) {
      validateUsername(value);
    } else {
      setParticipantUserError({
        ...participantUserError,
        username: "Username is required",
      });
    }
  }

  function updateParticipantUser() {
    setLoading(true);
    const url = BACK_OFFICE_API.PARTICIPANT_USER.UPDATE_PARTICIPANT_USER;
    API_SERVICE.MAKE_PUT_REQUEST(url, participantUser)
      .then((data) => {
        setMessageTitle(null);
        setSuccessMessage("Participant user updated successfully");
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setMessageTitle("Error");
        setMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  function submit() {
    setLoading(true);
    const url = BACK_OFFICE_API.PARTICIPANT_USER.CREATE_PARTICIPANT_USER;
    API_SERVICE.MAKE_POST_REQUEST(url, participantUser)
      .then((data) => {
        setMessageTitle(null);
        setSuccessMessage("Participant user created successfully");
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setMessageTitle("Error");
        setMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  function cancelModal() {
    props.closeModal();
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={cancelModal} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  const submitButton = () => {
    if (!loading) {
      if (props.isUpdate) {
        return (
          <button
            disabled={!validForm}
            onClick={updateParticipantUser}
            className="primary-button"
          >
            Update
          </button>
        );
      } else {
        return (
          <button
            disabled={!validForm}
            onClick={submit}
            className="primary-button"
          >
            Submit
          </button>
        );
      }
    } else {
      return (
        <div className="pull-up-element-2 p-mt-3">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  const participantUserFormView = () => {
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading user information..." />
          </div>
        );
      case 1:
        return (
          <div>
            <div>
              <CustomMessage
                closeModal={props.closeModal}
                close={true}
                message={successMessage}
                messageType="success"
              />
            </div>
          </div>
        );
      case 2:
        return <div>{formView()}</div>;
      default:
        return;
    }
  };

  const titleView = () => {
    if (props.isUpdate) {
      return "Update participant user";
    } else {
      return "New participant user";
    }
  };

  const subTitleView = () => {
    if (props.isUpdate) {
      return "Update the form below";
    } else {
      return "Fill the form below to create participant user";
    }
  };

  function validateMultipleSelect(e) {
    let value = e.target.value;
    if (value.length) {
      setParticipantUserError({ ...participantUserError, participantIds: "" });
      setParticipantUser({ ...participantUser, participantIds: value });
    } else {
      setParticipantUserError({
        ...participantUserError,
        participantIds: "Select participants",
      });
      setParticipantUser({ ...participantUser, participantIds: null });
    }
  }

  const formView = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">{titleView()}</div>
        <div className="custom-dialog-subtitle-container p-mb-5">
          <p className="custom-dialog-subtitle">{subTitleView()}</p>
          <p className="custom-dialog-subtitle">* Required Fields</p>
        </div>
        <div className="p-pb-1">{viewAlert()}</div>
        <div className="p-grid">
          <div className="p-col-12">
            <FormInput
              value={participantUser["username"]}
              required={true}
              field="username"
              type="NAME"
              error={participantUserError["username"]}
              fn={validateForm}
              loading={loading}
              placeholder="Username"
              verifying={isValidatingUsername}
            />
          </div>
          <div className="p-col-12 p-mt-3">
            <MultiSelect
              value={participantUser["participantIds"]}
              options={participants}
              onChange={validateMultipleSelect}
              display="chip"
              placeholder="Select participant(s) *"
              onShow={() => setShouldAddBottomPadding(true)}
              onHide={() => setShouldAddBottomPadding(false)}
            />
          </div>
          <div
            className={`p-col-12 ${
              shouldAddBottomPadding ? "buttons-wrapper" : ""
            }`}
          >
            <div className="p-mt-5">
              <div className="p-grid">
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {cancelButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="p-pb-2">{participantUserFormView()}</div>
    </div>
  );
}
