import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { API_SERVICE } from "api/service";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { DetailsBreakDown } from "components/elements/details-break-down/details-break-down";
import { CustomConfirmDialog } from "../../../components/elements/custom-confirm-dialog/custom-confirm-dialog";
import { Toast } from "primereact/toast";
import { OverlayPanel } from "primereact/overlaypanel";
import { HELPER } from "utils/helper";
import { CustomToast } from "components/elements/alert/custom-toast";
import { ParticipantUserForm } from "./participant-user-form";
import { UserManualViewerButton } from "components/elements/user-manual-viewer/user-manual-viewer-button";

import ErrorBoundary from "components/elements/error-boundary/error-boundary";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function ParticipantUsers() {
  const toast = useRef(null);
  const op = useRef(null);
  // const mainContext = useContext(MainContext);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [error, setError] = useState("");
  // const [search, setSearch] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [confirmText] = useState("");
  const [itemIdForDelete] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [breakDownTitle, setBreakDownTitle] = useState("");
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsError, setDetailsError] = useState(null);
  const [detailsId, setDetailsId] = useState(null);
  const [detailsMobile, setDetailsMobile] = useState(false);

  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [editedParticipantUser, setEditedParticipantUser] = useState(null);

  const tableHeaders = [
    { label: "Username", value: "username" },
    { label: "Status", value: "active" },
    { label: "Date Added", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];

  const authorities = [
    { label: "UPDATE", value: "bo_manage_participant_users" },
    { label: "VIEW", value: "bo_manage_participant_users" },
  ];

  const [details, setDetails] = useState([]);

  const [participantUsers, setParticipantUsers] = useState([]);
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  function deleteChargeType(e) {
    setModalLoading(true);
  }

  function reloadDetails() {
    viewParticipantUser(detailsId, detailsMobile);
  }

  const updateParticipantUser = useCallback((e, isMobile) => {
    const editedUser = {
      username: e?.username,
      participantIds: e?.settlementParticipants.map(
        (participant) => participant.participantId
      ),
    };
    setEditedParticipantUser(editedUser);
    openModal(1, isMobile);
  }, []);

  const viewParticipantUser = useCallback((e, isMobile) => {
    setDetailsError(null);
    setDetailsId(e);
    setDetailsMobile(isMobile);
    // setDetailsLoading(true);
    openModal(2, isMobile);
    let arr = [];
    arr.push({ label: "Username", value: e?.username });
    let participantsNameArr = e?.settlementParticipants.map(
      (participant) => participant.name
    );
    arr.push({
      label: "Participant(s)",
      value: participantsNameArr.join(", "),
    });
    arr.push({
      label: "Status",
      value: e?.active ? "Active" : "Inactive",
      itemCase: "active",
    });
    setDetails(arr);
    setBreakDownTitle("Participant User");
    setDetailsLoading(false);
  }, []);

  const openAction = useCallback(
    (e, action, isMobile) => {
      switch (action) {
        case "VIEW": {
          viewParticipantUser(e, isMobile);
          break;
        }
        case "UPDATE": {
          updateParticipantUser(e, isMobile);
          break;
        }
        default:
          break;
      }
    },
    [viewParticipantUser, updateParticipantUser]
  );

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  const getParticipantUsers = useCallback(() => {
    setCurrentIndex(0);
    setLoading(true);
    setParticipantUsers([]);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: 10,
    });
    const url = BACK_OFFICE_API.PARTICIPANT_USER.GET_PARTICIPANT_USERS;
    API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
      .then((data) => {
        const result = data.result.content;
        if (!result.length) {
          setEmptyText("No participant users found ...");
        } else {
          let arr = [];
          setTotalItems(data.result.totalElements);
          setTotalPages(data.result.totalPages);
          result.forEach((e) => {
            arr.push({
              ...e,
              active: e?.active ? "Active" : "Inactive",
              actions: "CRU",
              detailsFunction: openAction,
            });
          });
          setParticipantUsers(arr);
        }
        setError(null);
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  }, [pageNo, openAction]);

  useEffect(() => {
    getParticipantUsers();
  }, [getParticipantUsers]);

  function onHide() {}

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }

  function showResetPasswordModal() {
    setCurrentModalIndex(4);
  }

  function showChangeStatusModal() {
    setCurrentModalIndex(5);
  }

  function reload(resetPageNo = true) {
    if (resetPageNo) {
      setPageNo(0);
    }
    setLoading(true);
    setIsSearch(false);
    setCurrentIndex(0);
    getParticipantUsers();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload === true) {
      reload(false);
    }
  }

  const participantUsersView = () => {
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading participant users..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            authorities={authorities}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={isSearch}
            reload={reload}
            error={error}
            items={participantUsers}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const modalContent = () => {
    switch (currentModalIndex) {
      case 0:
        return <ParticipantUserForm closeModal={closeModal} />;
      case 1:
        return (
          <ParticipantUserForm
            closeModal={closeModal}
            editedParticipantUser={editedParticipantUser}
            isUpdate={true}
          />
        );
      case 2:
        return (
          <DetailsBreakDown
            reload={reloadDetails}
            error={detailsError}
            loading={detailsLoading}
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
            resetPassword={showResetPasswordModal}
            changeStatus={showChangeStatusModal}
          />
        );
      case 3:
        return (
          <CustomConfirmDialog
            itemId={itemIdForDelete}
            confirmText={confirmText}
            loading={modalLoading}
            loadingText="Deleting charge type..."
            fn={deleteChargeType}
            closeModal={closeModal}
          />
        );
      default:
        break;
    }
  };

  const mobileModal = () => {
    switch (currentModalIndex) {
      case 0:
        return <ParticipantUserForm />;
      case 1:
        return "";
      case 2:
        return (
          <DetailsBreakDown
            reload={reloadDetails}
            error={detailsError}
            loading={detailsLoading}
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
            isResetPassword={true}
            resetPassword={showResetPasswordModal}
            isChangeStatus={true}
            changeStatus={showChangeStatusModal}
            userStatus={detailsId.active}
          />
        );
      case 3:
        return (
          <CustomConfirmDialog
            itemId={itemIdForDelete}
            confirmText={confirmText}
            loading={modalLoading}
            loadingText="Deleting charge type..."
            fn={deleteChargeType}
            closeModal={closeModal}
          />
        );
      default:
        break;
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <div style={{ display: isPasswordReset ? "block" : "none" }}>
          <CustomToast
            title="Success"
            description="Password successfully reset!"
            type="success"
            clearToast={() => setIsPasswordReset(false)}
          />
        </div>
        {/* <div className="page-title p-text-left">Participant Users</div> */}
        <div className="page-header">
          <div className="page-title p-text-left">Participant Users</div>
          <UserManualViewerButton fileName="manage_users_participant_user" />
        </div>
        <div className="p-mt-2">
          <CustomBreadcrumb page={"Manage Participant users"} />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6 p-d-flex p-jc-end p-ai-center"></div>
                <div className="p-col-6">
                  <div
                    className={
                      HELPER.HAS_AUTHORITY("bo_manage_participant_users")
                        ? "dcir-show"
                        : "dcir-hide"
                    }
                  >
                    <button
                      disabled={loading}
                      onClick={() => openModal(0, false)}
                      className="primary-button hide-btn-text"
                    >
                      <i className="pi pi-plus" />
                      <span className="hide-btn-text"> New user </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-screen">
          <div className="p-grid">
            <div className="p-col-9"></div>
            <div className="p-col-3">
              <div className="floating-mobile-buttons add-cursor">
                <div
                  className={
                    HELPER.HAS_AUTHORITY("bo_dcir_create_merchant_users")
                      ? "dcir-hide"
                      : "dcir-hide"
                  }
                >
                  <i
                    onClick={(e) => op.current.toggle(e)}
                    className="pi pi-ellipsis-v"
                    style={{
                      fontSize: "1.5em",
                      color: "#464DF2",
                    }}
                  />
                  <OverlayPanel
                    ref={op}
                    id="overlay_panel"
                    style={{ width: "100px" }}
                    className="overlaypanel-demo"
                  >
                    <div className="p-mb-3 p-ml-1">
                      <span
                        onClick={() => openModal(0, true)}
                        className="custom-over-flow-text"
                      >
                        <i className="pi pi-plus" /> New
                      </span>
                    </div>
                    <div className="p-mb-2 p-ml-1">
                      <span
                        onClick={() => openModal(1, true)}
                        className="custom-over-flow-text"
                      >
                        <i className="pi pi-filter" /> Filter
                      </span>
                    </div>
                  </OverlayPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{participantUsersView()}</div>
      </div>
    </ErrorBoundary>
  );
}
