import { useEffect, useState } from "react";
import { BACK_OFFICE_API } from "api/routes/back-office/index";
import { useGlNameEnquiryOption } from "hooks/use-gl-name-enquiry-option";
import { useSingleAppConfigurationValue } from "hooks/use-single-configuration-value";
import { useGlAccountSize } from "hooks/useGlAccountSize";
import { API_SERVICE } from "api/service";
import { CustomMessage } from "components/elements/alert/custom-message";
import { CustomToast } from "components/elements/alert/custom-toast";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { FormDropdown } from "components/form/form-dropdown";
import { FormInput } from "components/form/form-input";
import { Optional } from "components/elements/optional/optional";
import { BACKOFFICE_CONFIGS } from "utils/constants";
import { HELPER } from "utils/helper";
import { CUSTOM_VALIDATION } from "utils/validation";
import { COMMON_API } from "../../../api/routes/common/index";

export function CreateTransitAccount(props) {
  const glAccountSize = useGlAccountSize();
  const isNameEnquiryEnabled = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.IS_NAME_ENQUIRY_ENABLED
  );
  const glNameEnquiryOption = useGlNameEnquiryOption();
  const [verifyingAccountNumber, setVerifyingAccountNumber] = useState(false);
  const [accountType, setAccountType] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [transitAccount, setTransitAccount] = useState({
    accountName: "",
    accountNumber: "",
  });
  const [formErrors, setFormErrors] = useState({
    accountNumber: null,
    accountName: null,
  });
  const accountTypes = ["GL", "NUBAN"];

  useEffect(() => {
    function validateForm() {
      const validErrorForm = !CUSTOM_VALIDATION.VALID_OBJ_ANY(formErrors);
      let validForm =
        transitAccount["accountName"].length &&
        transitAccount["accountNumber"].length;
      setIsValidForm(validForm && validErrorForm);
    }
    validateForm();
  }, [transitAccount, formErrors]);

  function createTransitAccount() {
    setLoading(true);
    setError(null);
    const url = BACK_OFFICE_API.TRANSIT_ACCOUNT.SETTLEMENT_TRANSIT_ACCOUNTS;
    API_SERVICE.MAKE_POST_REQUEST(url, transitAccount)
      .then((response) => {
        setShowSuccessMessage(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  function verifyAccountNumber(accNo) {
    setVerifyingAccountNumber(true);
    setFormErrors({
      ...formErrors,
      accountNumber: null,
    });
    const url = COMMON_API.ACCOUNT_ENQUIRY.VERIFY_ACCOUNT_NUMBER;
    API_SERVICE.MAKE_GET_REQUEST(`${url}/${accNo}`)
      .then((data) => {
        const accountName = data?.result?.accountName;
        setTransitAccount({
          ...transitAccount,
          accountNumber: accNo,
          accountName: accountName,
        });
        setVerifyingAccountNumber(false);
      })
      .catch((error) => {
        setFormErrors({
          ...formErrors,
          accountNumber: HELPER.PROCESS_ERROR(error, "NAME_ENQUIRY"),
        });
        setVerifyingAccountNumber(false);
      });
  }

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    setTransitAccount({ ...transitAccount, [name]: value });
    if (isValidInput) {
      setFormErrors({ ...formErrors, [name]: null });
      if (name === "accountNumber" && isNameEnquiryEnabled === "true") {
        verifyAccountNumber(value);
      }
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setFormErrors({
        ...formErrors,
        [name]: errorMessage,
      });
    }
  }

  function validateGlAccount(e, name, type, refineName, required) {
    const accountNo = e?.target?.value;
    const isValidAccountNo = glAccountSize
      ? accountNo?.length?.toString() === glAccountSize
      : true;
    if (isValidAccountNo) {
      if (isNameEnquiryEnabled === "true" && glNameEnquiryOption === "true") {
        verifyAccountNumber(accountNo, name);
      } else {
        setTransitAccount({ ...transitAccount, [name]: accountNo });
        setFormErrors({ ...formErrors, [name]: null });
      }
    } else {
      setFormErrors({
        ...formErrors,
        [name]: "GL account number is invalid",
      });
    }
  }

  function shouldDisableAccountNameField() {
    if (!accountType) {
      return true;
    }
    if (isNameEnquiryEnabled === "false") {
      return false;
    } else {
      if (accountType === "gl" && glNameEnquiryOption === "true") {
        return true;
      } else {
        return true;
      }
    }
  }

  const renderTransitAccountForm = () => {
    return (
      <div>
        <div>
          <div className="custom-modal-title p-text-left">
            New Settlement Transit Account
          </div>
          <div className="custom-dialog-subtitle-container p-mb-5">
            <p className="custom-dialog-subtitle">
              Fill form to create a settlement transit account
            </p>
            <p className="custom-dialog-subtitle">* Required Fields</p>
          </div>
        </div>
        <Optional show={error}>
          <div className="p-pb-1">
            <CustomToast
              title="Error"
              description={error}
              type="error"
              clearToast={() => setError(null)}
            />
          </div>
        </Optional>
        <div className="p-grid">
          <div className="p-col-12">
            <div className="p-mt-1">
              <FormDropdown
                required={true}
                disabled={false}
                value={accountType}
                fn={(e) => {
                  setAccountType(e?.target?.value);
                  setFormErrors({ accountNumber: null, accountName: null });
                  setTransitAccount({ accountName: "", accountNumber: "" });
                }}
                options={accountTypes}
                placeholder="Select account type *"
              />
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-mt-1">
              <FormInput
                verifyingField="account no"
                verifying={verifyingAccountNumber}
                value={transitAccount["accountNumber"]}
                required={true}
                field="accountNumber"
                type="NUBAN"
                error={formErrors["accountNumber"]}
                fn={
                  accountType && accountType === "GL"
                    ? validateGlAccount
                    : validateForm
                }
                disabled={!accountType}
                placeholder="Account no"
              />
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-mt-1">
              <FormInput
                value={transitAccount["accountName"]}
                required={
                  accountType &&
                  accountType === "GL" &&
                  glNameEnquiryOption === "false"
                }
                field="accountName"
                type="NAME"
                error={formErrors["accountName"]}
                fn={validateForm}
                disabled={
                  shouldDisableAccountNameField()
                  // accountType && accountType === "GL"
                  //   ? glNameEnquiryOption === "true"
                  //     ? true
                  //     : false
                  //   : true
                }
                placeholder="Account name"
              />
            </div>
          </div>
          <Optional show={!loading}>
            <div className="p-col-12 p-mt-5">
              <div className="p-grid">
                <div className="p-col-6">
                  <button
                    onClick={props.closeModal}
                    className="secondary-button"
                  >
                    Close
                  </button>
                </div>
                <div className="p-col-6">
                  <button
                    disabled={!isValidForm}
                    onClick={createTransitAccount}
                    className="primary-button"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Optional>
          <Optional show={loading}>
            <div className="p-col-12">
              <CustomLoader loadingText="Submitting..." />
            </div>
          </Optional>
        </div>
      </div>
    );
  };

  const transitAccountFormView = () => {
    if (showSuccessMessage) {
      return (
        <div>
          <div>
            <CustomMessage messageType="success" />
          </div>
          <div>
            <p className="success-message-text">
              Settlement transit account created successfully!
            </p>
          </div>
          <div className="success-message-btn-container p-text-center">
            <p
              onClick={() => props.closeModal(true)}
              className="close-modal add-cursor"
            >
              Close
            </p>
          </div>
        </div>
      );
    } else {
      return <>{renderTransitAccountForm()}</>;
    }
  };

  return <div>{transitAccountFormView()}</div>;
}
