import { useState } from "react";
import { API_SERVICE } from "api/service";
import { CustomMessage } from "components/elements/alert/custom-message";
import { CustomToast } from "components/elements/alert/custom-toast";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { Optional } from "components/elements/optional/optional";
import { HELPER } from "utils/helper";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function RequeryActionConfirmation(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [actionType, setActionType] = useState("SUCCESSFUL");
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function submit() {
    setLoading(true);
    let payload;
    const actionType = props.actionType;
    if (props.transactionType === "disputes") {
      payload = HELPER.TO_URL_STRING({
        disputeStatus: actionType,
      });
    } else {
      payload = HELPER.TO_URL_STRING({
        settlementStatus: actionType,
      });
    }
    const { UPDATE_DISPUTE_ON_REQUERY, UPDATE_SETTLEMENT_ON_REQUERY } =
      BACK_OFFICE_API.REQUERY_MANAGEMENT;
    const url =
      props.transactionType === "disputes"
        ? UPDATE_DISPUTE_ON_REQUERY
        : UPDATE_SETTLEMENT_ON_REQUERY;
    API_SERVICE.MAKE_PUT_REQUEST(`${url}/${props.transactionId}`, payload)
      .then((data) => {
        setResponseMessage(data?.result?.message);
        setLoading(false);
        setCurrentIndex(1);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }
  function requeryActionView() {
    switch (currentIndex) {
      case 0:
        return (
          <div className="p-text-left">
            <div>
              <h3>Confirm Action.</h3>
            </div>
            <div>
              <p>
                Confirm that you want to mark this transaction as{" "}
                {props.actionType.toLowerCase()}!
              </p>
            </div>
            <Optional show={error}>
              <div className="p-col-12">
                <CustomToast
                  title="Error"
                  description={error}
                  type="error"
                  clearToast={() => setError(null)}
                />
              </div>
            </Optional>
            <div className="p-col-12">
              <div className="p-mt-5">
                <div className="p-grid">
                  <Optional show={!loading}>
                    <div className={loading ? "p-col-12" : "p-col-6"}>
                      {/* {cancelButton()} */}
                      <button
                        className="secondary-button"
                        onClick={props.closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className={loading ? "p-col-12" : "p-col-6"}>
                      {/* {submitButton()} */}
                      <button className="primary-button" onClick={submit}>
                        Confirm
                      </button>
                    </div>
                  </Optional>
                  <Optional show={loading}>
                    <div className="p-col-12 p-text-center">
                      <CustomLoader loadingText="Loading..." />
                    </div>
                  </Optional>
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="p-text-center p-mt-2">
            <div>
              <CustomMessage messageType="success" />
            </div>
            <div className="success-message-btn-container p-text-center">
              <p>{responseMessage}</p>
              <p
                className="close-modal add-cursor p-mt-3"
                onClick={() => props.closeModal(true)}
              >
                Close
              </p>
            </div>
          </div>
        );
      default:
        break;
    }
  }
  return <div>{requeryActionView()}</div>;
}
