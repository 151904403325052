import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { API_SERVICE } from "api/service";
import "./charge-types.css";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { CreateChargeType } from "./create-charge-type";
import { DetailsBreakDown } from "components/elements/details-break-down/details-break-down";
// import { CustomConfirmationDialog } from "components/elements/confirmation-dialog/confirmation-dialog";
import { CustomConfirmDialog } from "components/elements/custom-confirm-dialog/custom-confirm-dialog";
import { Toast } from "primereact/toast";
import { HELPER } from "utils/helper";
import ErrorBoundary from "components/elements/error-boundary/error-boundary";
import { ChargeTypesFilter } from "./charge-types-filter";
import { UserManualViewerButton } from "components/elements/user-manual-viewer/user-manual-viewer-button";

import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function ChargeTypes() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [itemIdForDelete, setItemIdForDelete] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [toastError, setToastError] = useState(null);
  const [breakDownTitle, setBreakDownTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [chargeTypeForEdit, setChargeTypeForEdit] = useState(null);
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [viewChargeError, setViewChargeError] = useState(null);
  const tableHeaders = [
    { label: "Charge Type", value: "chargeType" },
    { label: "Charge Name", value: "chargeTypeName" },
    { label: "Flat", value: "flat" },
    { label: "Min Cap", value: "minimumCap" },
    { label: "Max Cap", value: "maximumCap" },
    { label: "Percent", value: "percentage" },
    { label: "Actions", value: "actions" },
  ];
  const chargeTypeAuthorities = [
    { label: "VIEW", value: "bo_dcir_create_charge_type" },
    { label: "DELETE", value: "bo_dcir_remove_charge_type" },
  ];
  const [details, setDetails] = useState([]);
  const [chargeTypes, setChargeTypes] = useState([]);
  const [pageNo, setPageNo] = useState(0);

  function deleteChargeType(id) {
    setModalLoading(true);
    const url = BACK_OFFICE_API.CHARGE_TYPE.DELETE_CHARGE_MODEL;
    API_SERVICE.MAKE_PUT_REQUEST(`${url}/${id}`)
      .then((data) => {
        setSuccessMessage(data?.result?.message);
        setModalLoading(false);
        // reload();
      })
      .catch((error) => {
        setModalLoading(false);
        // setToastError(HELPER.PROCESS_ERROR(error));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: HELPER.PROCESS_ERROR(error, "TOAST"),
          life: 10000,
        });
      });
  }

  const viewChargeType = useCallback((code, isMobile) => {
    setDetails([]);
    const url = BACK_OFFICE_API.CHARGE_TYPE.GET_CHARGE_MODEL_BY_CODE;
    API_SERVICE.MAKE_GET_REQUEST(`${url}/${code}`)
      .then((data) => {
        const e = data.result;
        let arr = [];
        arr.push({ label: "Name", value: e?.chargeTypeName });
        arr.push({ label: "Code", value: e?.code });
        arr.push({ label: "Description", value: e?.chargeTypeDesc });
        arr.push({ label: "flat", value: e?.flat, itemCase: "flat" });
        arr.push({ label: "Charge Type", value: e?.chargeType?.code });
        arr.push({
          label: "Min Cap",
          value: e?.minimumCap,
          itemCase: "minimumCap",
        });
        arr.push({
          label: "Max Cap",
          value: e?.maximumCap,
          itemCase: "maximumCap",
        });
        arr.push({ label: "Percent", value: e?.percentage });
        setDetails(arr);
        setBreakDownTitle("Charge Type");
        openModal(2, isMobile);
      })
      .catch((error) => {
        setViewChargeError(HELPER.PROCESS_ERROR(error));
      });
  }, []);

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          viewChargeType(e?.code, isMobile);
          break;
        }
        case "DELETE":
          // setModalLoadingText("Deleting charge type...");
          setItemIdForDelete(e?.code); //remember to change to chargeCode
          setConfirmText(`${e?.chargeTypeName?.toString()?.toLowerCase()}`);
          openModal(3, isMobile);
          break;
        case "UPDATE":
          const chargeModel = {
            chargeType: e?.chargeType,
            minCap: e?.minimumCap,
            maxCap: e?.maximumCap,
            percent: e?.percentage,
            flat: e?.flat,
            chargeName: e?.chargeTypeName,
            chargeCode: e?.code,
            chargeTypeDesc: e?.chargeTypeDesc,
            id: e?.id,
          };
          setChargeTypeForEdit(chargeModel);
          openModal(4, isMobile);
      }
    },
    [viewChargeType]
  );

  const getChargeTypes = useCallback(() => {
    setCurrentIndex(0);
    setChargeTypes([]);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: 10,
    });
    const url = BACK_OFFICE_API.CHARGE_TYPE.GET_CHARGE_TYPES;
    API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
      .then((data) => {
        const result = data?.result?.content;
        if (!result?.length) {
          setEmptyText("No charge types found ...");
        } else {
          let arr = [];
          setTotalItems(data?.result.totalElements);
          setTotalPages(data?.result.totalPages);
          result?.forEach((e) => {
            arr.push({
              ...e,
              chargeType: e.chargeType.code,
              actions: "RD",
              detailsFunction: openAction,
            });
          });
          setChargeTypes(arr);
        }
        setError(null);
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  }, [openAction, pageNo]);

  const getChargeTypeByCode = (payload) => {
    setSearch(true);
    setVisible(false);
    setCurrentIndex(0);
    setChargeTypes([]);
    const url = BACK_OFFICE_API.CHARGE_TYPE.GET_CHARGE_MODEL_BY_CODE;
    API_SERVICE.MAKE_GET_REQUEST(`${url}/${payload.chargeCode}`)
      .then((data) => {
        const result = data?.result;
        if (!result) {
          setEmptyText("No charge type found!");
        } else {
          let arr = [];
          arr.push({
            ...result,
            chargeType: result?.chargeType?.code,
            actions: "CRD",
            detailsFunction: openAction,
          });
          setChargeTypes(arr);
        }
        setError(null);
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  };

  function onHide() {}

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }

  useEffect(() => {
    getChargeTypes();
  }, [getChargeTypes]);

  function reload() {
    setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    getChargeTypes();
  }

  function closeModal(isReload) {
    setToastError(null);
    setSuccessMessage(null);
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload) {
      reload();
    }
  }

  function goToPage(page) {
    setPageNo(page);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return <CreateChargeType closeModal={closeModal} />;
      case 2:
        return (
          <div>
            <DetailsBreakDown
              title={breakDownTitle}
              breakDown={details}
              closeModal={closeModal}
              error={viewChargeError}
            />
          </div>
        );
      case 3:
        return (
          <CustomConfirmDialog
            success={successMessage}
            toastError={toastError}
            clearToastError={() => setToastError(null)}
            itemId={itemIdForDelete}
            confirmText={confirmText}
            loading={modalLoading}
            loadingText="Deleting charge type..."
            fn={deleteChargeType}
            closeModal={closeModal}
          />
        );
      case 4:
        return (
          <CreateChargeType
            editChargeType={chargeTypeForEdit}
            isUpdate={true}
            closeModal={closeModal}
          />
        );
    }
  };

  const chargeTypeView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading charge types..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            authorities={chargeTypeAuthorities}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={chargeTypes}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return <CreateChargeType closeModal={closeModal} />;
      case 2:
        return (
          <DetailsBreakDown
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
            error={viewChargeError}
          />
        );
      case 3:
        return (
          <CustomConfirmDialog
            success={successMessage}
            toastError={toastError}
            clearToastError={() => setToastError(null)}
            itemId={itemIdForDelete}
            confirmText={confirmText}
            loading={modalLoading}
            loadingText="Deleting charge type..."
            fn={deleteChargeType}
            closeModal={closeModal}
          />
        );
      case 4:
        return (
          <CreateChargeType
            editChargeType={chargeTypeForEdit}
            isUpdate={true}
            closeModal={closeModal}
          />
        );
      case 5:
        return (
          <ChargeTypesFilter
            closeModal={closeModal}
            searchFunction={getChargeTypeByCode}
          />
        );
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <div className="page-header p-text-left">
          <div className="page-title">Charge Types</div>
          <UserManualViewerButton fileName="chargetype" />
        </div>
        <div className="p-mt-2">
          <CustomBreadcrumb page={"Manage Charge Types"} />
        </div>

        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6 p-d-flex p-jc-end p-ai-center">
                  <button
                    disabled={loading}
                    onClick={() => openModal(5, false)}
                    className="filter-button"
                  >
                    <i className="pi pi-filter" />
                    <span className="hide-btn-text"> Filter</span>
                  </button>
                </div>
                <div className="p-col-6">
                  <div
                    className={
                      HELPER.HAS_AUTHORITY("bo_dcir_create_charge_type")
                        ? "dcir-show"
                        : "dcir-hide"
                    }
                  >
                    <button
                      disabled={loading}
                      onClick={() => openModal(0, false)}
                      className="primary-button hide-btn-text"
                    >
                      <i className="pi pi-plus" />
                      <span className="hide-btn-text"> New charge type </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{chargeTypeView()}</div>
      </div>
    </ErrorBoundary>
  );
}
