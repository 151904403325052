import React, { useState } from "react";
import { API_SERVICE } from "api/service";
import { FormInput } from "components/form/form-input";
import { CustomMessage } from "components/elements/alert/custom-message";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomToast } from "components/elements/alert/custom-toast";
import { HELPER } from "utils/helper";
import { COMMON_API } from "../../../api/routes/common/index";

export function ChangeUserStatus(props) {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [message, setMessage] = useState(null);
  const [messageTitle, setMessageTitle] = useState(null);

  function changeUserStatus() {
    setLoading(true);
    const payload = {
      active: props.userStatus === "Active" ? false : true,
      username: props.username,
    };
    const url = COMMON_API.AUTH.CHANGE_BACK_OFFICE_USER_STATUS;
    API_SERVICE.MAKE_PUT_REQUEST(url, payload)
      .then((data) => {
        setSuccessMessage(
          `${props.username} has been ${
            props.userStatus === "Active" ? "disabled" : "enabled"
          }.`
        );
        setShowSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setMessageTitle("Error");
        setMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  const actions = () => {
    if (loading) {
      return (
        <div>
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else if (!loading && !showSuccess) {
      return (
        <div className="p-mt-4 p-pb-2">
          <div className="p-grid">
            <div className="p-col-6">
              <button onClick={props.cancel} className="secondary-button">
                Cancel
              </button>
            </div>
            <div className="p-col-6">
              <button
                disabled={username !== props.username}
                onClick={changeUserStatus}
                className="primary-button"
              >
                {props.userStatus === "Active" ? "Disable" : "Enable"}
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  const alertView = () => {
    if (messageTitle) {
      return (
        <div>
          <CustomToast
            title={messageTitle}
            description={message}
            type="error"
            clearToast={() => setMessageTitle(null)}
          />
        </div>
      );
    } else {
      return <div />;
    }
  };

  const changeStatusView = () => {
    if (showSuccess) {
      return (
        <div>
          <CustomMessage
            closeModal={props.closeModal}
            close={true}
            message={successMessage}
            messageType="success"
          />
        </div>
      );
    } else {
      return (
        <div>
          <p className="p-mb-4 p-mt-0 confirm-text">
            Confirm that you want to{" "}
            {props.userStatus === "Active" ? "disable" : "enable"} this user?
          </p>
          <div>
            <p className="p-text-left">
              To confirm, type <b>{props.username}</b> in the text input field
            </p>
            <FormInput
              value={username}
              required={true}
              field="reset"
              type="INPUT"
              fn={(e) => setUsername(e?.target?.value)}
              loading={loading}
              placeholder="Enter username"
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="p-my-3">
      <div className="p-pb-1">{alertView()}</div>
      <div>{changeStatusView()}</div>
      <div>{actions()}</div>
    </div>
  );
}
