import { HELPER } from "../../../utils/helper";
import { ConditionalRenderer } from "../conditional-renderer/conditional-renderer";
import { TableActions } from "./table-actions";
import { Divider } from "primereact/divider";

export function TableMobileView({
  headers,
  items,
  actions,
  authorities,
  performAction,
}) {
  const mobileTableContent = (item) => {
    return headers.map((header) => {
      return (
        <div key={`${header.label}`} className="p-grid">
          <div className="p-col-12">
            <span className="p-grid">
              <span className="p-col-4">
                <span className="mobile-table-label">{header.label}:</span>;
              </span>
              <span className="p-col-8">
                <ConditionalRenderer renderIf={header.value === "actions"}>
                  <TableActions
                    data={item}
                    authorities={authorities}
                    performAction={performAction}
                  />
                </ConditionalRenderer>
                <ConditionalRenderer renderIf={header.value !== "actions"}>
                  <span className="mobile-table-value">
                    {HELPER.TRANSFORM_VIEW(header.value, item[header.value])}
                  </span>
                </ConditionalRenderer>
              </span>
            </span>
          </div>
        </div>
      );
    });
  };
  const renderView = () => {
    return items.map((item, index) => {
      return (
        <div key={index.toString()}>
          {mobileTableContent(item)}
          <div>
            <Divider />
          </div>
        </div>
      );
    });
  };

  return <>{renderView()}</>;
}
