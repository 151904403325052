import { useState, useEffect, memo } from "react";
import "./privacy-policy.css";
import { CustomModal } from "../custom-modal/custom-modal";
import { STORAGE } from "utils/storage";
import { CONFIG } from "config/config";
const PrivacyPolicy = memo(() => {
  const [open, setOpen] = useState(true);
  const hidePrivacyPolicy = !open || !CONFIG.BANK_PRIVACY_POLICY_URL;
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const handleOk = () => {
    STORAGE.SET_SHOW_PRIVACY_POLICY(!dontShowAgain);
    setOpen(false);
  };
  useEffect(() => {
    const showPrivacyPolicy = STORAGE.GET_SHOW_PRIVACY_POLICY();
    if (showPrivacyPolicy) {
      try {
        const temp = JSON.parse(showPrivacyPolicy);
        setOpen(temp);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const modalContent = () => (
    <div className="privacy">
      <h2 className="privacy-title">
        Protecting your privacy is important to us
      </h2>
      <p className="privacy-text">
        This Privacy Notice applies to our web portal and its associated
        subdomains (collectively, our "Service"). By accessing or using our
        Service, you consent and signify that you have read, understood, and
        agreed to our data collection practices described in our{" "}
        <a href={CONFIG.BANK_PRIVACY_POLICY_URL} target="_blank">
          Privacy Policy
        </a>
      </p>
      <p className="privacy-text">
        By clicking "OK" you agree this portal can collect data to be used for
        the operation of the website, maintain qaulity of service and provide
        general statistics regarding the website
      </p>
      <div className="privacy-actions">
        <div className="privacy-checkbox">
          <input
            id="dont-show-again"
            type="checkbox"
            className="confirmation-checkbox"
            value={dontShowAgain}
            onChange={() => setDontShowAgain(!dontShowAgain)}
          />
          <label htmlFor="dont-show-again">Don't show again </label>
        </div>

        <button onClick={handleOk} className="primary-button privacy-button">
          OK
        </button>
      </div>
    </div>
  );

  if (hidePrivacyPolicy) return null;
  return (
    <CustomModal
      onHide={() => {}}
      visible={open}
      modalContent={modalContent}
      closeModal={() => {}}
    />
  );
});

export default PrivacyPolicy;
