import { useState, useEffect, memo } from "react";
import "./cookie-consent.css";
import { STORAGE } from "utils/storage";
import { CONFIG } from "config/config";
const CookieConsent = memo(() => {
  const hideCookieConsent = !CONFIG.BANK_PRIVACY_POLICY_URL;
  const [cookieConsent, setCookieConsent] = useState("accepted");

  useEffect(() => {
    const cookieConsent = STORAGE.GET_COOKIE_CONSENT();
    setCookieConsent(cookieConsent);
  }, []);

  const acceptCookies = () => {
    STORAGE.SET_COOKIE_CONSENT("accepted");
    setCookieConsent("accepted");
  };

  const rejectCookies = () => {
    STORAGE.SET_COOKIE_CONSENT("rejected");
    setCookieConsent("rejected");
  };

  if (hideCookieConsent) return null;
  return (
    <>
      {cookieConsent !== "accepted" && cookieConsent !== "rejected" && (
        <div className="cookie">
          <h4 className="cookie-heading">We Use Cookies!</h4>
          <p className="cookie-text">
            This web portal uses cookies to ensure necessary funtionality,
            <br /> Improve your experience and analyze our traffic. By
            continuing into <br /> the website, you have given consent to our
            use of cookies. For more <br /> information read our{" "}
            <a href={CONFIG.BANK_PRIVACY_POLICY_URL} target="_blank">
              Privacy Policy
            </a>
          </p>
          <div className="cookie-actions">
            <button onClick={() => acceptCookies()} className="primary-button ">
              Accept all
            </button>
            <button
              onClick={() => rejectCookies()}
              className="secondary-button"
            >
              Reject all
            </button>
          </div>
        </div>
      )}
    </>
  );
});

export default CookieConsent;
