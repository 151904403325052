import * as webStorage from "./webStorage";

const tokenStorageName = "dcir_bo_yhb84sabfy11";
const userNameStorageName = "dcir_bo_yhb23szbrb12";
const isLoggedInStorageName = "dcir_bo_lgb84nemjy15";
const expire_inStorageName = "dcir_bo_exb84inekm61";
const currentPageStorageName = "dcir_bo_cpb84snarm19";
const rolesStorageName = "dcir_bo_rub64slesrk17";
const authoritiesStorageName = "dcir_bo_aub84sriesrhor08";
const nameStorageName = "dcir_bo_rob84slesrm17";
const firstNameStorageName = "dcir_bo_firb84srore18";
const lastNameStorageName = "dcir_bo_lnb84slagerm23";
const currentPageIndex = "dcir_bo_pid14sleverm10";
const merchantIdStorageName = "dcir_bo_pid14rdevrrm10";
const merchantNameStorageName = "dcir_bo_hyXvgsuTTU123";
const userEmail = "dcir_bo_jsu8w_owi82929";
const twoFaKey = "xisipw_jTku_orVzs";
const monitoringToken = "mon_19djn4_iqhdbeu79";
const monitoringLogin = "mon_1d563hu82_brbtuqbw";
const userType = "dcir_u23r1y93";
const cookieConsent = "dcir_c12940_okldncw";
const showPrivacyPolicy = "dcir_901kfdrw52_pjprw";

const LOCAL_STORAGE = {
  TOKEN: `${tokenStorageName}`,
  USER_NAME: `${userNameStorageName}`,
  IS_LOGGED_IN: `${isLoggedInStorageName}`,
  EXPIRE_IN: `${expire_inStorageName}`,
  CURRENT_PAGE_STORAGE_NAME: `${currentPageStorageName}`,
  ROLES: `${rolesStorageName}`,
  NAME: `${nameStorageName}`,
  FIRST_NAME: `${firstNameStorageName}`,
  LAST_NAME: `${lastNameStorageName}`,
  AUTHORITIES: `${authoritiesStorageName}`,
  CURRENT_PAGE_INDEX: `${currentPageIndex}`,
  MERCHANT_ID: merchantIdStorageName,
  MERCHANT_NAME: merchantNameStorageName,
  USER_EMAIL: userEmail,
  TWO_FA_KEY: twoFaKey,
  MONITORING_TOKEN: monitoringToken,
  MONITORING_LOGIN: monitoringLogin,
  USER_TYPE: userType,
  COOKIE_CONSENT: cookieConsent,
  SHOW_PRIVACY_POLICY: showPrivacyPolicy,
};

function saveUserCredentials(loginCredentials, isMonitoringLogin) {
  webStorage.setItem(
    LOCAL_STORAGE.AUTHORITIES,
    JSON.stringify(loginCredentials?.authorities)
  );
  if (isMonitoringLogin) {
    webStorage.setItem(
      LOCAL_STORAGE.MONITORING_TOKEN,
      loginCredentials?.access_token
    );
  } else {
    webStorage.setItem(LOCAL_STORAGE.TOKEN, loginCredentials?.access_token);
  }
  webStorage.setItem(
    LOCAL_STORAGE.CURRENT_PAGE_STORAGE_NAME,
    loginCredentials?.selectedSideNav
  );
  webStorage.setItem(LOCAL_STORAGE.USER_TYPE, loginCredentials?.userType);
  webStorage.setItem(
    LOCAL_STORAGE.IS_LOGGED_IN,
    JSON.stringify(loginCredentials.isAuthenticated)
  );
  webStorage.setItem(LOCAL_STORAGE.USER_NAME, loginCredentials.username);
  webStorage.setItem(
    LOCAL_STORAGE.EXPIRE_IN,
    JSON.stringify(loginCredentials.expires_in)
  );
  webStorage.setItem(LOCAL_STORAGE.FIRST_NAME, loginCredentials.firstName);
  webStorage.setItem(LOCAL_STORAGE.LAST_NAME, loginCredentials.lastName);
  webStorage.setItem(
    LOCAL_STORAGE.ROLES,
    JSON.stringify(loginCredentials.roles)
  );
  webStorage.setItem(LOCAL_STORAGE.NAME, loginCredentials.name);
  webStorage.setItem(
    LOCAL_STORAGE.CURRENT_PAGE_INDEX,
    loginCredentials.selectedSideNavIndex.toString()
  );
  webStorage.setItem(
    LOCAL_STORAGE.CURRENT_PAGE_STORAGE_NAME,
    loginCredentials.selectedSideNav
  );
  webStorage.setItem(LOCAL_STORAGE.MERCHANT_ID, loginCredentials.merchantId);
  webStorage.setItem(
    LOCAL_STORAGE.MERCHANT_NAME,
    loginCredentials.merchantName
  );
  webStorage.setItem(LOCAL_STORAGE.USER_EMAIL, loginCredentials.email);

  if (isMonitoringLogin) {
    webStorage.setItem(LOCAL_STORAGE.MONITORING_LOGIN, "true");
  }
}

function getUserCredentials() {
  let authorities = webStorage.getItem(LOCAL_STORAGE.AUTHORITIES);
  authorities =
    authorities && authorities !== "undefined" ? JSON.parse(authorities) : [];
  let roles = webStorage.getItem(LOCAL_STORAGE.ROLES);
  roles = roles && roles !== "undefined" ? JSON.parse(roles) : [];
  const currentPageIndex =
    webStorage.getItem(LOCAL_STORAGE.CURRENT_PAGE_INDEX) || "0";
  return {
    username: webStorage.getItem(LOCAL_STORAGE.USER_NAME),
    selectedSideNav: webStorage.getItem(
      LOCAL_STORAGE.CURRENT_PAGE_STORAGE_NAME
    ),
    isAuthenticated: webStorage.getItem(LOCAL_STORAGE.IS_LOGGED_IN),
    access_token: webStorage.getItem(LOCAL_STORAGE.TOKEN),
    authorities: authorities,
    expires_in: Number(webStorage.getItem(LOCAL_STORAGE.EXPIRE_IN)),
    firstName: webStorage.getItem(LOCAL_STORAGE.FIRST_NAME),
    lastName: webStorage.getItem(LOCAL_STORAGE.LAST_NAME),
    name: webStorage.getItem(LOCAL_STORAGE.NAME),
    selectedSideNavIndex: parseInt(currentPageIndex),
    roles: roles,
    merchantId: webStorage.getItem(LOCAL_STORAGE.MERCHANT_ID),
  };
}

function isLoggedIn() {
  const loginStatus = webStorage.getItem(LOCAL_STORAGE.IS_LOGGED_IN);
  return loginStatus && loginStatus === "true";
}

function isMonitoringLogin() {
  const loginStatus = webStorage.getItem(LOCAL_STORAGE.MONITORING_LOGIN);
  return loginStatus && loginStatus === "true";
}

function isTokenExpire() {
  const expire_in = Number(webStorage.getItem(LOCAL_STORAGE.EXPIRE_IN));
  return expire_in;
}

function getToken() {
  return webStorage.getItem(LOCAL_STORAGE.TOKEN);
}

function getMonitoringToken() {
  const token = webStorage.getItem(LOCAL_STORAGE.MONITORING_TOKEN);
  return token;
}

function storeCurrentPage(page, navId) {
  webStorage.setItem(LOCAL_STORAGE.CURRENT_PAGE_STORAGE_NAME, page);
  webStorage.setItem(LOCAL_STORAGE.CURRENT_PAGE_INDEX, navId);
}

function getRoles() {
  const roles = webStorage.getItem(LOCAL_STORAGE.ROLES);
  if (!roles || roles === "undefined") {
    return [];
  }
  return JSON.parse(roles);
}

function getCurrentSideNav() {
  return {
    selectedSideNav: webStorage.getItem(
      LOCAL_STORAGE.CURRENT_PAGE_STORAGE_NAME
    ),
    selectedSideNavIndex: Number(
      webStorage.getItem(LOCAL_STORAGE.CURRENT_PAGE_INDEX)
    ),
  };
}

function getAuthorities() {
  const authorities = webStorage.getItem(LOCAL_STORAGE.AUTHORITIES);
  if (!authorities || authorities === "undefined") {
    return [];
  }
  return JSON.parse(webStorage.getItem(LOCAL_STORAGE.AUTHORITIES));
}

function getUsername() {
  return webStorage.getItem(LOCAL_STORAGE.USER_NAME);
}
function getUserType() {
  return webStorage.getItem(LOCAL_STORAGE.USER_TYPE);
}

function getMerchantName() {
  return webStorage.getItem(LOCAL_STORAGE.MERCHANT_NAME);
}

function getUserEmail() {
  return webStorage.getItem(LOCAL_STORAGE.USER_EMAIL);
}

function setCookieConsent(consent) {
  return webStorage.setItem(LOCAL_STORAGE.COOKIE_CONSENT, consent);
}

function getCookieConsent() {
  return webStorage.getItem(LOCAL_STORAGE.COOKIE_CONSENT);
}

function setShowPrivacyPolicy(showPrivacyPolicy) {
  return webStorage.setItem(
    LOCAL_STORAGE.SHOW_PRIVACY_POLICY,
    showPrivacyPolicy
  );
}

function getShowPrivacyPolicy() {
  return webStorage.getItem(LOCAL_STORAGE.SHOW_PRIVACY_POLICY);
}

function clearStorage() {
  Object.entries(LOCAL_STORAGE).forEach(([k, v]) => {
    if (
      v !== LOCAL_STORAGE.SHOW_PRIVACY_POLICY &&
      v !== LOCAL_STORAGE.COOKIE_CONSENT
    )
      webStorage.removeItem(v);
  });
}

export const STORAGE = {
  GET_TOKEN: getToken,
  GET_MONITORING_TOKEN: getMonitoringToken,
  SAVE_USER_CREDENTIAL: saveUserCredentials,
  GET_USER_CREDENTIALS: getUserCredentials,
  IS_LOGGED_IN: isLoggedIn,
  CLEAR_STORAGE: clearStorage,
  IS_TOKEN_EXPIRE: isTokenExpire,
  GET_ROLES: getRoles,
  GET_AUTHORITIES: getAuthorities,
  STORE_CURRENT_PAGE: storeCurrentPage,
  GET_CURRENT_NAV: getCurrentSideNav,
  GET_USERNAME: getUsername,
  getUserType,
  GET_MERCHANT_NAME: getMerchantName,
  GET_USER_EMAIL: getUserEmail,
  IS_MONITORING_LOGIN: isMonitoringLogin,
  GET_COOKIE_CONSENT: getCookieConsent,
  SET_COOKIE_CONSENT: setCookieConsent,
  GET_SHOW_PRIVACY_POLICY: getShowPrivacyPolicy,
  SET_SHOW_PRIVACY_POLICY: setShowPrivacyPolicy,
};
