// import { HELPER } from "../helper/helper";
// import { HELPER } from "./helper";
import { HELPER } from "../helper";

export const SpecialLabelCases = [
  { case: "transactionAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "transactionMerchantAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "transactionChargeAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "transactionTotalAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "amount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "flat", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "minimumCap", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "maximumCap", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "theDate", action: HELPER.FORMAT_DATE },
  { case: "transactionTime", action: HELPER.FORMAT_DATE },
  { case: "settlementAttemptDateTime", action: HELPER.FORMAT_DATE },
  { case: "reportTime", action: HELPER.FORMAT_DATE },
  { case: "transactionDate", action: HELPER.FORMAT_DATE },
  { case: "tatExpiryDate", action: HELPER.FORMAT_DATE },
  { case: "chargeAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "requestTime", action: HELPER.FORMAT_DATE },
  { case: "logTime", action: HELPER.FORMAT_DATE },
  { case: "settlementTime", action: HELPER.FORMAT_DATE },
  { case: "responseCode", action: HELPER.RESPONSE_CODE_COLOR },
  { case: "resolutionStatus", action: HELPER.RESPONSE_STATUS },
  { case: "creationStatus", action: HELPER.RESPONSE_STATUS },
  { case: "disputeCreationStatus", action: HELPER.RESPONSE_STATUS },
  { case: "reportGenerationStatus", action: HELPER.RESPONSE_STATUS },
  { case: "status", action: HELPER.RESPONSE_STATUS },
  { case: "processStatus", action: HELPER.RESPONSE_STATUS },
  { case: "formattedStatus", action: HELPER.RESPONSE_STATUS },
  { case: "createdOn", action: HELPER.FORMAT_DATE },
  { case: "uploadedAt", action: HELPER.FORMAT_DATE },
  { case: "active", action: HELPER.RESPONSE_STATUS },
  { case: "pending", action: HELPER.RESPONSE_STATUS },
  { case: "failed", action: HELPER.RESPONSE_STATUS },
  { case: "createdAt", action: HELPER.FORMAT_DATE },
  { case: "completedAt", action: HELPER.FORMAT_DATE },
  { case: "createdTime", action: HELPER.FORMAT_DATE },
  { case: "completedTime", action: HELPER.FORMAT_DATE },
  { case: "creationTime", action: HELPER.FORMAT_DATE },
  { case: "percentageProcessed", action: HELPER.PERCENTAGE_STATUS },
  { case: "retentionStatus", action: HELPER.RESPONSE_STATUS },
  { case: "passwordStatus", action: HELPER.RESPONSE_STATUS },
  { case: "requestStatus", action: HELPER.RESPONSE_STATUS },
  { case: "attemptTime", action: HELPER.FORMAT_DATE },
  { case: "uploadTotalCount", action: HELPER.RESPONSE_STATUS },
  { case: "successCount", action: HELPER.RESPONSE_STATUS },
  { case: "failedCount", action: HELPER.RESPONSE_STATUS },
  { case: "transactionType", action: HELPER.RESPONSE_STATUS },
  { case: "lastModifiedOn", action: HELPER.FORMAT_DATE },
];

const tokenStorageName = "dcir_bo_yhb84sabfy11";
const userNameStorageName = "dcir_bo_yhb23szbrb12";
const isLoginStorageName = "dcir_bo_lgb84nemjy15";
const expire_inStorageName = "dcir_bo_exb84inekm61";
const currentPageStorageName = "dcir_bo_cpb84snarm19";
const rolesStorageName = "dcir_bo_rub64slesrk17";
const authoritiesStorageName = "dcir_bo_aub84sriesrhor08";
const nameStorageName = "dcir_bo_rob84slesrm17";
const firstNameStorageName = "dcir_bo_firb84srore18";
const lastNameStorageName = "dcir_bo_lnb84slagerm23";
const currentPageIndex = "dcir_bo_pid14sleverm10";
const merchantIdStorageName = "dcir_bo_pid14rdevrrm10";
const merchantNameStorageName = "dcir_bo_hyXvgsuTTU123";
const userEmail = "dcir_bo_jsu8w_owi82929";
const twoFaKey = "xisipw_jTku_orVzs";
const monitoringToken = "mon_19djn4_iqhdbeu79";
const isMonitoringLogin = "mon_1d563hu82_brbtuqbw";

export const LOCAL_STORAGE = {
  TOKEN: `${tokenStorageName}`,
  USER_NAME: `${userNameStorageName}`,
  IS_LOGIN: `${isLoginStorageName}`,
  EXPIRE_IN: `${expire_inStorageName}`,
  CURRENT_PAGE_STORAGE_NAME: `${currentPageStorageName}`,
  ROLES: `${rolesStorageName}`,
  NAME: `${nameStorageName}`,
  FIRST_NAME: `${firstNameStorageName}`,
  LAST_NAME: `${lastNameStorageName}`,
  AUTHORITIES: `${authoritiesStorageName}`,
  CURRENT_PAGE_INDEX: `${currentPageIndex}`,
  MERCHANT_ID: merchantIdStorageName,
  MERCHANT_NAME: merchantNameStorageName,
  USER_EMAIL: userEmail,
  TWO_FA_KEY: twoFaKey,
  MONITORING_TOKEN: monitoringToken,
  IS_MONITORING_LOGIN: isMonitoringLogin,
};
