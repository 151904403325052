import { CONFIG } from "config/config";
import "./footer.css";
const Footer = () => {
  const hideFooter = !CONFIG.BANK_TERMS_URL || !CONFIG.BANK_PRIVACY_POLICY_URL;

  if (hideFooter) return null;
  return (
    <footer className="footer">
      <a href={CONFIG.BANK_TERMS_URL} target="_blank">
        Terms & Conditions
      </a>
      <a href={CONFIG.BANK_PRIVACY_POLICY_URL} target="_blank">
        Privacy Policy
      </a>
    </footer>
  );
};

export default Footer;
