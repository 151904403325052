// import { CustomCard } from "../../../components/elements/custom-card/custom-card";
// import { AppMeter } from "./meter";

export function ServerMemoryMetrics({ server }) {
  return (
    <div className="p-grid memory-metrics">
      <div className="p-col-6">
        {/* <CustomCard> */}
        <p className="text-small p-mb-1">Free Disk Space</p>
        <h2 className="p-mt-2 p-mb-1">{server.freeMemory}</h2>
        {/* <AppMeter percent="40%" /> */}
        {/* </CustomCard> */}
      </div>
      <div className="p-col-6">
        {/* <CustomCard> */}
        <p className="text-small p-mb-1">Free RAM</p>
        <h2 className="p-mt-2 p-mb-1">{server.freeRam}</h2>
        {/* <AppMeter percent="75%" /> */}

        {/* </CustomCard> */}
      </div>
      <div className="p-col-6">
        {/* <CustomCard> */}
        <p className="text-small p-mb-1">Total Disk Space</p>
        <pre className="text-small p-mt-2 p-mb-1">{server.totalMemory}</pre>
        {/* </CustomCard> */}
      </div>
      <div className="p-col-6">
        {/* <CustomCard> */}
        <p className="text-small p-mb-1">Total RAM</p>
        <pre className="text-small p-mt-2 p-mb-1">{server.totalRam}</pre>
        {/* <AppMeter percent="75%" /> */}

        {/* </CustomCard> */}
      </div>
      <div className="p-col-6">
        {/* <CustomCard> */}
        <p className="text-small p-mb-1">Up Time</p>
        <pre className="p-mt-2 p-mb-1">{server.upTime}</pre>
        {/* <AppMeter percent="75%" /> */}

        {/* </CustomCard> */}
      </div>{" "}
      <div className="p-col-6">
        {/* <CustomCard> */}
        <p className="text-small p-mb-1">CPU</p>
        <pre className="p-mt-2 p-mb-1">{server.cpu}</pre>
        {/* <AppMeter percent="75%" /> */}

        {/* </CustomCard> */}
      </div>
      <div className="p-col-6">
        <p className="text-small p-mb-1">CPU Utilization</p>
        <pre className="p-mt-2 p-mb-1">
          {server.cpuUtilization !== null && server.cpuUtilization !== undefined
            ? server.cpuUtilization.toFixed(2) + "%"
            : "NA"}
        </pre>
      </div>
    </div>
  );
}
