import { useCallback, useEffect, useRef, useState } from "react";
import { MONITORING_SERVICE } from "../../../api/services/monitoring-service";
import { CustomCard } from "../../../components/elements/custom-card/custom-card";
import "./dcir-services.css";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { HELPER } from "utils/helper";
import { Tooltip } from "primereact/tooltip";
import { createPortal } from "react-dom";
import { usePolling } from "hooks/use-polling";

const mockServices = [
  { serviceName: "Management", status: true, serviceId: "CRON" },
  { serviceName: "CRON", status: false, serviceId: "CRON" },
  { serviceName: "Management", status: true, serviceId: "Management" },
  { serviceName: "CRON", status: false, serviceId: "CRON" },
  { serviceName: "CRON", status: false, serviceId: "CRON" },
  // { name: "CRON", status: false },
];

export function ServicesHealthStatus() {
  const timeouts = useRef([]);
  const startingServices = useRef([]);
  const restartingServices = useRef([]);

  const toast = useRef(null);
  const [services, setServices] = useState(mockServices);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [action, setAction] = useState("");

  const getServiceStatus = useCallback((hideLoader) => {
    if (!hideLoader) setLoading(true);
    MONITORING_SERVICE.MAKE_GET_REQUEST("/service/health/status")
      .then((data) => {
        setServices(data?.response);
        setLoading(false);
      })
      .catch((error) => {
        console.log("err", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getServiceStatus();
  }, [getServiceStatus]);

  usePolling(getServiceStatus, true);

  const handleSuccessResponse = (response) => {
    setActionLoading(false);
    closeModal();
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: response?.message,
      life: 10000,
    });
    reload();
    setSelectedService({});
  };

  const handleErrorResponse = (error) => {
    setActionLoading(false);
    closeModal();
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: HELPER.PROCESS_ERROR("toast", error),
      life: 10000,
    });
  };

  const handleYes = () => {
    switch (action) {
      case "restart":
        restartService();
        break;
      case "start":
        startService();
        break;
      case "stop":
        stopService();
        break;
      default:
        console.error("Invalid action");
        break;
    }
  };

  const updateStartingServices = (selectedService) => {
    startingServices.current = [...startingServices.current, selectedService];
    const timeout = setTimeout(() => {
      const services = startingServices.current.filter(
        (service) => service.serviceId !== selectedService.serviceId
      );
      startingServices.current = services;
      getServiceStatus(true);
    }, 30000);

    timeouts.current.push(timeout);
  };

  const updateRestartingServices = (selectedService) => {
    restartingServices.current = [
      ...restartingServices.current,
      selectedService,
    ];
    const timeout = setTimeout(() => {
      const services = restartingServices.current.filter(
        (service) => service.serviceId !== selectedService.serviceId
      );
      restartingServices.current = services;
      getServiceStatus(true);
    }, 30000);

    timeouts.current.push(timeout);
  };

  const startService = () => {
    setActionLoading(true);
    MONITORING_SERVICE.MAKE_PUT_REQUEST(
      `/service/start/${selectedService.serviceId}`
    )
      .then((data) => {
        if (data.success) {
          updateStartingServices(selectedService);
          handleSuccessResponse(data?.response);
        }
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };

  const stopService = () => {
    setActionLoading(true);
    MONITORING_SERVICE.MAKE_PUT_REQUEST(
      `/service/stop/${selectedService.serviceId}`
    )
      .then((data) => {
        if (data.success) {
          handleSuccessResponse(data?.response);
        }
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };

  const restartService = () => {
    setActionLoading(true);
    MONITORING_SERVICE.MAKE_PUT_REQUEST(
      `/service/restart/${selectedService.serviceId}`
    )
      .then((data) => {
        if (data.success) {
          updateRestartingServices(selectedService);
          handleSuccessResponse(data?.response);
        }
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };

  const handleServiceAction = (service, action) => {
    setAction(action);
    setSelectedService(service);
    setShowModal(true);
  };

  const isServiceStarting = (service) => {
    return (
      startingServices.current.filter(
        (startingService) => startingService.serviceId === service.serviceId
      ).length !== 0
    );
  };

  const isServiceRestarting = (service) => {
    return (
      restartingServices.current.filter(
        (restartingService) => restartingService.serviceId === service.serviceId
      ).length !== 0
    );
  };

  const reload = () => {
    getServiceStatus();
  };

  function closeModal() {
    setShowModal(false);
  }

  const modalContent = () => {
    return (
      <div className="d-flex p-flex-column align-center ">
        <i
          className="pi pi-question-circle"
          style={{ fontSize: "8rem", color: "#0371ac" }}
        />
        <p className="p-py-3">
          Are you sure you want to {action}
          <b> {selectedService?.serviceName}</b> service?
        </p>
        <div className="d-flex  " style={{ width: "100%" }}>
          <button
            disabled={actionLoading}
            className="secondary-button p-mr-3 "
            onClick={closeModal}
          >
            No
          </button>
          <button
            disabled={actionLoading}
            className="primary-button "
            onClick={handleYes}
          >
            {" "}
            {actionLoading ? (
              <ProgressSpinner
                style={{ width: "20px", height: "20px" }}
                strokeWidth="4"
              />
            ) : (
              "Yes"
            )}
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <CustomModal
        closeModal={closeModal}
        visible={showModal}
        modalContent={modalContent}
        onHide={closeModal}
      />
      <CustomCard loading={loading}>
        {createPortal(<Tooltip target=".tooltip-target" />, document.body)}
        <div style={{ paddingBottom: "1rem" }}>
          <p className="p-text-bold p-mt-0">Services</p>
          <table className="service-list-tabl p-mt-5">
            <thead style={{ display: "none" }}>
              <tr>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {services.map((service, index) => (
                <tr key={index}>
                  <td>{service.serviceName}</td>
                  <td>
                    <div>
                      {
                        <span
                          className={`custom-badge custom-badge-text ${
                            service?.active
                              ? "custom-badge-success"
                              : "custom-badge-error"
                          }`}
                          style={{
                            color: `${service?.active ? "#28c76f" : "#ea5455"}`,
                          }}
                        >
                          <span
                            className="p-text-bold tooltip-target"
                            data-pr-tooltip={
                              typeof service?.details === "string"
                                ? service?.details
                                : service?.details?.join("\n")
                            }
                          >
                            {service?.active ? "Active" : "Inactive"}{" "}
                          </span>
                        </span>
                      }
                    </div>
                  </td>
                  <td>
                    <button
                      disabled={isServiceRestarting(service)}
                      onClick={() => handleServiceAction(service, "restart")}
                      className="restart-button"
                    >
                      <i
                        data-pr-tooltip={
                          isServiceRestarting(service)
                            ? "Service is being restarted"
                            : "Restart Service"
                        }
                        className={`pi tooltip-target pi-refresh px-1 p-mr-1 ${
                          isServiceRestarting(service) ? "disabled" : null
                        }`}
                      />
                    </button>
                  </td>
                  <td>
                    <button
                      className="action-button"
                      disabled={isServiceStarting(service) && service.active}
                      onClick={() =>
                        handleServiceAction(
                          service,
                          !service.active ? "start" : "stop"
                        )
                      }
                    >
                      {!service.active ? (
                        <i
                          data-pr-tooltip={
                            isServiceStarting(service)
                              ? "Service is being started"
                              : "Start Service"
                          }
                          className={`pi tooltip-target  pi-play start px-1 p-mr-1 ${
                            isServiceStarting(service) ? "disabled" : null
                          }`}
                        />
                      ) : (
                        <i
                          data-pr-tooltip="Stop Service"
                          className="pi tooltip-target pi-ban stop px-1 p-mr-1"
                        />
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CustomCard>
    </>
  );
}
