import { BACK_OFFICE_API } from "api/routes/back-office/index";
import { API_SERVICE } from "api/service";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { HELPER } from "utils/helper";
import { FormDropdown } from "components/form/form-dropdown";
import { FormMultiselect } from "components/form/form-multiselect";
import { CUSTOM_VALIDATION } from "utils/validation";
import { CustomToast } from "components/elements/alert/custom-toast";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";

export function SettlementSetup(props) {
  const toast = useRef(null);
  const [chargeTypes, setChargeTypes] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [settlementTypes, setSettlementTypes] = useState([]);
  const [error, setError] = useState({
    message: "",
    title: "",
    type: "error",
  });
  const [settlementInfo, setSettlementInfo] = useState({
    frequency: "",
    fee: "",
    priority: "",
    participants: "",
  });
  const [settlementErrorForm, setSettlementErrorForm] = useState({
    frequency: null,
    fee: null,
    priority: null,
    participants: null,
  });

  function getParticipantsArray(allParticipants, selectedRuleParticipants) {
    return allParticipants.filter((p1) =>
      selectedRuleParticipants.some(
        (p2) => p2.participantCode === p1.participantCode
      )
    );
  }

  useEffect(() => {
    function prefillForm() {
      if (props?.isUpdate) {
        const {
          frequency,
          fee,
          participants: selectedRuleParticipants,
          ...rest
        } = props.payload;
        setSettlementInfo({
          ...rest,
          fee: chargeTypes.find((charge) => charge.code === fee),
          participants: getParticipantsArray(
            participants,
            selectedRuleParticipants
          ),
          frequency: { decs: frequency, code: frequency },
        });
      }
    }
    prefillForm();
  }, [participants, chargeTypes]);

  useEffect(() => {
    function getSettlementType() {
      const url = BACK_OFFICE_API.SUPER_AGENT.GET_SETTLEMENT_TYPE;
      API_SERVICE.MAKE_GET_REQUEST(url)
        .then((data) => {
          let arr = [];
          data.result.forEach((e) => {
            arr.push({ decs: e, code: e });
          });
          setSettlementTypes(arr);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error getting settlement types!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }

    function getSettlementParticipants() {
      const params = HELPER.TO_URL_STRING({
        page: 0,
        size: 2000,
        global: false,
      });
      const url = BACK_OFFICE_API.PARTICIPANT.GET_SETTLEMENT_PARTICIPANTS;
      API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
        .then((data) => {
          let arr = [];
          data.result.content.forEach((e) => {
            arr.push({
              chargeCode: e.chargeType.code,
              accountNumber: e.accountNumber,
              accountName: e.accountName,
              participantCode: e.participantId,
              desc: e.participantId,
              code: e.name,
              id: e.participantId,
            });
          });
          setParticipants(arr);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }

    function getChargeTypes() {
      const url = BACK_OFFICE_API.CHARGE_TYPE.GET_ALL_CHARGE_MODELS;
      API_SERVICE.MAKE_GET_REQUEST(url)
        .then((data) => {
          let arr = [];
          data?.result?.forEach((e) => {
            arr.push({ desc: e.chargeTypeName, code: e.code });
          });
          setChargeTypes(arr);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error getting charge types!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }

    getSettlementType();
    getSettlementParticipants();
    getChargeTypes();
  }, []);

  function validateMultipleSelect(e, name) {
    const value = e.target.value;
    if (value.length) {
      setSettlementErrorForm({ ...settlementErrorForm, [name]: "" });
      setSettlementInfo({ ...settlementInfo, [name]: value });
    } else {
      setSettlementInfo({ ...settlementInfo, [name]: null });
      setSettlementErrorForm({ ...settlementErrorForm, [name]: null });
    }
  }

  function validateDropdown(e, name) {
    const value = e.target.value;
    if (value) {
      setSettlementErrorForm({ ...settlementErrorForm, [name]: "" });
      setSettlementInfo({ ...settlementInfo, [name]: value });
    }
  }

  function validateForm() {
    const validErrorForm = Object.values(settlementInfo)
      .map((item) => item === "")
      .includes(true);
    return validErrorForm;
  }

  function handleNextAction() {
    if (
      !validateForm() &&
      !CUSTOM_VALIDATION.VALID_OBJ_ANY(settlementErrorForm)
    ) {
      setError({
        message: "",
        title: "",
        type: "error",
      });
      const formattedParticipants = settlementInfo.participants.map((items) => {
        const { desc, code, id, ...rest } = items;
        return rest;
      });
      const formattedPayload = {
        ...settlementInfo,
        fee: settlementInfo.fee.code,
        frequency: settlementInfo.frequency.code,
        participants: formattedParticipants,
      };
      const isSubmit = true;
      const isUpdate = props?.isUpdate;
      props.action(formattedPayload, null, isSubmit, isUpdate);
    } else {
      setError({
        message: "Inputs cannot be empty.",
        title: "Validation Error",
        type: "error",
      });
    }
  }

  return (
    <div className="p-grid">
      <div>
        <Toast ref={toast} />
      </div>
      <div className="p-col-12">
        <div className="d-flex">
          <div className="p-mt-2 p-col-6">
            {props.isUpdate && <label>Fee *:</label>}
            <FormDropdown
              required={true}
              field="fee"
              error={settlementErrorForm["fee"]}
              label="desc"
              value={settlementInfo["fee"]}
              fn={validateDropdown}
              options={chargeTypes}
              placeholder="Choose a fee*"
              filter
            />
          </div>

          <div className="p-mt-2 p-col-6">
            {props.isUpdate && <label>Participants *:</label>}
            <FormMultiselect
              required={true}
              error={settlementErrorForm["participants"]}
              field="participants"
              label="code"
              value={settlementInfo["participants"]}
              options={participants}
              fn={validateMultipleSelect}
              placeholder={"Participants"}
              filter
            />
          </div>
        </div>
        <div className="p-mt-2 p-col-12">
          {props.isUpdate && <label>Priority *:</label>}
          <FormDropdown
            required={true}
            field="priority"
            value={settlementInfo["priority"]}
            fn={validateDropdown}
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            placeholder="Input priority *"
          />
        </div>
        <div className="p-mt-3 p-col-12">
          {props.isUpdate && <label>Frequency:</label>}
          <FormDropdown
            required={true}
            label="code"
            field="frequency"
            error={settlementErrorForm["frequency"]}
            value={settlementInfo["frequency"]}
            fn={validateDropdown}
            options={settlementTypes}
            placeholder="Settlement type *"
          />
        </div>
        <div className="login-alert-container p-col-12">
          {error.title ? (
            <div className="merchant-toast">
              <CustomToast
                title={error.title}
                description={error.message}
                type={error.type}
                clearToast={() =>
                  setError({ title: null, message: null, type: null })
                }
              />
            </div>
          ) : (
            <div />
          )}
        </div>

        {props.loading ? (
          <div
            style={{ marginBottom: "1rem" }}
            className="p-col-12 p-mt-6 d-flex p-jc-center "
          >
            <CustomLoader loadingText="Submitting..." />
          </div>
        ) : (
          <div
            style={{ marginBottom: "1rem" }}
            className="p-col-12 p-mt-6 d-flex p-jc-center"
          >
            <button
              onClick={() => {
                const ruleSetupIndex = 1;
                props.action("", ruleSetupIndex);
              }}
              className="primary-button  "
            >
              Back
            </button>
            <button
              disabled={
                validateForm() &&
                CUSTOM_VALIDATION.VALID_OBJ_ANY(settlementErrorForm)
              }
              onClick={handleNextAction}
              className="primary-button p-ml-2  "
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
