import { CustomTable } from "components/elements/custom-table/custom-table";
import { EmptyView } from "components/elements/empty-view/empty-view";

export function ConfigParticipants(props) {
  const totalPages = 1;
  const totalItems = 10;
  const currentPage = 0;
  const pageNo = 0;
  const range = 1;
  const emptyText = "";
  const search = false;
  const error = "";
  const tableHeaders = [
    { label: "Participant Code", value: "participantCode" },
    { label: "Charge Code", value: "chargeCode" },
    { label: "Account Name", value: "accountName" },
    { label: "Account Number", value: "accountNumber" },
  ];

  if (!props.participants.length) {
    return <EmptyView emptyText="No participants found..." />;
  }

  return (
    <CustomTable
      isNotPaginated={true}
      isReload={false}
      totalPages={totalPages}
      totalItems={totalItems}
      currentPage={pageNo + 1}
      range={range}
      emptyText={emptyText}
      search={search}
      reload={() => {}}
      error={error}
      items={props.participants}
      headers={tableHeaders}
      nextPage={() => {}}
      prevPage={() => {}}
      goToFirstPage={() => {}}
      goToLastPage={() => {}}
      goToPage={() => {}}
    />
  );
}
